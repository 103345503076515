const ROUTES = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  CONVERSION_SETUP: "/conversion-setup/:id",
  EMAIL_VALIDATION: "/emailValidation",
  ANALYTICS_VIEW: "/analytics-view/:id",
  RESET_PASSWORD: "/resetPassword",
  SETTINGS: "/settings/:id",
  ADMINLOGIN:"/admin-login",
  RESET_ADMIN_PASSWORD: "/resetAdminPassword",
  ADMINDASHBOARD:"/admin-dashboard",
  USERS:"/users",
  ADMIN:"/admin",
  DELETEDUSERS:"/deleted-users",


};


export default ROUTES;
