import * as yup from "yup";
import { TFunction } from "react-i18next";

import {
  EMAIL_REGEX,
  NUMBER_REGEX,
  PASSWORD_REGEX,
  PHONE_REGEX,
  SITE_URL_REGEX,
  OTP_REGEX,
} from "../../constants/commonConstants";

const changePasswordValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    oldPassword: yup
      .string()
      .trim()
      .required(translation("current_password_required")),

    newPassword: yup
      .string()
      .required(translation("new_password_required"))
      .matches(/^(?!\s)([^\s](?!\s)*){1,}$/, translation("no_spaces_allowed"))
      .matches(PASSWORD_REGEX, translation("pass_reg_ms")),

    confirmPassword: yup
      .string()
      .required(translation("confirm_password_required"))
      .oneOf(
        [yup.ref("newPassword")],
        translation("password_confirm_password_must_match")
      )
      .matches(/^(?!\s)([^\s](?!\s)*){1,}$/, translation("no_spaces_allowed")),
  });

const resetPasswordValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    password: yup
      .string()
      .required(translation("pass_req"))
      .matches(PASSWORD_REGEX, translation("pass_reg_ms")),
    confirmPassword: yup
      .string()
      .required(translation("confirm_password_required"))
      .oneOf([yup.ref("password")], translation("password_matched_ms")),
  });

const loginValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .required(translation("email_req"))
      .matches(EMAIL_REGEX, translation("email_valid"))
      .email(translation("email_valid")),
    password: yup.string().required(translation("pass_req")),
    // .matches(PASSWORD_REGEX, translation("pass_reg_ms")),
  });

const signUpUserInfoValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z ]*$/, translation("name_no_special_chars"))
      .max(50, translation("name_max_length"))
      .required(translation("full_name_req"))
      .min(2, translation("name_min_length_2")),
    email: yup
      .string()
      .trim()
      .required(translation("email_req"))
      .matches(EMAIL_REGEX, translation("email_valid"))
      .email(translation("email_valid")),
  });

const userPasswordAndTermsAcceptValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    password: yup
      .string()
      .required(translation("pass_req"))
      .matches(PASSWORD_REGEX, translation("pass_reg_ms")),
    termsAccept: yup
      .bool()
      .test(
        "is boolean",
        translation("accept_terms_and_condition"),
        (value) => value === true
      )
      .required(translation("accept_terms_and_condition")),
  });
const userSiteValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    siteUrl: yup
      .string()
      .required(translation("site_req"))
      .matches(SITE_URL_REGEX, translation("site_url_invalid"))
      .trim()
      .max(100, translation("site_max_length_100")),
  });
const useVerificationCodeValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    verificationCode: yup
      .string()
      .matches(OTP_REGEX, translation("otp_not_valid"))
      .required(translation("code_req")),
  });
const menuTypeSelection = (translation: TFunction) =>
  yup.object().shape({
    menuType: yup.string().trim().required(translation("menu_type_req")),
  });
const userSiteInfoValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    signupRole: yup.string().trim().required(translation("signup_role_req")),
    siteType: yup
      .string()
      .trim()

      .required(translation("site_type_req")),
    siteMonthlyUsers: yup
      .string()
      .trim()

      .required(translation("site_monthly_users_req")),
  });

const addPumpValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    deviceName: yup.string().trim().required(translation("pump_name_required")),
    deviceId: yup.string().trim().required(translation("serial_name_required")),
    modelNumber: yup
      .string()
      .trim()
      .required(translation("model_number_required")),
  });

const forgotPasswordValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .required(translation("email_req"))
      .email(translation("email_valid")),
  });

const VerifyOtpValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    otp: yup
      .string()
      .trim()
      .required(translation("otp_req"))
      .matches(NUMBER_REGEX, translation("otp_reg_ms")),
  });

const EditProfileValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    address: yup.string().trim().required(translation("address_required")),
    userName: yup.string().required(translation("user_name_required")),
    phone: yup
      .string()
      .required(translation("contact_number_required"))
      .matches(PHONE_REGEX, translation("phone_number_regex")),
    password: yup.string().required(translation("password_required")),
  });

const EditProfileValidationSchemaWithImage = (translation: TFunction) =>
  yup.object().shape({
    address: yup.string().trim().required(translation("address_required")),
    userName: yup.string().required(translation("user_name_required")),
    phone: yup
      .string()
      .required(translation("contact_number_required"))
      .matches(PHONE_REGEX, translation("phone_number_regex")),
    password: yup.string().required(translation("password_required")),
    image: yup.string().required(translation("image_req")),
  });

const deleteAccountValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    selectedReason: yup
      .string()
      .trim()
      .required(translation("Please select the reason")),
    termsAccept: yup
      .bool()
      .test(
        "is boolean",
        translation("accept_terms_and_condition"),
        (value) => value === true
      )
      .required(translation("accept_terms_and_condition")),
    comment: yup
      .string()
      .trim()
      .required(translation("Please give the feedback")),
  });

const confirmDeleteAccountValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    typeDelete: yup
      .string()
      .required(translation("Please type DELETE"))
      .matches(/^DELETE$/, translation("Input should match 'DELETE'")),
  });
const forgetPasswordFormEmailValidation = (translation: TFunction) =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .required(translation("email_req"))
      .matches(EMAIL_REGEX, translation("email_valid"))
      .email(translation("email_valid")),
  });

const addNewAdminValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    firstname: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z ]*$/, translation("name_no_special_chars"))
      .max(50, translation("name_max_length"))
      .required(translation("first_name_required"))
      .min(2, translation("name_min_length_2")),
    lastname: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z ]*$/, translation("name_no_special_chars")),
    email: yup
      .string()
      .trim()
      .required(translation("email_req"))
      .matches(EMAIL_REGEX, translation("email_valid"))
      .email(translation("email_valid")),
    password: yup
      .string()
      .required(translation("pass_req"))
      .matches(PASSWORD_REGEX, translation("pass_reg_ms")),
  });

export {
  loginValidationSchema,
  forgotPasswordValidationSchema,
  VerifyOtpValidationSchema,
  addPumpValidationSchema,
  EditProfileValidationSchema,
  EditProfileValidationSchemaWithImage,
  resetPasswordValidationSchema,
  changePasswordValidationSchema,
  signUpUserInfoValidationSchema,
  userPasswordAndTermsAcceptValidationSchema,
  userSiteValidationSchema,
  userSiteInfoValidationSchema,
  useVerificationCodeValidationSchema,
  menuTypeSelection,
  deleteAccountValidationSchema,
  forgetPasswordFormEmailValidation,
  confirmDeleteAccountValidationSchema,
  addNewAdminValidationSchema,
};
