import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import InputWrapper from "../../utilities/formElements/commonInput/InputWrapper";
import {
  toastMessageError,
  toastMessageSuccess,
} from "../../utilities/commonToastMessage";
import { resetPasswordValidationSchema } from "../../utilities/validationSchema";
import CommonButton from "../../utilities/formElements/commonButton/CommonButton";

import { resetPassword, verifyResetLink } from "../../../services/user";
import {
  resetAdminPassword,
  verifyAdminResetLink,
} from "../../../services/admin";

import ROUTES from "../../../constants/routes";
import {
  resetAdminPasswordPath,
  resetPasswordPath,
  typePassword,
  typeText,
} from "../../../constants/commonConstants";

import Logo from "../../../assets/images/Skydive_logo_beta.svg";
import EyeIcon from "../../../assets/images/eye-outline.svg";
import EyeCloseIcon from "../../../assets/images/hidden-password.svg";

const ResetPassword = () => {
  const { t: translation } = useTranslation();
  const {
    control: controlForFogretPasswordModel,
    handleSubmit: handleSubmitForForgetPasswordModel,
    formState: { errors: errorsForForgetPasswordForm },
  } = useForm({
    resolver: yupResolver(resetPasswordValidationSchema(translation)),
  });
  const [viewPasswordAndConfirmPassword, setViewPasswordAndConfirmPasword] =
    useState({ viewPassword: false, viewConfirmPassword: false });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const verifyLink = async () => {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const path = window.location.pathname;

    const token = searchParams.get("token");
    if (token) {
      if (path === resetPasswordPath) {
        const isLinkVerified: any = await verifyResetLink(token);

        if (isLinkVerified && isLinkVerified?.error.errorMessage) {
          toastMessageError(isLinkVerified?.error.errorMessage);
          navigate(ROUTES.LOGIN);
        }
      } else if (path === resetAdminPasswordPath) {
        const isAdminLinkVerified = await verifyAdminResetLink(token);

        if (
          isAdminLinkVerified &&
          isAdminLinkVerified.error &&
          isAdminLinkVerified?.error?.errorMessage
        ) {
          navigate(ROUTES.ADMINLOGIN);
        }
      }
    } else {
      // unauthorized
      navigate(ROUTES.LOGIN);
    }
  };
  const onNewPasswordSubmit = async (data: any) => {
    setLoading(true);
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const path = window.location.pathname;

    const param = {
      token: searchParams.get("token"),
      password: data.password,
      confirmPassword: data.confirmPassword,
    };

    if (path === "/resetPassword") {
      const passwordChangeResponse = await resetPassword(param);
      if (passwordChangeResponse && passwordChangeResponse.data) {
        toastMessageSuccess(
          translation("reset_password_success"),
          "passwordReset"
        );
      } else {
        toastMessageError(
          translation("reset_password_error"),
          "resetPasswordError"
        );
      }
      setLoading(false);
      navigate(ROUTES.LOGIN);
    }
    if (path === resetAdminPasswordPath) {
      const passwordChangeResponseAdmin = await resetAdminPassword(param);
      if (passwordChangeResponseAdmin && passwordChangeResponseAdmin.data) {
        toastMessageSuccess(
          translation("reset_password_success"),
          "passwordReset"
        );
      } else {
        toastMessageError(
          translation("reset_password_error"),
          "resetPasswordError"
        );
      }
      setLoading(false);
      navigate(ROUTES.ADMINLOGIN);
    }
  };

  useEffect(() => {
    verifyLink();
  }, []);

  return (
    <div className="userauth-main">
      <div className="container-fluid">
        <img src={Logo} alt="logo" className="logo" />
        <div className="userauth-form">
          <h4>{translation("resetPassword")}</h4>
          <form
            onSubmit={handleSubmitForForgetPasswordModel((data) =>
              onNewPasswordSubmit(data as any)
            )}
          >
            <div className="form-group">
              <div className="input-icon">
                <InputWrapper
                  control={controlForFogretPasswordModel}
                  name="password"
                  className="form-control"
                  placeholder={translation("enter_new_password")}
                  error={errorsForForgetPasswordForm?.password}
                  type={
                    viewPasswordAndConfirmPassword.viewPassword
                      ? "text"
                      : "password"
                  }
                />
                <CommonButton
                  type="button"
                  onClick={() =>
                    setViewPasswordAndConfirmPasword((prevState) => ({
                      ...prevState,
                      viewPassword:
                        !viewPasswordAndConfirmPassword.viewPassword,
                    }))
                  }
                  iconSrc={
                    viewPasswordAndConfirmPassword.viewPassword
                      ? EyeIcon
                      : EyeCloseIcon
                  }
                />
              </div>
            </div>

            <div className="form-group">
              <div className="input-icon">
                <InputWrapper
                  control={controlForFogretPasswordModel}
                  name="confirmPassword"
                  className="form-control"
                  placeholder={translation("enter_confirm_password")}
                  error={errorsForForgetPasswordForm?.confirmPassword}
                  type={
                    viewPasswordAndConfirmPassword.viewConfirmPassword
                      ? typeText
                      : typePassword
                  }
                />

                <CommonButton
                  type="button"
                  onClick={() =>
                    setViewPasswordAndConfirmPasword((prevState) => ({
                      ...prevState,
                      viewConfirmPassword:
                        !viewPasswordAndConfirmPassword.viewConfirmPassword,
                    }))
                  }
                  iconSrc={
                    viewPasswordAndConfirmPassword.viewConfirmPassword
                      ? EyeIcon
                      : EyeCloseIcon
                  }
                />
              </div>
            </div>

            <div className="forgot-check">
              <div className="form-check m-0"></div>
            </div>

            <CommonButton
              className="theme-btn success-btn text-uppercase fontBold me-3"
              label="Reset Password"
              loading={loading}
              disabled={loading}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

