import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { averageSessionDurationConverter } from "../../util/helper";
import { getMetricsData } from "../../util/metricsModal";
import storage from "../../util/storage";
import {
  selectedDateRange,
  selectedEndDateRange,
} from "../../util/clusterService";

import { eventEmitter } from "../../eventEmitters/EventEmitters";

import ActionType from "../../resources/enums";

import DownIcon from "../../assets/images/down-icon.png";
import "./Footer.scss";
import Loader from "../../loader/Loader";

const Footer = () => {
  const authReducer: any = useSelector((state: any) => state?.AuthReducer);
  const dispatch = useDispatch();

  const [currHistoricalFilter, setCurrHistoricalFilter] = useState(
    authReducer?.historicalFilterSelected.name
  );

  useEffect(() => {
    getMetricsDataInfo();

    metrixData();
    metrixDataOnFilterChange();
  }, []);

  const metrixData = () => {
    eventEmitter.addListener("newSession", () => {
      getMetricsDataInfo("newSession");
    });
  };
  const metrixDataOnFilterChange = () => {
    eventEmitter.addListener("filterChanged", () => {
      getMetricsDataInfo();
    });
  };
  const [historicalLoader, setHistoricalLoader] = useState<boolean>(false);
  const [historyDataInfo, getHistoryDataInfo] = useState<any>();
  const getMetricsDataInfo = async (newSession?:string) => {
    if(!newSession){

      setHistoricalLoader(true);
    }
    const params = {
      endDate: selectedEndDateRange
      ? selectedEndDateRange
      : new Date().toISOString(),
      startDate:
      selectedDateRange && selectedDateRange.value
      ? new Date(selectedDateRange.value).toISOString()
      : new Date(new Date().setHours(0,0,0,0)).toISOString(),
      siteId: authReducer.authData.site._id,
    };
    const getHistoryData = await getMetricsData(params);
    dispatch({
      type: ActionType.TOTALSESSION,
      payload: { totalSession: getHistoryData?.totalSession },
    });
    storage.set("totalSession", getHistoryData?.totalSession);
    getHistoryDataInfo(getHistoryData);
    setCurrHistoricalFilter(authReducer?.historicalFilterSelected.name);
  
      setHistoricalLoader(false);

  };

  const [showFooter, setShowFooter] = useState<boolean>(true);
  return (
    <>
      <button
        className={
          showFooter ? "hide-footer-btn up-footer-btn" : "hide-footer-btn"
        }
        onClick={() => setShowFooter(!showFooter)}
      >
        <img src={DownIcon} alt="down-icon" />
      </button>

      <div
        className={showFooter ? "footer-main" : "footer-main footer-hide-main"}
      >
        <div className="container">
          <div className="footer-desc">
            <ul className="footer-list-align">
              <li className="d-flex">
                <div className="arrow-inline first-line-overall">
                  <h6>Overall</h6>
                  <h4>
                    {" "}
                    {selectedDateRange.name === "All"
                      ? ` ${new Date(
                          authReducer.authData?.createdAt
                        ).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}
                   - Today`
                      : selectedDateRange.name === "Custom Range"
                      ? selectedEndDateRange
                        ? `${new Date(
                            selectedDateRange.value
                          ).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })}  - 
                      ${new Date(selectedEndDateRange).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )}`
                        : `  
                      ${new Date(selectedDateRange.value).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )} - ${new Date().toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })}`
                      : selectedDateRange.name}
                  </h4>
                </div>
              </li>
              <li>
                <div className="arrow-inline">
                  <h4>{historyDataInfo?.newUsers}</h4>
                  <div className="arrow-count"></div>
                </div>
                <p>Visitors</p>
              </li>

              <li>
                <div className="arrow-inline">
                  <h4>{historyDataInfo?.percentNewSessions}%</h4>
                  <div className="arrow-count"></div>
                </div>
                <p>% New Sessions</p>
              </li>

              <li>
                <div className="arrow-inline">
                  <h4>{historyDataInfo?.totalSession}</h4>
                  <div className="arrow-count"></div>
                </div>
                <p>Sessions</p>
              </li>

              <li>
                <div className="arrow-inline">
                  <h4>{historyDataInfo?.pageviewsPerSession}</h4>
                  <div className="arrow-count"></div>
                </div>
                <p>Pages / Session</p>
              </li>

              <li>
                <div className="arrow-inline">
                  <h4>{historyDataInfo?.bounceRate}</h4>
                  <div className="arrow-count"></div>
                </div>
                <p>Bounce Rate</p>
              </li>

              <li>
                <div className="arrow-inline">
                  <h4>
                    {averageSessionDurationConverter(
                      historyDataInfo?.avgSessionDuration
                    )}
                  </h4>
                  <div className="arrow-count"></div>
                </div>
                <p>Avg Session Duration</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      { historicalLoader ? <Loader /> :""}
    </>
  );
};

export default Footer;
