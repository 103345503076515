import * as d3 from "d3";
import { each } from "lodash";
import {
  data,
  getPagesStatistics,
  handleVisitorMovePagesModel,
  setPages,
} from "./pagesModel";

import { session } from "../services/session";
import { eventEmitter } from "../eventEmitters/EventEmitters";
import { getMetricsData, historical } from "./metricsModal";
import storage from "./storage";

import { site } from "../services/site";
import {
  hideHistoricalTooltip,
  hideRealtimeTooltip,
  hideSelectedUrlTooltip,
  showHistoricalTooltip,
  showRealtimeTooltip,
  showSelectedUrlTooltip,
} from "./tooltipService";

import {
  DATEPICKER_RANGES,
  INITIAL_FILTERS,
} from "../constants/commonConstants";
import { wrapPath } from "./helper";
export let availableFilters;

interface FilterItem {
  name: string;

  menuType: string;
}

const initialHistoryDataDateRange = [
  { name: "Today", value: new Date().setHours(0, 0, 0, 0) },
  {
    name: "Last 2 Days",
    value: new Date(
      new Date().setHours(0, 0, 0, 0) - 1 * 24 * 60 * 60 * 1000
    ).getTime(),
  },
  {
    name: "Last 7 Days",
    value: new Date(
      new Date().setHours(0, 0, 0, 0) - 6 * 24 * 60 * 60 * 1000
    ).getTime(),
  },
  {
    name: "Last 30 Days",
    value: new Date(
      new Date().setHours(0, 0, 0, 0) - 29 * 24 * 60 * 60 * 1000
    ).getTime(),
  },
  { name: "All", value: storage.get("userCreatedAt") },
];


const DEFAULT_D3CIRCLE_CONSTRAINT = {
  gravity: 0.2,
  charge: -1,
  friction: 0.0001,
  speed: 100,
};
const DEFAULT_VALUE = {
  UNKNOWN_URL_CLUSER_INDEX: 10,
  ALL_CLUSTER_INDEX: 13,
  DEFAULT_CLUSTER_CENTER: {
    "menu-nodes": {
      centerIndex: 11,
      cluster: 11,
    },
  },
  SETUP_TIMEOUT: 15000,
  ALL_RANGE_NAME: "All",
  ROW_PER_PAGE: 100,
};

export interface RealtimeItem {
  name: string;
  currentUsers: number;
  totalUsers: number;
  color: number;
  colorInfo: any;
  display: boolean;
}

const siteId = storage.get("siteId");
export let pageViewDisabled = false;
const UNKNOWN_URL_CLUSER_INDEX = 10;
let clusterTextInstances: any = [];
let clusterRectInstances: any = [];
let svg: any;

// end Date selected for custom date range
export let selectedEndDateRange;
export let historyDataDateRange;
export let items: RealtimeItem[] = [];
let initialHistoryFlag = true;
export let onsiteUser = 0;

// const availableFilters: FilterItem[];

export let selectedFilter: FilterItem;
let currentBubbleColor = 1;
export let clusterCenterCoords: any;
let bubblesSelection: any;
export let updateBubbles: any;
const mainSvgHeight = window.innerHeight;
const mainSvgWidth = window.innerWidth;

let gapBetweenBoxes;

export const pageWiseCount = {};
export let selectedDateRange = historyDataDateRange
  ? historyDataDateRange[0]
  : initialHistoryDataDateRange[0];
const historyIndexRectCenters = {};
// eslint-disable-next-line prefer-const
let pages: any[] = storage.get("pages") && JSON.parse(storage.get("pages"));
export let bubblesData = [];

const someThing =
  window.innerHeight * 0.85 > 500 ? window.innerHeight * 0.85 : 500;

const mainClusterHeight = someThing * 0.75;

export const getPagesList = () => {
  pages =storage.get("pages") && JSON.parse(storage.get("pages"));
};

export const createStaticComponents = async () => {
  return new Promise(async (resolve) => {
    d3.select("#main_svg")
      .append("div")
      .attr("class", "historical-cluster-tooltip")
      .attr("id", "tooltip")
      .style("opacity", 0);

    svg = d3
      .select("#main_svg")
      .append("svg")
      .attr("width", mainSvgWidth - 10)
      .attr("height", mainSvgHeight - 200)
      .attr("class", "svg-main-window")
      .attr("background-color", "red")
      .attr("border", 1);

    bubblesSelection = svg?.selectAll("circle");

    svg
      .append("rect")
      .attr("id", "maincluster")
      .attr("width", mainSvgWidth - 10)
      .attr("height", mainClusterHeight)
      .style("fill", "#f0f0f0")
      .style("stroke", "#f0f0f0");

    await createClusterRect(svg, mainSvgWidth);
    resolve("ok");
  });
};

const createClusterRect = (svg, mainSvgWidth) => {
  return new Promise(async (resolve) => {
    clusterTextInstances = [];

    clusterRectInstances = [];

    clusterCenterCoords = await getClusterCoords(mainSvgWidth);

    clusterCenterCoords.forEach((center, key) => {
      if (key < UNKNOWN_URL_CLUSER_INDEX) {
        const svgInstance = svg.append("g").attr("rectIndex", key);
        const element = document.getElementById("select_a_page");

        const rectInstance = svgInstance
          .append("rect")
          .attr("x", center.x - 50)
          .attr("y", center.y - 50)
          .attr("width", 150)
          .attr("height", 100)
          .attr("rectIndex", key)
          .attr("selected", false)
          .attr("id", "rect_" + key)
          .style("fill", d3.rgb(227, 231, 231))
          .style("stroke", d3.rgb(227, 231, 231))
          .on("mouseover", function () {
            const clusterIndex = parseInt(this.getAttribute("rectIndex"));

            const clusterSelected = this.getAttribute("selected");

            if (
              clusterIndex &&
              clusterSelected === "false" &&
              !pageViewDisabled
            ) {
              const ele = this.getBoundingClientRect();

              element.style.left = ele.left + "px";
              element.style.top = ele.top + "px";
              element.style.right = ele.right + "px";
              element.style.width = ele.width + "px";
              element.style.height = ele.height + "px";
              element.style.display = "block";
              element.setAttribute("rectIndex", clusterIndex.toString());
            }
          })
          .on("mouseout", () => {
            element.style.display = "none";
          });

        const textInstance = svgInstance

          .append("text")
          .text("")
          .attr("x", center.x - 50)
          .attr("y", center.y + 50 + 15)
          .attr("fill", "#fff")
          .attr("text-anchor", "start")
          .attr("real-text", "")
          .attr("is-wrap", false)
          .style("font-size", "10px")
          .style("fill", d3.rgb(105, 105, 105))
          .style("z-index", "999999999")
          .style("stroke-width", 1)
          .style("width", "100px")
          .on("mouseover", (d) => {
            showSelectedUrlTooltip(d);
          })
          .on("mouseout", () => {
            hideSelectedUrlTooltip();
          });

        clusterTextInstances.push(textInstance);
        clusterRectInstances.push(rectInstance);

        gapBetweenBoxes =
          clusterCenterCoords[1].x - (clusterCenterCoords[0].x + 100);
        resolve("ok");
      }

      if (key === UNKNOWN_URL_CLUSER_INDEX) {
        const svgInstance = svg.append("g");
        const backColor = d3.rgb(255, 255, 255);

        const rectInstance = svgInstance
          .append("rect")
          .attr("x", clusterCenterCoords[0]?.x - 50)
          .attr("y", center.y - 50)
          .attr(
            "width",

            clusterCenterCoords[9].x -
              50 +
              150 -
              (clusterCenterCoords[0]?.x - 50)
          )
          .attr("rectIndex", key)
          .attr("height", 100)
          .style("fill", backColor)
          .style("stroke", backColor);

        const textInstance = svgInstance

          .append("text")
          .text("All other pages")
          .attr(
            "x",

            clusterCenterCoords[0]?.x - 50 + 10
          )
          .attr("y", center.y + 45)
          .attr("real-text", "All other pages")
          .attr("fill", d3.rgb(105, 105, 105))
          .attr("is-wrap", false)
          .style(
            "stroke-width",

            1,

            { "font-size": "10px", "z-index": "999999999" },

            "fill",

            d3.rgb(105, 105, 105)
          )
          .style(
            "font-size",

            "10px"
          );

        clusterTextInstances.push(textInstance);
        clusterRectInstances.push(rectInstance);

        resolve("ok");
      }

      // for svg width

      d3.select("#main_svg").attr("width", clusterCenterCoords[4].x + 52 + 150);
      svg.attr("width", clusterCenterCoords[4].x + 52 + 150);
      d3.select("#maincluster").attr(
        "width",
        clusterCenterCoords[4].x + 52 + 150
      );

      // end
    });

    eventEmitter.emit("createStaticComponents", {
      clusterTextInstances,
      clusterRectInstances,
    });

    resolve("ok");
  });
};

const getClusterCoords = (mainSvgWidth: any) => {
  const columnsAmount = 5;
  const rowsAmount = 3;
  const marginWidth = (mainSvgWidth * 0.85 - 200) / 5; // (1309*0.85-200)/5   = 182.53
  let initialWidth = marginWidth - 130; // 52.52999
  const centerX: any = [];

  for (let i = 0; i < columnsAmount; i++) {
    centerX[i] = {
      x: initialWidth,
      exteraX: initialWidth * (50 / 36) + 250, // (382.53-250) *(50/36)+250 =
    };
    initialWidth = initialWidth + marginWidth;
  }

  const centerY = [];
  const marginHeight =
    ((window.innerHeight * 0.85 > 500 ? window.innerHeight * 0.85 : 500) -
      200) /
      3 +
    10;

  let initialHeight = 100;

  for (let i = 0; i < rowsAmount; i++) {
    centerY[i] = {
      y: initialHeight,
      exteraY: (initialHeight - 250) * (50 / 36) + 250,
    };
    initialHeight = initialHeight + marginHeight;
  }

  const mainCenters = new Array(13);
  let mainIndex = 0;

  each(centerY, function (valueY, keyY) {
    if (keyY < 2) {
      each(centerX, function (valueX) {
        mainCenters[mainIndex] = {
          x: valueX.x,
          y: valueY.y,
          exteraX: valueX.exteraX,
          exteraY: valueY.exteraY,
        };

        mainIndex++;
      });
    } else {
      mainCenters[10] = {
        x: centerX[2].x,
        exteraX: centerX[2].exteraX,
        y: valueY.y,
        exteraY: valueY.exteraY,
      };
    }
  });

  const someCondition = window.innerHeight * 0.9 > 500;

  const someCalculatedValue = someCondition ? window.innerHeight * 0.85 : 500;

  mainCenters[11] = {
    x: 250 + 225,

    y: 500,

    exteraX: someCalculatedValue + 225 * 0.9,

    exteraY: (someCalculatedValue * 0.9 - 250) * (50 / 36) + 250,
  };

  mainCenters[12] = {
    x: mainCenters[10].x - 200,

    y: 500,

    exteraX: mainCenters[10].exteraX - 200,

    exteraY: (someCalculatedValue * 0.8 - 250) * (50 / 36) + 250,
  };

  mainCenters[13] = {
    x: mainCenters[2].x + 80,

    y: (someCalculatedValue * 0.85 - 300) / 2 + 80,

    exteraX: mainCenters[2].exteraX,

    exteraY: ((someCalculatedValue * 0.85 - 300) / 2) * (50 / 36),
  };

  return mainCenters;
};

export const enablePageView = (pages: any,numberOfPagesToBeSelected:number) => {
  const bubbleParametersData = returnBubbleParameterData();

  const allPagesClusterIndex = 10;

  clusterTextInstances.forEach((clusterTextInstance: any, index: any) =>
    fillClusterEmpty(index)
  );

  const pagesData = pages.filter((page: any) => page.hasOwnProperty("slotIndex"));
    for(let i =0;i<pagesData.length;i++){
      if(numberOfPagesToBeSelected>0 && pagesData[i].selected){
     

          numberOfPagesToBeSelected--;
          fillClusterSelected(pagesData[i].slotIndex, pagesData[i].name);
      }
    }

    // .forEach((page: any) => fillClusterSelected(page.slotIndex, page.name));

  fillClusterSelected(allPagesClusterIndex, "All other pages");

  pageViewDisabled = false;

  const realTimeBubble = bubblesData.filter((item) => item.realTime);

  const pageSimulation = d3
    .forceSimulation()
    .force("collision", d3.forceCollide().radius(4).strength(1))
    .force(
      "charge",
      d3
        .forceManyBody()
        .strength(
          bubbleParametersData && bubbleParametersData.charge
            ? parseInt(bubbleParametersData.charge)
            : DEFAULT_D3CIRCLE_CONSTRAINT.charge
        )
    )
    .force("gravity", d3.forceManyBody().strength(0.2))
    .force(
      "x",
      d3.forceX((d: any) => findForceCenter(d.pageName, "x")).strength(0.05)
    )
    .force(
      "y",
      d3.forceY((d: any) => findForceCenter(d.pageName, "y")).strength(0.05)
    )
    .on("tick", () => {
      svg
        ?.selectAll("circle[realTime=true]")
        .transition()
        .duration(
          bubbleParametersData.speed
            ? bubbleParametersData.speed
            : DEFAULT_D3CIRCLE_CONSTRAINT.speed
        )
        .attr("cx", (d: any) => d.x)
        .attr("cy", (d: any) => d.y);
    });

  pageSimulation.nodes(realTimeBubble).alpha(0.9).restart();
};

const findForceCenter = (pageName: string, cordinate: string) => {
  let centerCordinates: number;
  const visitedPage = data.find((page: any) => {
    return page.name === pageName;
  });
  if (visitedPage) {
    const bubbleCordinates =
      clusterCenterCoords[
        visitedPage.slotIndex ?? DEFAULT_VALUE.UNKNOWN_URL_CLUSER_INDEX
      ];
    if (cordinate === "x") {
      centerCordinates = bubbleCordinates?.x;
    } else {
      centerCordinates = bubbleCordinates?.y;
    }
  }
  return centerCordinates;
};
export const disablePageView = () => {
  const bubbleParametersData = returnBubbleParameterData();
  clusterTextInstances.forEach((clusterTextInstance) => {
    clusterTextInstance.text("");
    clusterTextInstance.attr("is-wrap", false);
    clusterTextInstance.attr("real-text", "");
  });

  clusterRectInstances.forEach((clusterRectInstance) => {
    clusterRectInstance.style("fill", "#f0f0f0");
    clusterRectInstance.style("stroke", "#f0f0f0");
  });

  pageViewDisabled = true;

  const realTimeBubble = bubblesData.filter((item) => item.realTime);

  const newPageSimulation = d3
    .forceSimulation()
    .force("collision", d3.forceCollide().radius(4).strength(1))
    .force(
      "charge",
      d3
        .forceManyBody()
        .strength(
          bubbleParametersData && bubbleParametersData.charge
            ? parseInt(bubbleParametersData.charge)
            : DEFAULT_D3CIRCLE_CONSTRAINT.charge
        )
    )
    .force("gravity", d3.forceManyBody().strength(0.2))
    .force(
      "x",
      d3
        .forceX(clusterCenterCoords[DEFAULT_VALUE.ALL_CLUSTER_INDEX].x)
        .strength(0.05)
    )
    .force(
      "y",
      d3
        .forceY(clusterCenterCoords[DEFAULT_VALUE.ALL_CLUSTER_INDEX].y)
        .strength(0.05)
    )
    .on("tick", () => {
      svg
        ?.selectAll("circle[realTime=true]")
        .transition()
        .duration(
          bubbleParametersData.speed
            ? bubbleParametersData.speed
            : DEFAULT_D3CIRCLE_CONSTRAINT.speed
        )
        .attr("cx", (d: any) => d.x)
        .attr("cy", (d: any) => d.y);
    });

  newPageSimulation.nodes(realTimeBubble).alpha(0.9).restart();
};

export const drawExistingBubble = async() => {
  const bubbleParametersData = returnBubbleParameterData();

  const realTimeBubble = bubblesData.filter((item) => item.realTime);

  const pageSimulation = d3
    .forceSimulation()
    .force("collision", d3.forceCollide().radius(4).strength(1))
    .force(
      "charge",
      d3
        .forceManyBody()
        .strength(
          bubbleParametersData && bubbleParametersData.charge
            ? parseInt(bubbleParametersData.charge)
            : DEFAULT_D3CIRCLE_CONSTRAINT.charge
        )
    )
    .force("gravity", d3.forceManyBody().strength(0.2))
    .force(
      "x",
      d3.forceX((d: any) => findForceCenter(d.pageName, "x")).strength(0.05)
    )
    .force(
      "y",
      d3.forceY((d: any) => findForceCenter(d.pageName, "y")).strength(0.05)
    )
    .on("tick", () => {
      svg
        ?.selectAll("circle[realTime=true]")

        .attr("cx", (d: any) => d.x)
        .attr("cy", (d: any) => d.y);
    });

  pageSimulation.nodes(realTimeBubble).alpha(0.9).restart();

  svg
    ?.selectAll("circle")
    .data(realTimeBubble)
    .enter()
    .append("circle")
    .attr("realTime", true)
    .attr("r", 4)
    .attr("id", bubblesData.length - 1)
    .style("fill", (d: any) => setColor(d.color))
    .style("stroke", (d: any) => setColor(d.color))
    .attr("visitor_id", (d) => d.visitor_id)
    .attr("show-menu", (d) => d.name)
    .attr("remove", "no")
    .attr("createdAt", (d) => d.createdAt)
    .attr("pageName", (d) => d.pageName)
    .on("mouseover", (d) => showRealtimeTooltip(d))
    .on("mouseout", () => hideRealtimeTooltip());
  if (realTimeBubble && realTimeBubble.length > 0) {
    for (let i = 0; i < realTimeBubble.length; i++) {
    await  handleVisitorMovePagesModel(null, realTimeBubble[i]?.pageName);
    }
  }
  eventEmitter.emit("drawExistingUserBubble");
};

export const fillClusterEmpty = (clusterIndex: any) => {
  const clusterTextInstance = clusterTextInstances[clusterIndex];
  const clusterRectInstance = clusterRectInstances[clusterIndex];
  clusterTextInstance?.text("");
  clusterTextInstance?.attr("real-text", "");
  clusterTextInstance?.attr("is-wrap", false);
  clusterRectInstance?.style("fill", d3.rgb(227, 231, 231));
  clusterRectInstance?.attr("selected", false);
};

export const fillClusterSelected = (clusterIndex: any, pathName: any) => {
  const clusterTextInstance = clusterTextInstances[clusterIndex];
  const clusterRectInstance = clusterRectInstances[clusterIndex];
  const { isWrap: wrapped, pathName: wrappedName } = wrapPath(pathName);

  clusterTextInstance?.text(wrappedName);
  clusterTextInstance?.attr("real-text", pathName);
  clusterTextInstance?.attr("is-wrap", wrapped);
  clusterRectInstance?.style("fill", d3.rgb(255, 255, 255));
  clusterRectInstance?.attr("selected", true);
};

export const updatedClusterIndex = () => {
  return { clusterTextInstances, clusterRectInstances };
};

export const addOrRemovePageBubble = (eventName: string, pageName: string) => {
  const bubbleParametersData = returnBubbleParameterData();

  return new Promise((resolve) => {
    if (eventName === "addPage") {
      const realTimeBubble = bubblesData.filter((item) => item.realTime);

      const pageSimulation = d3
        .forceSimulation()
        .force("collision", d3.forceCollide().radius(4).strength(1))
        .force(
          "charge",
          d3
            .forceManyBody()
            .strength(
              bubbleParametersData && bubbleParametersData.charge
                ? parseInt(bubbleParametersData.charge)
                : DEFAULT_D3CIRCLE_CONSTRAINT.charge
            )
        )
        .force("gravity", d3.forceManyBody().strength(0.2))
        .force(
          "x",
          d3.forceX((d: any) => findForceCenter(d.pageName, "x")).strength(0.05)
        )
        .force(
          "y",
          d3.forceY((d: any) => findForceCenter(d.pageName, "y")).strength(0.05)
        )
        .on("tick", () => {
          svg
            ?.selectAll(`circle[pageName='${pageName}']`)
            .transition()
            .duration(
              bubbleParametersData.speed
                ? bubbleParametersData.speed
                : DEFAULT_D3CIRCLE_CONSTRAINT.speed
            )
            .attr("cx", (d: any) => d.x)
            .attr("cy", (d: any) => d.y);
        });

      pageSimulation.nodes(realTimeBubble).alpha(0.9).restart();
    } else if ("removePage") {
      const processedPageData = [];
      const notSelectedPage = data.map((item) => {
        if (!processedPageData.includes(item.name)) {
          processedPageData.push(item.name);
          if (!item.selected) {
            return item.name;
          }
        }
      });

      const bubbleCoords =
        clusterCenterCoords[DEFAULT_VALUE.UNKNOWN_URL_CLUSER_INDEX];

      const newBubbleData = bubblesData.filter((item) => {
        if (
          (item?.realTime && notSelectedPage.includes(item?.pageName)) ||
          item?.pageName === pageName
        ) {
          return item;
        }
      });

      const simulation = d3
        .forceSimulation()
        .force("collision", d3.forceCollide().radius(4).strength(1))
        .force(
          "charge",
          d3
            .forceManyBody()
            .strength(
              bubbleParametersData && bubbleParametersData.charge
                ? parseInt(bubbleParametersData.charge)
                : DEFAULT_D3CIRCLE_CONSTRAINT.charge
            )
        )
        .force("gravity", d3.forceManyBody().strength(0.2))
        .force("x", d3.forceX(bubbleCoords?.x).strength(0.05))
        .force("y", d3.forceY(bubbleCoords?.y).strength(0.05))
        .on("tick", () => {
          svg
            ?.selectAll("circle")
            .filter((d) => d.realTime && notSelectedPage.includes(d?.pageName))
            .transition()
            .duration(
              bubbleParametersData.speed
                ? bubbleParametersData.speed
                : DEFAULT_D3CIRCLE_CONSTRAINT.speed
            )
            .attr("cx", (d: any) => (d.x ? d.x : bubbleCoords?.x))
            .attr("cy", (d: any) => (d.y ? d.y : bubbleCoords?.y));
        });

      simulation.nodes(newBubbleData).alpha(0.9).restart();
    }
    resolve("ok");
  });
};
// bubble data

export const drawHistoricalBubble = async (record) => {
  const clusterIndex = 11;
  if (!clusterCenterCoords) {
    clusterCenterCoords = getClusterCoords(mainSvgWidth);
  }
  const historicalCoords =
    clusterCenterCoords && clusterCenterCoords[clusterIndex];

    historicalCoords.x = clusterCenterCoords[DEFAULT_VALUE.ALL_CLUSTER_INDEX].x-10; 
  for (let i = 0; i < record.length; i++) {
    const { bubbleName, userValue } = record[i];

    const radius =
      Math.ceil(Math.log(userValue) * 4) < 4
        ? 4
        : Math.ceil(Math.log(userValue) * 4);

    if (bubblesData.length > 20) {
      svg?.attr("height", 1100);
      historicalCoords.y = clusterCenterCoords[10].y - 50 + 100 + 100 +40;
      historicalCoords.exteraY =
        ((window.innerHeight * 0.9 > 500 ? window.innerHeight * 0.85 : 500) *
          0.9 -
          250) *
          (50 / 36) +
        400;
    } else {
      svg?.attr("height", mainSvgHeight);
      historicalCoords.y = clusterCenterCoords[10].y - 50 + 100 + 100 +40;
      historicalCoords.exteraY =
        ((window.innerHeight * 0.9 > 500 ? window.innerHeight * 0.85 : 500) *
          0.9 -
          250) *
          (50 / 36) +
        250;
    }

    const currentBubble = getItemByName(bubbleName);
    bubblesData = bubblesData.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
    const bubble = {
      x: historicalCoords?.x,
      y: historicalCoords?.y,
      radius,
      color: currentBubble?.color,
      colorInfo: currentBubble?.colorInfo,
      cluster: clusterIndex,
      historical: true,
      id: clusterIndex,
      menuName: bubbleName,
      user: userValue,
      currentUrlName: "menu-nodes",
    };
    bubblesData.push(bubble);
  }

  drawBubbleNow(historicalCoords);
};

const drawBubbleNow = (historicalCoords) => {
  const bubbleParametersData = returnBubbleParameterData();

  const historicalBubbleData = bubblesData.filter(
    (item) => item?.historical === true && item.menuName
  );

  const historicalSimulation = d3
    .forceSimulation()
    .force(
      "collision",
      d3
        .forceCollide()
        .radius((d: any) => +d.radius)
        .strength(1)
    )
    .force(
      "charge",
      d3
        .forceManyBody()
        .strength(
          bubbleParametersData && bubbleParametersData.charge
            ? parseInt(bubbleParametersData.charge)
            : DEFAULT_D3CIRCLE_CONSTRAINT.charge
        )
    )
    .force("gravity", d3.forceManyBody().strength(0.2))
    .force("x", d3.forceX(historicalCoords?.x).strength(0.05))
    .force("y", d3.forceY(historicalCoords?.y).strength(0.05))
    .on("tick", () => {
      svg
        ?.selectAll("circle[historical=true]")
        .transition()
        .duration(
          bubbleParametersData.speed
            ? bubbleParametersData.speed
            : DEFAULT_D3CIRCLE_CONSTRAINT.speed
        )
        .attr("cx", (d: any) => d.x)
        .attr("cy", (d: any) => d.y);
    });

  historicalSimulation.nodes(historicalBubbleData).alpha(0.9).restart();

  svg
    ?.selectAll("circle[historical=true]")
    .data(historicalBubbleData)
    .enter()
    .append("circle")
    .attr("cx", (d) => d.x)
    .attr("cy", (d) => d.y)
    .attr("r", (d) => d.radius)
    .attr("id", historicalBubbleData.length - 1)
    .attr("historical", true)

    .attr("stroke-width", "1")
    .attr("class", "sub_circle")
    .attr("show-menu", (d) => d.name)
    .attr("user", (d) => d.user)
    .attr("sub-menu-flag", true)
    .attr("remove", "no")
    .style("stroke", (d) => setColor(d.color))
    .attr("name", (d) => d.menuName)
    .style("fill", (d: any) => setColor(d.color))
    .on("mouseover", (d) => showHistoricalTooltip(d))
    .on("mouseout", () => hideHistoricalTooltip());
};
const colorPalette = d3.scaleOrdinal(d3.schemeCategory10);

export const setColor = (colorKey: any) => {
  return d3.rgb(colorPalette(colorKey));
};

export const createRealtimeListItem = (name: string, totalUsers: number) => {
  const usersCount = bubblesData

    .filter((bubble: any) => {
      return !!bubble.sessionData;
    })
    .reduce((acc: any, bubble: any) => {
      if (bubble.sessionData[selectedFilter?.menuType] === name) {
        return ++acc;
      }

      return acc;
    }, 0);
  if (!items.filter((data) => data.name === name).length) {
    items.push({
      name,
      currentUsers: usersCount,
      totalUsers,
      color: currentBubbleColor,
      colorInfo: setColor(currentBubbleColor),
      display: true,
    });
    
    items.sort((a, b) => {
      // Sort in descending order based on currentUsers, giving priority to currentUsers
      if (a.currentUsers > b.currentUsers) return -1;
      if (a.currentUsers < b.currentUsers) return 1;
    
      // If currentUsers are equal, then sort based on totalUsers
      if (a.totalUsers > b.totalUsers) return -1;
      if (a.totalUsers < b.totalUsers) return 1;
    
      // If both currentUsers and totalUsers are equal, maintain the original order
      return 0;
    });
  }
  currentBubbleColor++;
};

export const getItemByName = (itemName: string) => {
  return items && items.find((item) => item.name === itemName);
};
const returnBubbleParameterData = () => {
  return storage.get("bubbleParameters")
    ? typeof storage.get("bubbleParameters") === "string"
      ? JSON.parse(storage.get("bubbleParameters"))
      : storage.get("bubbleParameters")
    : null;
};

export const drawUserBubble = async (sessionData) => {
  const bubbleParametersData = returnBubbleParameterData();

  return new Promise(async(resolve) => {
    let otherPage = false;
    let menuIndex = 0;
    const realtimeType: string = sessionData[selectedFilter?.menuType];

    if (!realtimeType) {
      return;
    }
    if (!getItemByName(realtimeType)) {
      createRealtimeListItem(realtimeType, 0);
      drawHistoricalBubble({ bubbleName: realtimeType, userValue: 0 });

      eventEmitter.emit(
        "sideBarRealtimeItemsUpdated",
        JSON.parse(JSON.stringify(items))
      );
    }

    const currentItemData = getItemByName(realtimeType);
    onsiteUser++;
    if (currentItemData) {
      currentItemData.currentUsers++;
    
    }


    for (let i = 0; i < items.length; i++) {
      if (items[i].name === realtimeType) {
        menuIndex = i;
      }
    }
    items.sort((a, b) => {
      // Sort in descending order based on currentUsers, giving priority to currentUsers
      if (a.currentUsers > b.currentUsers) return -1;
      if (a.currentUsers < b.currentUsers) return 1;
      
      // If currentUsers are equal, then sort based on totalUsers
      if (a.totalUsers > b.totalUsers) return -1;
      if (a.totalUsers < b.totalUsers) return 1;
      
      // If both currentUsers and totalUsers are equal, maintain the original order
      return 0;
    });

    const bubbleCoords = {
      x: 100,

      y: (menuIndex + 1) * 20 + 60,
    };
    const visitedPage = data.find(
      (page: any) => page.selected && page.name === sessionData.current_url_name
    );
    let clusterIndex = null;
    if (visitedPage) {
      clusterIndex = visitedPage.slotIndex;
    } else if (pageViewDisabled) {
      clusterIndex = DEFAULT_VALUE.ALL_CLUSTER_INDEX;
    } else {
      otherPage = true;
      clusterIndex = DEFAULT_VALUE.UNKNOWN_URL_CLUSER_INDEX;
    }

    if (pageViewDisabled) {
      bubbleCoords.x = clusterCenterCoords[DEFAULT_VALUE.ALL_CLUSTER_INDEX].x;
      bubbleCoords.y = clusterCenterCoords[DEFAULT_VALUE.ALL_CLUSTER_INDEX].y;
    } else {
      bubbleCoords.x = clusterCenterCoords[clusterIndex].x;
      bubbleCoords.y = clusterCenterCoords[clusterIndex].y;
    }

    if (
      pageWiseCount[sessionData.current_url_name] ||
      pageWiseCount[sessionData.current_url_name] === 0
    ) {
      pageWiseCount[sessionData.current_url_name] += 1;
    } else {
      pageWiseCount["allOtherPages"] += 1;
    }

    bubblesData.push({
      x: +bubbleCoords?.x,
      y: +bubbleCoords?.y,
      radius: 4,
      realTime: true,
      color: currentItemData?.color,
      cluster: DEFAULT_VALUE.UNKNOWN_URL_CLUSER_INDEX,
      id: DEFAULT_VALUE.UNKNOWN_URL_CLUSER_INDEX,
      number: bubblesData?.length ? bubblesData?.length : 0,
      pageUserCount: pageWiseCount[sessionData.current_url_name],
      visitor_id: sessionData._id,
      name: realtimeType,
      pageName: sessionData.current_url_name,
      currentUrlName: sessionData.current_url_name,
      createdAt: new Date(sessionData.createdAt).toISOString(),

      sessionData: {
        browser: sessionData.browser,
        device: sessionData.device,
        visitor_type: sessionData.visitor_type,
        country_name: sessionData.country_name,
        os: sessionData.os,
        traffic_source: sessionData.traffic_source,
        search_engine: sessionData.search_engine,
        referrer: sessionData.referrer,
      },
    });

    const notSelectedPage = data.map((item) => {
      if (!item.selected) {
        return item.name;
      }
    });
    const newBubbleData = bubblesData.filter((item) => {
      if (
        pageViewDisabled
          ? item?.realTime
          : otherPage
          ? item?.realTime && notSelectedPage.includes(item?.pageName)
          : item?.pageName === sessionData.current_url_name
      ) {
        return item;
      }
    });

    const selectBubbleString =
      pageViewDisabled || otherPage
        ? "circle[realTime=true]"
        : `circle[pageName='${sessionData.current_url_name}']`;
    const simulation = d3
      .forceSimulation()
      .force("collision", d3.forceCollide().radius(4).strength(1))
      .force(
        "charge",
        d3
          .forceManyBody()
          .strength(
            bubbleParametersData && bubbleParametersData.charge
              ? parseInt(bubbleParametersData.charge)
              : DEFAULT_D3CIRCLE_CONSTRAINT.charge
          )
      )
      .force("gravity", d3.forceManyBody().strength(0.2))
      .force("x", d3.forceX(bubbleCoords?.x).strength(0.05))
      .force("y", d3.forceY(bubbleCoords?.y).strength(0.05))
      .on("tick", () => {
        svg
          ?.selectAll("circle")
          .filter((d) =>
            pageViewDisabled
              ? d.realTime && d.realTime === true
              : otherPage
              ? d.realTime && notSelectedPage.includes(d?.pageName)
              : d.pageName === sessionData.current_url_name
          )
          .transition()
          .duration(
            bubbleParametersData.speed
              ? bubbleParametersData.speed
              : DEFAULT_D3CIRCLE_CONSTRAINT.speed
          )
          .attr("cx", (d: any) => {
            return d.x ? d.x : bubbleCoords?.x;
          })
          .attr("cy", (d: any) => (d.y ? d.y : bubbleCoords?.y));
      });

    simulation.nodes(newBubbleData).alphaDecay(0.9).restart();

    svg
      ?.selectAll("circle")
      .filter((d) =>
        pageViewDisabled
          ? d.realTime && d.realTime === true
          : otherPage
          ? d.realTime && notSelectedPage.includes(d?.pageName)
          : d.pageName === sessionData.current_url_name
      )
      .data(newBubbleData)
      .enter()
      .append("circle")
      .attr("cx", -130)
      .attr("cy", 100)
      .attr("realTime", true)
      .attr("r", 4)
      .attr("id", bubblesData.length - 1)
      .style("fill", (d: any) => setColor(d.color))
      .style("stroke", (d: any) => setColor(d.color))
      .attr("visitor_id", sessionData._id)
      .attr("show-menu", (d) => d.name)
      .attr("remove", "no")
      .attr("createdAt", (d) => d.createdAt)
      .attr("pageName", sessionData.current_url_name)
      .on("mouseover", (d) => showRealtimeTooltip(d))
      .on("mouseout", () => hideRealtimeTooltip());

  await  handleVisitorMovePagesModel(null, sessionData.current_url_name);
    eventEmitter.emit("userCountChanged", onsiteUser);
    resolve("ok");
  });
};

const visitorLastPage = {};

export const handleVisitorMove = async(
  urlTrackingData,
  newVisitor = false,
  connectionType?: string
) => {
  const bubbleParametersData = returnBubbleParameterData();
  const node = JSON.parse(JSON.stringify(bubblesData)).find(
    (node: any) => node.visitor_id === urlTrackingData.sessionId
  );

  if (!node) {
    return;
  }
  const previousUrl = newVisitor
    ? null
    : JSON.parse(JSON.stringify(node)).pageName;

 await handleVisitorMovePagesModel(previousUrl, urlTrackingData.pathName);
 eventEmitter.emit("sessionChanged", urlTrackingData);
  const selectedPage = data.find(
    (page: any) => page.name === urlTrackingData.pathName
  );

  if (
    selectedPage &&
    selectedPage.slotIndex < DEFAULT_VALUE.UNKNOWN_URL_CLUSER_INDEX
  ) {
    node.id = selectedPage.slotIndex;
    node.cluster = selectedPage.slotIndex;
    node.currentUrlName = urlTrackingData.pathName;
  } else {
    node.id = DEFAULT_VALUE.UNKNOWN_URL_CLUSER_INDEX;
    node.cluster = DEFAULT_VALUE.UNKNOWN_URL_CLUSER_INDEX;
    node.currentUrlName = urlTrackingData.pathName;
  }

  if (previousUrl && urlTrackingData.pathName && !connectionType) {
    if (pageWiseCount[previousUrl] || pageWiseCount[previousUrl] === 0) {
      if (node.currentUrlName === urlTrackingData.pathName) {
        pageWiseCount[visitorLastPage[urlTrackingData.sessionId]] -= 1;
        pageWiseCount[urlTrackingData.pathName] += 1;
      }
      if (pageWiseCount[previousUrl] !== 0) {
        pageWiseCount[previousUrl] -= 1;
        pageWiseCount[urlTrackingData.pathName] += 1;
      }
    } else {
      if (pageWiseCount[previousUrl] !== 0) {
        pageWiseCount["allOtherPages"] += 1;
        pageWiseCount[previousUrl] -= 1;
      }
    }
  }

  // new code end

  const nextPageCoordinate = {
    x:
      pages.filter((ele) => ele.name === urlTrackingData.pathName)[0] &&
      (pages.filter((ele) => ele.name === urlTrackingData.pathName)[0]
        .slotIndex ||
        pages.filter((ele) => ele.name === urlTrackingData.pathName)[0]
          .slotIndex === 0)
        ? clusterCenterCoords[
            pages.filter((ele) => ele.name === urlTrackingData.pathName)[0]
              .slotIndex
          ]?.x
        : clusterCenterCoords[10].x,
    y:
      pages.filter((ele) => ele.name === urlTrackingData.pathName)[0] &&
      (pages.filter((ele) => ele.name === urlTrackingData.pathName)[0]
        .slotIndex ||
        pages.filter((ele) => ele.name === urlTrackingData.pathName)[0]
          .slotIndex === 0)
        ? clusterCenterCoords[
            pages.filter((ele) => ele.name === urlTrackingData.pathName)[0]
              .slotIndex
          ]?.y
        : clusterCenterCoords[10].y,
  };

  bubblesData.forEach((item, index) => {
    if (item.visitor_id === urlTrackingData.sessionId) {
      bubblesData[index].pageName = urlTrackingData.pathName;
      bubblesData[index].x = nextPageCoordinate.x;
      bubblesData[index].y = nextPageCoordinate.y;
    }
  });

  const nextPageData: any[] = bubblesData.filter(
    (item) =>
      item?.pageName === urlTrackingData.pathName ||
      item.visitor_id === urlTrackingData.sessionId
  );

  if (!pageViewDisabled) {
    const simulation = d3
      .forceSimulation()
      .force("collision", d3.forceCollide().radius(4).strength(1))
      .force(
        "charge",
        d3
          .forceManyBody()
          .strength(
            bubbleParametersData && bubbleParametersData.charge
              ? parseInt(bubbleParametersData.charge)
              : DEFAULT_D3CIRCLE_CONSTRAINT.charge
          )
      )
      .force("gravity", d3.forceManyBody().strength(0.2))
      .force("x", d3.forceX(nextPageCoordinate?.x).strength(0.05))
      .force("y", d3.forceY(nextPageCoordinate?.y).strength(0.05))
      .on("tick", () => {
        svg
          ?.selectAll("circle[realTime=true]")
          .transition()
          .duration(
            bubbleParametersData.speed
              ? bubbleParametersData.speed
              : DEFAULT_D3CIRCLE_CONSTRAINT.speed
          )
          .attr("cx", (d: any) => d.x)
          .attr("cy", (d: any) => d.y)
          .attr("pageName", (d) => {
            if (d.visitor_id === urlTrackingData.sessionId) {
              return urlTrackingData.pathName;
            } else {
              return d.pageName;
            }
          });
      });

    simulation.nodes(nextPageData).alpha(0.9).restart();
  }
};

export const handleVisitorLeave = (itemName: string) => {
  const foundItem = getItemByName(itemName);

  foundItem.currentUsers--;

  foundItem.totalUsers++;
  items.sort((a, b) => {
    // Sort in descending order based on currentUsers, giving priority to currentUsers
    if (a.currentUsers > b.currentUsers) return -1;
    if (a.currentUsers < b.currentUsers) return 1;
    
    // If currentUsers are equal, then sort based on totalUsers
    if (a.totalUsers > b.totalUsers) return -1;
    if (a.totalUsers < b.totalUsers) return 1;
    
    // If both currentUsers and totalUsers are equal, maintain the original order
    return 0;
  });

};

let eventsEnabled: boolean;
export const handleFilterChange = async (filterSelected) => {
  currentBubbleColor = 1;
  selectedFilter = filterSelected;

  items = [];
  if (eventsEnabled) {
    eventEmitter.emit("realtimeFilterChanged");
  }
};

export const enableEvents = () => {
  eventsEnabled = true;
};

export const disableEvents = () => {
  eventsEnabled = false;
};

// date range filter change

eventEmitter.addListener("filterChanged", () => {
  items = [];
  removeHistoricalBubbles();
  const realtimeMenuItems = new Set<string>();

  bubblesData.forEach((bubble) => {
    const listItemName =
      bubble?.sessionData[
        selectedFilter ? selectedFilter?.menuType : "country_name"
      ];
    realtimeMenuItems.add(listItemName);
  });
  for (const listItemName of realtimeMenuItems) {
    createRealtimeListItem(listItemName, 0);

    bubblesData
      .filter(
        (bubble) => bubble.sessionData[selectedFilter?.menuType] === listItemName
      )
      .forEach((bubble) => {
        const currentItem = getItemByName(listItemName);
        bubble.color = currentItem.color;
        bubble.name = listItemName;
      });
  }
  svg
  ?.selectAll("circle[realTime=true]")
  ?.style("fill", (d) => setColor(d.color))
  ?.style("stroke", (d) => setColor(d.color));

  eventEmitter.emit(
    "sideBarRealtimeItemsUpdated",
    JSON.parse(JSON.stringify(items))
  );
  initialHistoryFlag = false;
  updateSiteInfo();
  getPagesStatistics();

  getHistoricalBubblesData();
  getMetricsData({
    startDate: selectedDateRange && selectedDateRange.value
    ? new Date(selectedDateRange.value).toISOString()
    : new Date(new Date().setHours(0,0,0,0)).toISOString(),
    endDate: selectedEndDateRange
      ? selectedEndDateRange
      : new Date().toISOString(),
  });
});

// realtime filter change for clusters

export const realtimeFilterChangedForClusters = () => {
  currentBubbleColor = 1;
  selectedFilter = selectedFilter;
  items = [];
};

export const removeHistoricalBubbles = () => {
  bubblesData = bubblesData.filter((node) => node.cluster !== 11);
  // DO NOT make chain from the actions below!

  bubblesSelection = bubblesSelection?.data(
    JSON.parse(JSON.stringify(bubblesData)),

    (d: any) => d.index
  );
  // d3.selectAll("circle").remove();
  svg?.selectAll("circle[historical=true]").remove();
};

const updateSiteInfo = async () => {
  const requestParams = {
    id: 0,
    jsonprc: "2.0",
    method: "update",
    params: {
      siteId:storage.get("siteId"),
      selectedViews: {
        showListView: true,
        selectedSource: selectedFilter,
        selectedTimeSource: selectedDateRange.name || "Today",
        selectedCustomTimeSource:
          selectedDateRange.name === "Custom Range"
            ? selectedDateRange.name
            : null,
      },
    },
  };
  const tempo = await site(requestParams);
  return tempo;
};

const getHistoricalBubblesData = async () => {
  const param = {
    id: 0,
    jsonprc: "2.0",
    method: "getHistoricalBubblesData",
    params: {
      endDate: selectedEndDateRange
        ? selectedEndDateRange
        : new Date().toISOString(),
      startDate: selectedDateRange.value,
      siteId: storage.get("siteId"),
      menuType: selectedFilter?.menuType,
    },
  };

  const getHistoryData = await session(param);

  const bubbledData = getHistoryData?.data?.result;
  // Create realtime item bubbles data
  const newBubbledData = [];
  const historicalBubbleData = [];

  for (const bubbleName in bubbledData) {
    const currentItem = getItemByName(bubbleName);

    currentItem
      ? currentItem.totalUsers++
      : createRealtimeListItem(bubbleName, bubbledData[bubbleName]);

    newBubbledData.push(bubbleName);
    historicalBubbleData.push({
      bubbleName: bubbleName,
      userValue: bubbledData[bubbleName],
    });
  }
  removeHistoricalBubbles();
  drawHistoricalBubble(historicalBubbleData);
  eventEmitter.emit(
    "sideBarRealtimeItemsUpdated",
    JSON.parse(JSON.stringify(items))
  );

  const rectAllOtherPages =
    clusterCenterCoords[9].x - 50 + 150 - (clusterCenterCoords[0]?.x - 50);
  const historyIndex = Math.floor(rectAllOtherPages / 64);

  for (let i = 0; i < newBubbledData.length; i++) {
    const centerValue = 140 + i * 64 + 32;
    if (centerValue < rectAllOtherPages + 140 - 64) {
      historyIndexRectCenters[newBubbledData[i]] = {};
      historyIndexRectCenters[newBubbledData[i]].x = 140 + i * 64 + 32;
      historyIndexRectCenters[newBubbledData[i]].y = 0;
      //  {x: 140 + (i*64) + 32, y: 0};
    } else {
      historyIndexRectCenters[newBubbledData[i]] = {};
      historyIndexRectCenters[newBubbledData[i]].x =
        140 + (i - Math.floor(i / historyIndex) * historyIndex) * 64 + 32;
      historyIndexRectCenters[newBubbledData[i]].y =
        Math.floor(i / historyIndex) * 64;
    }
  }
  if (!initialHistoryFlag) {
    const names = Object.keys(bubbledData);
    const tempoArray = items.filter((element) => names.includes(element.name));
    items = tempoArray;
  }
};

export const DateRangeFilterSet = (dateRange: any, endDate?: any) => {
  if (endDate) {
    selectedEndDateRange = endDate.value;
  }
  selectedDateRange = dateRange;
  eventEmitter.emit("filterChanged");
};

export const moveBubbleToHistoricalCluster = async(sessionId: string) => {
  const bubbleParametersData = returnBubbleParameterData();

  const userBubble = bubblesData.find((node) => node.visitor_id === sessionId);

  if (!userBubble) {
    return;
  }

  // handleVisitorMove(userBubble.currentUrlName, null, "disconnect");
 await handleVisitorMovePagesModel(userBubble.pageName, null);

  const realtimeListItemName = userBubble.sessionData[selectedFilter?.menuType];
  handleVisitorLeave(realtimeListItemName);
  onsiteUser--;

  eventEmitter.emit("userCountChanged", onsiteUser);
  const userNodeIndex = bubblesData.findIndex(
    (node) => node.visitor_id === userBubble.visitor_id
  );

  bubblesData?.splice(userNodeIndex, 1);

  bubblesSelection[0]?.splice(userNodeIndex, 1);

  const parsedVisitorId = sessionId.replace(/'/g, " ");

  const historicalNode = svg.select(`circle[name='${userBubble.name}']`);

  if (historicalNode._groups[0][0]) {
    svg
      ?.selectAll(`circle[visitor_id='${parsedVisitorId}']`)
      .transition()
      .duration(1600)

      .attr(
        "transform",
        `translate(${-(
          userBubble.x - historicalNode?._groups[0][0]?.getAttribute("cx")
        )},${-(
          userBubble.y - historicalNode?._groups[0][0]?.getAttribute("cy")
        )})`
      )
      .on("end", (bubble: any) => {
        historical.totalSession++;
        const newHistoricalValue =
          parseInt(historicalNode?._groups[0][0]?.getAttribute("user")) + 1;
        updateBubble(bubble.name, newHistoricalValue);
      })
      .remove();
  } else {
    const currentBubblePosition = svg.select(
      `circle[visitor_id='${parsedVisitorId}']`
    );

    const clusterIndex = 11;

    const historicalCoords =
      clusterCenterCoords && clusterCenterCoords[clusterIndex];

    svg
      ?.selectAll(`circle[visitor_id='${parsedVisitorId}']`)
      .transition()
      .duration(1600)

      .attr(
        "transform",
        (d) =>
          `translate(${
            historicalCoords.x -
            currentBubblePosition?._groups[0][0].getAttribute("cx")
          },${
            historicalCoords.y -
            currentBubblePosition?._groups[0][0].getAttribute("cy")
          })`
      )
      .on("end", (bubble: any) => {
        historical.totalSession++;
        drawNewHistoricalBubble(bubble.name, 1);
      })
      .remove();
  }
};

const clearClusterSelection = () => {
  for (let i = 1; i < 10; i++) {
    fillClusterEmpty(i);
  }
};

const drawNewHistoricalBubble = (name, value) => {
  const bubbleParametersData = returnBubbleParameterData();
  const clusterIndex = 11;

  const historicalCoords =
    clusterCenterCoords && clusterCenterCoords[clusterIndex];

  const currentBubble = getItemByName(name);

  const bubbleCreation = {
    x: historicalCoords?.x,
    y: clusterCenterCoords[10].y - 50 + 100 + 100 +40,
    radius: 4,
    color: currentBubble?.color,
    colorInfo: currentBubble?.colorInfo,
    cluster: clusterIndex,
    historical: true,
    id: clusterIndex,
    menuName: name,
    user: value,
    currentUrlName: "menu-nodes",
  };
  bubblesData.push(bubbleCreation);

  const historicalBubbleData = bubblesData.filter(
    (item) => item?.historical === true && item.menuName
  );

  const historicalSimulation = d3
    .forceSimulation()
    .force(
      "collision",
      d3
        .forceCollide()
        .radius((d: any) => +d.radius)
        .strength(1)
    )
    .force(
      "charge",
      d3
        .forceManyBody()
        .strength(
          bubbleParametersData && bubbleParametersData.charge
            ? parseInt(bubbleParametersData.charge)
            : DEFAULT_D3CIRCLE_CONSTRAINT.charge
        )
    )
    .force("gravity", d3.forceManyBody().strength(0.2))
    .force("x", d3.forceX(historicalCoords?.x).strength(0.05))
    .force("y", d3.forceY(historicalCoords?.y).strength(0.05))
    .on("tick", () => {
      svg
        ?.selectAll("circle[historical=true]")
        .attr("cx", (d: any) => d.x)
        .attr("cy", (d: any) => d.y);
    });

  historicalSimulation.nodes(historicalBubbleData).alpha(0.9).restart();

  svg
    ?.selectAll("circle[historical=true]")
    .data(historicalBubbleData)
    .enter()
    .append("circle")
    .attr("cx", historicalCoords?.x)
    .attr("cy", historicalCoords?.y)
    .attr("r", (d) => d.radius)
    .attr("id", historicalBubbleData.length - 1)
    .attr("historical", true)
    .attr("stroke-width", "1")
    .attr("class", "sub_circle")
    .attr("show-menu", (d) => d.name)
    .attr("user", (d) => d.user)
    .attr("sub-menu-flag", true)
    .attr("remove", "no")
    .style("stroke", (d) => setColor(d.color))
    .attr("name", (d) => d.menuName)
    .style("fill", (d: any) => setColor(d.color))
    .on("mouseover", (d) => showHistoricalTooltip(d))
    .on("mouseout", () => hideHistoricalTooltip());
};

const updateBubble = (name, value) => {
  eventEmitter.emit(
    "sideBarRealtimeItemsUpdated",
    JSON.parse(JSON.stringify(items))
  );

  const calculetedRadius = Math.ceil(Math.log(+value) * 4);
  const newRadius = calculetedRadius < 4 ? 4 : calculetedRadius;

  const bubbleToUpdate = bubblesData.find((bubble) => bubble.menuName === name);

  if (bubbleToUpdate) {
    bubbleToUpdate.radius = newRadius;
    bubbleToUpdate.user = value;

    const clusterIndex = 11;

    const historicalCoords =
      clusterCenterCoords && clusterCenterCoords[clusterIndex];

    bubblesData.forEach((item, index) => {
      if (item.menuName === name && item?.historical === true) {
        bubblesData[index].radius = newRadius;
        bubblesData[index].user = value;
      }
    });
    const historicalBubbleData = bubblesData.filter(
      (item) => item?.historical === true && item.menuName
    );

    svg
      ?.selectAll(`circle[name='${name}']`)
      ?.attr("r", newRadius)
      .attr("user", value);
  }
};

eventEmitter.addListener("realtimeFilterChanged", () => {
  items = [];
  removeHistoricalBubbles();
  const realtimeMenuItems = new Set<string>();

  bubblesData.forEach((bubble) => {
    const listItemName =
      bubble?.sessionData[
        selectedFilter ? selectedFilter?.menuType : "country_name"
      ];
    realtimeMenuItems.add(listItemName);
  });
  for (const listItemName of realtimeMenuItems) {
    createRealtimeListItem(listItemName, 0);

    bubblesData
      .filter(
        (bubble) => bubble.sessionData[selectedFilter?.menuType] === listItemName
      )
      .forEach((bubble) => {
        const currentItem = getItemByName(listItemName);
        bubble.color = currentItem.color;
        bubble.name = listItemName;
      });
  }

  eventEmitter.emit(
    "sideBarRealtimeItemsUpdated",
    JSON.parse(JSON.stringify(items))
  );
  svg
    ?.selectAll("circle[realTime=true]")
    ?.style("fill", (d) => setColor(d.color))
    ?.style("stroke", (d) => setColor(d.color));

  updateSiteInfo();
  getHistoricalBubblesData();
});

export const handleSubscriptionChange = (subscription: any) => {
  availableFilters = INITIAL_FILTERS.filter((filter) => {
    return !subscription.subscriptionData.restrictedRealtimeFilters.includes(
      filter?.menuType
    );
  });
  const dateRangeList = [];
  historyDataDateRange = DATEPICKER_RANGES.filter(
    (range) =>
      !subscription.subscriptionData.restrictedRangeFilters.includes(range)
  ).reduce((acc, rangeName) => {
    dateRangeList.push({ name: rangeName, value: getRangeDate(rangeName)[0] });
    return dateRangeList;
  }, []);
  if (subscription?.subscriptionData?.title !== "Base Jump") {
    historyDataDateRange.push({ name: "Custom Range", value: "Custom Range" });
  }
};
const getRangeDate = (periodName) => {
  switch (periodName) {
    case "Today": {
      return [new Date().setHours(0, 0, 0, 0)];
    }
    case "Last 2 Days": {
      return [
        new Date(
          new Date().setHours(0, 0, 0, 0) - 1 * 24 * 60 * 60 * 1000
        ).getTime(),
      ];
    }
    case "Last 7 Days": {
      return [
        new Date(
          new Date().setHours(0, 0, 0, 0) - 6 * 24 * 60 * 60 * 1000
        ).getTime(),
      ];
    }
    case "Last 30 Days": {
      return [
        new Date(
          new Date().setHours(0, 0, 0, 0) - 29 * 24 * 60 * 60 * 1000
        ).getTime(),
      ];
    }
    case "All": {
      return [storage.get("userCreatedAt")];
    }
  }
};

export const setInitialFilter = (data) => {
  availableFilters = INITIAL_FILTERS.filter(
    (filter) =>
      !data?.subscriptionData?.restrictedRealtimeFilters?.includes(filter?.menuType)
  );
  if (!selectedFilter) {
    selectedFilter = availableFilters[0];
  }
};
export const setInitialDateFilter = (data) => {
  const dateRangeList = [];
  historyDataDateRange = DATEPICKER_RANGES.filter(
    (range) => !data?.subscriptionData?.restrictedRangeFilters?.includes(range)
  ).reduce((acc, rangeName) => {
    dateRangeList.push({ name: rangeName, value: getRangeDate(rangeName)[0] });
    return dateRangeList;
  }, []);
  if (data?.subscriptionData?.title !== "Base Jump") {
    historyDataDateRange.push({ name: "Custom Range", value: "Custom Range" });
  }
};


eventEmitter.addListener("pages-changed", (pages: any) => {
  clearClusterSelection();
  setPages(pages);
});
