import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CommonButton from "../utilities/formElements/commonButton/CommonButton";
import {
  toastMessageError,
  toastMessageSuccess,
} from "../utilities/commonToastMessage";

import PaymentMethod from "../views/paymentModel/PaymentMethod";
import ActionType from "../../resources/enums";
import config from "../../config/config";

import {
  deleteAdminUserDetail,
  deleteUserDetail,
} from "../../services/superAdmin";
import { currentUser } from "../../services/auth";
import { subscription } from "../../services/susbscription";

import { disconnectSocket } from "../../util/socket";
import { emptyLocalStorageOnLogout } from "../../util/helper";
import storage from "../../util/storage";

import ROUTES from "../../constants/routes";
import { UseFulLinks } from "../../constants/commonConstants";

import CheckMark from "../../assets/images/check-mark.svg";
import SkydiveLogo from "../../assets/images/Skydive_logo_beta.svg";
import SuspendedIcon from "../../assets/images/suspended-icon.svg";
import { eventEmitter } from "../../eventEmitters/EventEmitters";

interface Iprops {
  className?: string;
  name?: string;
  defaultValue?: string;
  closeModel?: any;
  header?: boolean;
  title?: string;
  onClose?: () => void;
  hasImage?: boolean;
  id?: any;
  imageName?: string;
  heading?: string;
}
const InfoModel: React.FC<Iprops> = ({
  title,
  onClose,
  closeModel,
  name,
  header,
  hasImage,
  id,
  heading,
}) => {
  const { t: translation } = useTranslation();
  const dispatch = useDispatch();
  const authReducer: any = useSelector((state: any) => state?.AuthReducer);

  const publishableKey = config.stripePublishableKey;
  const stripePromise = loadStripe(publishableKey as string);

  const [changePaymentDetails, setChangePaymentDetails] =
    useState<boolean>(false);
  const [downgradeAccountModel, setDowngradeAccountModel] =
    useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const getAccountRecord = async () => {
    const data = await subscription({
      jsonprc: "2.0",
      method: "getAccountRecord",
      params: {
        userId: authReducer.authData._id,
      },
      id: 0,
    });
    dispatch({
      type: ActionType.SUBSCRIPTIONDATA,
      payload: data.data?.result,
    });
    storage.set("urlsToTrack", data.data?.result.subscriptionData.urlsToTrack);
    storage.set(
      "mainViewSlots",
      data.data?.result?.subscriptionData?.mainViewSlots
    );
  };

  const handleDowngrade = async () => {
    setLoading(true);
    const changeSubscription = await subscription({
      jsonprc: "2.0",
      method: "changeSubscription",
      params: {
        userId: authReducer.authData._id,
        subscriptionTitle: "Base Jump",
      },
      id: 0,
    });

    if (changeSubscription) {
      dispatch({
        type: ActionType.SUBSCRIPTIONTITLE,
        payload: { title: "" },
      });
      setSuccessModal(true);
      const currentUserData: any = await currentUser();
      storage.set("siteId", currentUserData.data.site._id);

      if (currentUserData && currentUserData?.data) {
        dispatch({
          type: ActionType.LOGIN,
          payload: currentUserData.data,
        });
      }
    } else {
      toastMessageError(translation("something_went_wrong"));
    }
    setLoading(false);
  };

  const handleCloseModal = () => {
    // Call the closeModal function received from the parent
    handleDeleteAdmin();
    onClose;
  };

  const handleDeleteAdmin = async () => {
    setLoading(true);
    if (window.location.pathname === ROUTES.ADMIN) {
      const result = await deleteAdminUserDetail(id);
      if (!result.error) {
        toastMessageSuccess(result.data?.successMessage);
      } else {
        toastMessageError(translation("something_went_wrong"));
      }
      onClickClodeModel();
    } else if (window.location.pathname === ROUTES.USERS) {
      const result = await deleteUserDetail(id);
      if (!result.error) {
        toastMessageSuccess(result.data?.successMessage);
      } else {
        toastMessageError(translation("something_went_wrong"));
      }
      setLoading(true);

      onClickClodeModel();
    }
  };

  const onClickClodeModel = () => {
    onClose;
  };
  return (
    <>
      <div className="modal show-modal common-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            {header && (
              <div className="modal-header">
                <h4 className="modal-title">{title}</h4>
                <button
                  type="button"
                  className="btnC btn-close btn-close-white"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    dispatch({
                      type: ActionType.CLOSEMODEL,
                      payload: { isClose: false },
                    });
                    onClose();
                  }}
                ></button>
              </div>
            )}
            <div
              className={
                name === "success" || name === "fail"
                  ? "modal-body common-body text-primary tracking-code-modal"
                  : "modal-body common-body text-primary order-complete-modal"
              }
            >
              {hasImage ? (
                name === "suspendAccount" ? (
                  <img
                    src={SuspendedIcon}
                    alt="down-icon"
                    className="successful-icon"
                  />
                ) : (
                  <img
                    src={CheckMark}
                    alt="down-icon"
                    className="successful-icon"
                  />
                )
              ) : null}
              {(name === "success" || name === "fail") && (
                <div className="congrats-header">
                  <h4>
                    {name === "success"
                      ? translation("congrats")
                      : translation("no_tracking_code_detected")}
                  </h4>
                  <p>
                    <strong>
                      {name === "success"
                        ? translation("code_installed_successfully")
                        : translation("we_are_not_able_to_detect_code")}
                    </strong>
                  </p>
                </div>
              )}

              <div
                className={
                  name === "success" || name === "fail" ? "congrats-body" : ""
                }
              >
                <h3>{heading}</h3>

                <p>
                  {name === "success" ? (
                    <>
                      {translation(
                        "we_detected_your_code_installed_and_you_can_be_your_way_to_skydiving"
                      )}
                      <br />
                      <br />
                      {translation("if_no_visitors_head_to_your_site")}
                    </>
                  ) : name === "fail" ? (
                    `${translation(
                      "please_ensure_to_install_your_tracking_code"
                    )}`
                  ) : name === "suspendAccount" ? (
                    <>
                      {translation(
                        "this_is_likely_happened_due_to_non_payment"
                      )}
                      <br />
                      <br />
                      {translation(
                        "please_update_your_payment_details_in_settings"
                      )}
                    </>
                  ) : name === "OrderComplete" ? (
                    `${translation("processed_your_order")}`
                  ) : name === "downgradeModel" ? (
                    `${translation("notification_has_been_sent")}`
                  ) : name === "downgradeConfirmation" ? (
                    <>
                      {translation("you_are_about_to_downgrade_your_account")}
                      <br />
                      <br />
                      {translation("you_cannot_reverse_this_action")}
                    </>
                  ) : (
                    translation("are_you_sure_want_to_continue")
                  )}
                </p>

                <div className="button-align-modal flex-align-modal-btn">
                  {name === "success" ||
                  name === "fail" ||
                  name === "OrderComplete" ||
                  name === "downgradeModel" ? (
                    <CommonButton
                      className="theme-btn success-btn text-uppercase fontBold"
                      type="button"
                      label={
                        name === "success" || name === "fail"
                          ? translation("ok")
                          : translation("lets_skydive")
                      }
                      onClick={
                        name === "success" || name === "fail"
                          ? closeModel
                          : () => {
                              dispatch({
                                type: ActionType.CLOSEMODEL,
                                payload: { isClose: !authReducer?.isClose },
                              });
                              onClose();
                            }
                      }
                    />
                  ) : name === "suspendAccount" ? (
                    <>
                      <div>
                        <CommonButton
                          type="submit"
                          label={translation("upgrade_payment_details")}
                          className="theme-btn success-btn text-uppercase fontBold"
                          onClick={() => {
                            setChangePaymentDetails(true);
                          }}
                        />
                        <CommonButton
                          type="submit"
                          label={translation("downgrade_your_account")}
                          className="theme-btn success-btn text-uppercase fontBold"
                          onClick={() => {
                            setDowngradeAccountModel(true);
                          }}
                        />
                        <Link to={UseFulLinks.contactUs} target="_blank">
                          <CommonButton
                            type="submit"
                            label="contact us "
                            className="theme-btn success-btn text-uppercase fontBold"
                          />
                        </Link>
                      </div>

                      <button
                        className="logout-btn"
                        onClick={() => {
                          dispatch({
                            type: ActionType.LOGOUT,
                            payload: "",
                          });
                          disconnectSocket();
                          emptyLocalStorageOnLogout();
                        }}
                      >
                        Logoff
                      </button>
                    </>
                  ) : (
                    <>
                      <div className="downgrade-btns">
                        <CommonButton
                          type="button"
                          label={
                            name === "downgradeConfirmation"
                              ? translation("cancel_downgrade")
                              : translation("cancel")
                          }
                          className="theme-btn success-outline-btn transparent text-uppercase fontBold"
                          onClick={onClose}
                        />
                        <CommonButton
                          type="submit"
                          label={
                            name === "downgradeConfirmation"
                              ? translation("confirm_downgrade")
                              : translation("admin_delete")
                          }
                          className="theme-btn success-btn text-uppercase fontBold me-3"
                          onClick={
                            name === "downgradeConfirmation"
                              ? handleDowngrade
                              : handleCloseModal
                          }
                          loading={loading}
                          disabled={loading}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {hasImage &&
            (name === "OrderComplete" || name === "downgradeModel") ? (
              <img src={SkydiveLogo} alt="logo" className="company_logo" />
            ) : null}
          </div>
        </div>
      </div>
      {changePaymentDetails && (
        <Elements stripe={stripePromise}>
          <PaymentMethod
            onClose={() => {
              getAccountRecord();
              setChangePaymentDetails(false);
            }}
            modelName="updateSuspendedAccountPaymentDetails"
          />
        </Elements>
      )}
      {downgradeAccountModel && (
        <InfoModel
          title={"Downgrade Your Account"}
          onClose={() => {
            setDowngradeAccountModel(false);
          }}
          name="downgradeConfirmation"
          header={true}
        />
      )}
      {successModal && (
        <InfoModel
          onClose={() => {
            getAccountRecord();
            setSuccessModal(false);
          }}
          name="downgradeModel"
          title="Account Changes Complete"
          header={true}
          hasImage={true}
        />
      )}
    </>
  );
};

export default InfoModel;
