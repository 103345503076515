import ApiResponse from "../resources/domain/entity/IApiResponse";
import * as http from "../util/http";
import endpoint from "../constants/endpoint";
import { ICommonDataForApi } from "../Interfaces/CommonInterface";


export const currentAdmin = (): Promise<ApiResponse> => {
    return http.get(`${endpoint.admin.CURRENT}`);
};

export const getAdminUserDetails = (data?: any): Promise<ApiResponse> => {
    const queryParams: string[] = [];
    if (data && Object.keys(data).length) {
        Object.keys(data).map((key: any) => {
            queryParams.push(`${key}=${data[key]}`);
        });
    }
    return http.post(`${endpoint.admin.ADMIN_USER_DETAIL}`, data );
 
};

export const getUserList = (data?: any): Promise<ApiResponse> => {
    const queryParams: string[] = [];


    if (data && Object.keys(data).length) {
        Object.keys(data).map((key: any) => {
            queryParams.push(`${key}=${data[key]}`);

        });
    }

    return http.get(
        `${endpoint.admin.USER_LIST}${queryParams && queryParams.length > 0 ? `?${queryParams.join("&")}` : " "}`,
    );
};

export const forgetAdminPassword = (data: string): Promise<ApiResponse> => {
    return http.post(`${endpoint.admin.FORGET_PASSWORD}`, { email: data });
};

export const resetAdminPassword = (data: any): Promise<ApiResponse> => {
    return http.post(`${endpoint.admin.RESET_PASSWORD}`, data);
};

export const verifyAdminResetLink = (token: string): Promise<ApiResponse> => {
    return http.post(`${endpoint.admin.VERIFY_RESET_LINK}`, { token });
};
