import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";

import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { persistStore, persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import AuthReducer, { AdminAuthReducer } from "./authReducer";
import { Reducer } from "react";

const appReducer = combineReducers({
  AuthReducer,
  AdminAuthReducer,
});

const transforms = [
  encryptTransform({
    secretKey: "ADHKHKDHKDKHKSHKHDSKHKSDKJ",
  }),
];

const persistConfig = {
  key: "root",
  storage,
  transforms,
  stateReconciler: autoMergeLevel2,
};
const persistedReducer = persistReducer(
  persistConfig,
  appReducer as Reducer<unknown, never>
);
const store = createStore(
  persistedReducer,

  applyMiddleware(thunk, logger)
);

export default store;
persistStore(store);
export type RootState = ReturnType<typeof appReducer>;
