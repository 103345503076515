import ApiResponse from "../resources/domain/entity/IApiResponse";
import * as http from "../util/http";
import endpoint from "../constants/endpoint";
import { ILogin } from "../Interfaces/AuthInterface";

export const login = (data: ILogin): Promise<ApiResponse> => {
  return http.post(`${endpoint.auth.LOGIN}`, data);
};

export const signup = (data: any): Promise<ApiResponse> => {
  return http.post(`${endpoint.auth.SIGNUP}`, data);
};

export const currentUser = (): Promise<ApiResponse> => {
  return http.get(`${endpoint.user.CURRENT_USER}`);
};


export const adminLogin = (data: ILogin): Promise<ApiResponse> => {
  return http.post(`${endpoint.auth.ADMIN_LOGIN}`, data);
};
