import { useEffect, useState, useRef } from "react";
import * as d3 from "d3";
import {
    DEFAULT_D3CIRCLE_CONSTRAINT,
  } from "../../../constants/commonConstants";

interface ClusterPreviewProps {
   speed?:number,
   charge?:number
  }

  
const ClusterPreview : React.FC<ClusterPreviewProps>  = ({charge}) => {
  const svgRef = useRef(null);

  /**e
   * initial simulation hit
   */
  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const initialClusterRadius = 5; // Radius for the initial cluster
    let settingSimulation: any = null;
    const initialBubbles = [
      {
        id: 1,
        radius: 5,
        x: 150 - initialClusterRadius,
        y: 70,
      },
      {
        id: 2,
        radius: 5,
        x: 150 - initialClusterRadius / 2,
        y: 70,
      },
      {
        id: 3,
        radius: 5,
        x: 150,
        y: 70,
      },
      {
        id: 4,
        radius: 5,
        x: 150 + initialClusterRadius / 2,
        y: 70,
      },
      {
        id: 5,
        radius: 5,
        x: 150 + initialClusterRadius,
        y: 70,
      },
      {
        id: 6,
        radius: 5,
        x: 150 + initialClusterRadius * 2,
        y: 70,
      },
      {
        id: 7,
        radius: 5,
        x: 150,
        y: 70 + initialClusterRadius * 2,
      },
      {
        id: 8,
        radius: 5,
        x: 150 + initialClusterRadius * 1.5,
        y: 70,
      },
      {
        id: 9,
        radius: 5,
        x: 150,
        y: 70 + initialClusterRadius * 1.5,
      },
      {
        id: 10,
        radius: 5,
        x: 150 + initialClusterRadius * 2,
        y: 70 + initialClusterRadius * 2,
      },
    ];

    settingSimulation = d3
      .forceSimulation()
      .force("collision", d3.forceCollide().radius(5).strength(1))
      .force(
        "charge",
        d3.forceManyBody().strength(charge ? charge : DEFAULT_D3CIRCLE_CONSTRAINT.charge)
      )
      .force("gravity", d3.forceManyBody().strength(0.2))
      .force("x", d3.forceX(100).strength(0.14))
      .force("y", d3.forceY(70).strength(0.14))
      .on("tick", () => {
        svg
          .selectAll("circle")
          .transition()
          .duration(DEFAULT_D3CIRCLE_CONSTRAINT.speed)
          .attr("cx", (d: any) => d.x)
          .attr("cy", (d: any) => d.y);
      });

    settingSimulation.nodes(initialBubbles);

    svg
      .selectAll("circle")
      .data(initialBubbles)
      .enter()
      .append("circle")
      .attr("id", (d) => d.id)
      .attr("r", (d) => d.radius)
      .attr("fill", "steelblue")
      .attr("initial", true)
      .attr("cx", (d) => d.x)
      .attr("cy", (d) => d.y);

    return () => {
      settingSimulation.stop();
    };
  }, [charge]);

  return (
    <>
      <svg ref={svgRef} width={200} height={200} />
    </>
  );
};

export default ClusterPreview;
