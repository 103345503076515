import React from "react";

import {
  Controller,
  Control,
  FieldValues,
  FieldErrors,
  FieldError,
} from "react-hook-form";


interface IProps {
  className: string;
  placeHolder?: string;
  name: string;
  value?: string | number;
  control: Control;
  error: FieldErrors<FieldValues> | FieldError;
  disabled?: boolean;
  readOnly?: boolean;
  rows?: number;
  cols?: number;
  id?: string;
}

const CommonTextArea: React.FC<IProps> = (props) => {
  const {
    className,
    placeHolder,
    name,
    control,
    error,
    value,
    disabled = false,
    readOnly = false,
    rows = 4,
    cols,
    id,
  } = props;

  return (
    <div className="form-group">
      <Controller
        render={({ field }) => (
          <textarea
            {...field}
            id={id}
            className={className}
            placeholder={placeHolder}
            rows={rows}
            cols={cols}
            disabled={disabled}
            readOnly={readOnly}
          />
        )}
        defaultValue={value}
        name={name}
        control={control}
      />

      {error && error.message ? (
        <p className="auth-msg danger">{error?.message as string}</p>
      ) : null}
    </div>
  );
};

export default React.memo(CommonTextArea);
