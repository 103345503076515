import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import CommonButton from "../utilities/formElements/commonButton/CommonButton";
import CommonSelect from "../utilities/formElements/commonSelect/CommonSelect";
import InputWrapper from "../utilities/formElements/commonInput/InputWrapper";
import CommonTestArea from "../utilities/formElements/commonTextArea/CommonTestArea";
import CommonCheckBox from "../utilities/formElements/commonInput/CommonCheckBox";
import {
  addNewAdminValidationSchema,
  changePasswordValidationSchema,
  confirmDeleteAccountValidationSchema,
  deleteAccountValidationSchema,
} from "../utilities/validationSchema";
import {
  toastMessageError,
  toastMessageSuccess,
} from "../utilities/commonToastMessage";

import { disconnectSocket } from "../../util/socket";
import { emptyLocalStorageOnLogout } from "../../util/helper";

import {
  adminRole,
  adminStatus,
  reasonForClosingAccount,
  typePassword,
  typeText,
} from "../../constants/commonConstants";
import ROUTES from "../../constants/routes";
import ActionType from "../../resources/enums";

import { INewAdmin } from "../../Interfaces/AdminInterface";
import {
  CommonModalProps,
  IData,
  IPassword,
} from "../../Interfaces/CommonModalInterface";

import {
  deleteAdminUserDetail,
  deleteUserDetail,
  editAdminUserDetail,
  saveAdminUserDetails,
} from "../../services/superAdmin";
import { changePassword, deleteAccount } from "../../services/user";
import { subscription } from "../../services/susbscription";

import InfoModel from "./InfoModel";

import EyeIcon from "../../assets/images/eye-outline.svg";
import EyeCloseIcon from "../../assets/images/hidden-password.svg";
import storage from "../../util/storage";

const CommonModal: React.FC<CommonModalProps> = ({
  className = "",
  title,
  onClose,
  children,
  name,
  id,
  adminData,
}) => {
  const { t: translation } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState({
    changePasswordLoading: false,
    deleteAccountLoading: false,
    deleteAdmin: false,
  });
  const [deleteFeedback, setDeleteFeedback] = useState<IData>();
  const [enableNextForm, setEnableNextForm] = useState({
    firstPage: true,
    secondPage: false,
  });
  const [viewPasswordAndConfirmPassword, setViewPasswordAndConfirmPasword] =
    useState({ viewPassword: false, viewConfirmPassword: false });

  const [addAdminLoading, setAddAdminLoading] = useState<boolean>(false);

  const [successModal, setSuccessModal] = useState<boolean>(false);

  const authReducer = useSelector((state: any) => state?.AuthReducer);

  const handleLogout = () => {
    dispatch({
      type: ActionType.LOGOUT,
      payload: "",
    });
    disconnectSocket();
    emptyLocalStorageOnLogout();
  };

  const {
    handleSubmit: handleSubmitForChangePassword,
    formState: { errors: errorsForChangePassword },
    control: controlForChangePasswordForm,
  } = useForm({
    resolver: yupResolver(changePasswordValidationSchema(translation)),
  });

  const {
    handleSubmit: handleSubmitForDeleteAccount,
    formState: { errors: errorsForDeleteForm },
    control: controlForDeleteForm,
    setValue: setValueForDeleteForm,
  } = useForm({
    resolver: yupResolver(deleteAccountValidationSchema(translation)),
  });

  const {
    handleSubmit: handleSubmitForConfirmDeleteAccount,
    formState: { errors: errorsForConfirmDeleteForm },
    control: controlForConfirmDeleteForm,
  } = useForm({
    resolver: yupResolver(confirmDeleteAccountValidationSchema(translation)),
  });

  const {
    handleSubmit: handleSubmitForAddNewAdmin,
    formState: { errors: errorsForAddNewAdminForm },
    control: controlForAddNewAdminForm,
  } = useForm({
    resolver: yupResolver(addNewAdminValidationSchema(translation)),
  });

  /**
   *
   * change Password
   */
  const formDataSubmitForChangePassword = async (data: IPassword) => {
    setLoading({
      ...loading,
      changePasswordLoading: true,
    });

    const result = await changePassword(data);
    if (result && result.data) {
      toastMessageSuccess("Password Changed Successfully", "passwordChanged");
      onClose;
      handleLogout();
    } else {
      if (
        result &&
        result.error &&
        result.error.message === "Your old password not matched"
      ) {
        toastMessageError(
          "Your old password not matched",
          "oldPasswordIncorrect"
        );
      }
    }
    setLoading({
      ...loading,
    });
  };

  const formDataSubmitForDeleteAccount = (data: IData) => {
    setDeleteFeedback(data);
    setEnableNextForm({
      firstPage: false,
      secondPage: true,
    });
  };

  /**
   *
   * delete account
   */
  const formDataSubmitForConfirmDeleteAccount = async () => {
    setLoading({
      ...loading,
      deleteAccountLoading: true,
    });
    const result = await deleteAccount(deleteFeedback);
    if (result && result.data) {
      toastMessageSuccess("User deleted successfully", "userDeleted");
      handleLogout();
    }
    setLoading({
      ...loading,
    });
  };
  useEffect(() => {
    setValueForDeleteForm("termsAccept", false);
  }, []);
  const handleCloseModal = () => {
// Call the closeModal function received from the parent
    handleDeleteAdmin();
    onClose();
  };
  const formDataSubmitForAddNewAdmin = async (data: INewAdmin) => {
    setAddAdminLoading(true);

    if (title == "Edit Admin Details") {
      const mergedData = { ...adminData, ...data };

      const result = await editAdminUserDetail(mergedData);
      if (result && result.data) {
        toastMessageSuccess("Admin updated successfully", "adminUpdated");
      } else {
        toastMessageError(
          "Something went wrong. Please try again!",
          "adminUpdatedFailed"
        );
      }
      setAddAdminLoading(false);
      onClickClodeModel();
    } else if (title == "Add New Admin") {
      const result = await saveAdminUserDetails(data);
      if (result && result.data) {
        toastMessageSuccess("Admin added successfully", "adminAdded");
      } else {
        toastMessageError(
          "Add admin failed. Please try again!",
          "addAdminFailed"
        );
      }
      setAddAdminLoading(false);
      onClickClodeModel();
    }
  };

  const onClickClodeModel = () => {
    onClose();
  };
  const handleDeleteAdmin = async () => {
    setLoading({
      ...loading,
      deleteAdmin: true,
    });
    if (window.location.pathname === ROUTES.ADMIN) {
      const result = await deleteAdminUserDetail(id);
      if (!result.error) {
        toastMessageSuccess(result.data?.successMessage);
      } else {
        toastMessageError(translation("something_went_wrong"));
      }
      onClickClodeModel();
    } else if (window.location.pathname === ROUTES.USERS) {
      const result = await deleteUserDetail(id);
      if (!result.error) {
        toastMessageSuccess(result.data?.successMessage);
      } else {
        toastMessageError(translation("something_went_wrong"));
      }
      setLoading({
        ...loading,
      });
      onClickClodeModel();
    }
  };

  const getAccountRecord = async () => {
    const data = await subscription({
      jsonprc: "2.0",
      method: "getAccountRecord",
      params: {
        userId: authReducer.authData._id,
      },
      id: 0,
    });
    dispatch({
      type: ActionType.SUBSCRIPTIONDATA,
      payload: data.data?.result,
    });
    storage.set("urlsToTrack", data.data?.result.subscriptionData.urlsToTrack);
    storage.set(
      "mainViewSlots",
      data.data?.result?.subscriptionData?.mainViewSlots
    );
  };

  return (
    <>
      <div className={`modal show-modal common-modal ${className}`}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{title}</h4>
              <CommonButton
                type="button"
                className="btnC btn-close btn-close-white"
                onClick={onClose}
                data-bs-dismiss="modal"
              />
            </div>

            <div className="modal-body common-body text-primary">
              {children}

              {name === "ChangePassword" && (
                <>
                  <form
                    onSubmit={handleSubmitForChangePassword((data) =>
                      formDataSubmitForChangePassword(data as IPassword)
                    )}
                  >
                    <div className="form-group">
                      <InputWrapper
                        label={translation("old_password")}
                        control={controlForChangePasswordForm}
                        name="oldPassword"
                        className="form-control"
                        placeholder={translation("old_password")}
                        error={errorsForChangePassword?.oldPassword}
                        type="password"
                      />
                    </div>

                    <div className="form-group">
                      <label>{translation("new_password")}</label>
                      <div className="input-icon">
                        <InputWrapper
                          control={controlForChangePasswordForm}
                          name="newPassword"
                          className="form-control"
                          placeholder={translation("new_password")}
                          error={errorsForChangePassword?.newPassword}
                          type={
                            viewPasswordAndConfirmPassword.viewPassword
                              ? typeText
                              : typePassword
                          }
                        />
                        <CommonButton
                          type="button"
                          onClick={() =>
                            setViewPasswordAndConfirmPasword((prevState) => ({
                              ...prevState,
                              viewPassword:
                                !viewPasswordAndConfirmPassword.viewPassword,
                            }))
                          }
                          iconSrc={
                            viewPasswordAndConfirmPassword.viewPassword
                              ? EyeIcon
                              : EyeCloseIcon
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label>{translation("confirm_password")}</label>
                      <div className="input-icon">
                        <InputWrapper
                          control={controlForChangePasswordForm}
                          name="confirmPassword"
                          className="form-control"
                          placeholder={translation("confirm_new_password")}
                          error={errorsForChangePassword?.confirmPassword}
                          type={
                            viewPasswordAndConfirmPassword.viewConfirmPassword
                              ? typeText
                              : typePassword
                          }
                        />

                        <CommonButton
                          type="button"
                          onClick={() =>
                            setViewPasswordAndConfirmPasword((prevState) => ({
                              ...prevState,
                              viewConfirmPassword:
                                !viewPasswordAndConfirmPassword.viewConfirmPassword,
                            }))
                          }
                          iconSrc={
                            viewPasswordAndConfirmPassword.viewConfirmPassword
                              ? EyeIcon
                              : EyeCloseIcon
                          }
                        />
                      </div>
                    </div>

                    <div className="text-center mt-4 mb-3">
                      <CommonButton
                        type="submit"
                        label={translation("change_password")}
                        className="theme-btn success-btn text-uppercase fontBold"
                        loading={loading.changePasswordLoading}
                        disabled={loading.changePasswordLoading}
                      />
                    </div>
                  </form>
                </>
              )}

              {name === "DeleteAccount" && enableNextForm.firstPage && (
                <div className="delete-account-modal">
                  <div className="error-box-delete">
                    <h3>
                      You are about to permanently delete your account and all
                      data associated with it.
                    </h3>
                  </div>

                  <form
                    onSubmit={handleSubmitForDeleteAccount((data) =>
                      formDataSubmitForDeleteAccount(data as IData)
                    )}
                  >
                    <div className="modal-warning-brief">
                      <p>
                        ✓ If you have a paid plan, you will not receive a refund
                        for any outstanding payment amount from days remaining
                        on your subscription.
                      </p>
                      <p>
                        ✓ This action cannot be reversed and is immediate (you
                        will not be able to sign back in).{" "}
                      </p>
                      <p>
                        ✓ You can create a new Skydive account with this email
                        address once deleted.
                      </p>
                    </div>
                    <div className="check-content">
                      <CommonCheckBox
                        control={controlForDeleteForm}
                        name="termsAccept"
                        type="checkbox"
                        className="form-check-input"
                        labelText="I agree to the above conditions for deleting this account."
                        id="check1"
                        error={errorsForDeleteForm?.termsAccept}
                      />
                    </div>

                    <p className="error-para-show">
                      We really don't want to see you go, but if this truly it,
                      can you answer just two questions for us?
                    </p>

                    <div className="form-group">
                      <label>{translation("why_closing_your_account")}</label>
                      <CommonSelect
                        option={reasonForClosingAccount}
                        name="selectedReason"
                        className="form-select form-control"
                        control={controlForDeleteForm}
                        errors={errorsForDeleteForm?.selectedReason}
                      />
                    </div>

                    <div className="form-group">
                      <label>{translation("your_feedback_matters")}</label>
                      <label>
                        {translation("anything_else_you_like_us_to_know")}
                      </label>
                      <CommonTestArea
                        control={controlForDeleteForm}
                        name="comment"
                        className="form-control"
                        error={errorsForDeleteForm?.comment}
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      <CommonButton
                        type="submit"
                        label={translation("proceed")}
                        className="theme-btn theme-danger-btn text-uppercase fontBold me-3"
                      />
                      <CommonButton
                        type="button"
                        label={translation("cancel_and_keep_skydiving")}
                        className="theme-btn white-btn text-uppercase fontBold"
                        onClick={onClose}
                      />
                    </div>
                  </form>
                </div>
              )}

              {enableNextForm.secondPage && (
                <div className="delete-account-modal">
                  <div className="error-box-delete">
                    <h3>
                      You are about to permanently delete your account and all
                      data associated with it.
                    </h3>
                  </div>
                  <form
                    onSubmit={handleSubmitForConfirmDeleteAccount(
                      formDataSubmitForConfirmDeleteAccount
                    )}
                  >
                    <div className="form-group">
                      <label>{translation("type_delete")}</label>
                      <InputWrapper
                        control={controlForConfirmDeleteForm}
                        name="typeDelete"
                        className="form-control"
                        error={errorsForConfirmDeleteForm?.typeDelete}
                        type="text"
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      <CommonButton
                        type="submit"
                        label={translation("delete_account")}
                        className="theme-btn theme-danger-btn text-uppercase fontBold me-3"
                        loading={loading.deleteAccountLoading}
                        disabled={loading.deleteAccountLoading}
                      />
                      <CommonButton
                        type="button"
                        label={translation("cancel_and_keep_skydiving")}
                        className="theme-btn white-btn text-uppercase fontBold"
                        onClick={onClose}
                      />
                    </div>
                  </form>
                </div>
              )}

              {name === "AddAdmin" && (
                <>
                  <form
                    onSubmit={handleSubmitForAddNewAdmin((data) =>
                      formDataSubmitForAddNewAdmin(data as INewAdmin)
                    )}
                  >
                    <div className="form-group">
                      <InputWrapper
                        label={translation("first_name")}
                        control={controlForAddNewAdminForm}
                        name="firstname"
                        className="form-control"
                        placeholder={translation("first_name")}
                        error={errorsForAddNewAdminForm?.firstname}
                        type="text"
                        value={
                          title == "Edit Admin Details"
                            ? adminData?.firstname
                            : null
                        }
                      />
                    </div>
                    <div className="form-group">
                      <InputWrapper
                        label={translation("last_name")}
                        control={controlForAddNewAdminForm}
                        name="lastname"
                        className="form-control"
                        placeholder={translation("last_name")}
                        error={errorsForAddNewAdminForm?.lastName}
                        type="text"
                        value={
                          title == "Edit Admin Details"
                            ? adminData?.lastname
                            : null
                        }
                      />
                    </div>
                    <div className="form-group">
                      <InputWrapper
                        label={translation("email_label")}
                        control={controlForAddNewAdminForm}
                        name="email"
                        className="form-control"
                        placeholder={translation("email_label")}
                        error={errorsForAddNewAdminForm?.email}
                        type="email"
                        readOnly={title == "Edit Admin Details" ? true : false}
                        value={
                          title == "Edit Admin Details"
                            ? adminData?.email
                            : null
                        }
                      />
                    </div>

                    <div className="form-group">
                      <label>{translation("pass")}</label>
                      <div className="input-icon">
                        <InputWrapper
                          control={controlForAddNewAdminForm}
                          name="password"
                          className="form-control"
                          placeholder={translation("pass")}
                          error={errorsForAddNewAdminForm?.password}
                          type={
                            viewPasswordAndConfirmPassword.viewPassword
                              ? typeText
                              : typePassword
                          }
                        />
                        <CommonButton
                          type="button"
                          onClick={() =>
                            setViewPasswordAndConfirmPasword((prevState) => ({
                              ...prevState,
                              viewPassword:
                                !viewPasswordAndConfirmPassword.viewPassword,
                            }))
                          }
                          iconSrc={
                            viewPasswordAndConfirmPassword.viewPassword
                              ? EyeIcon
                              : EyeCloseIcon
                          }
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>{translation("admin_status")}</label>
                          <CommonSelect
                            option={adminStatus}
                            defaultValue={
                              title === "Edit Admin Details"
                                ? adminData?.status
                                : adminStatus[0].value
                            }
                            name="status"
                            className="form-select form-control"
                            control={controlForAddNewAdminForm}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>{translation("user_role")}</label>
                          <CommonSelect
                            option={adminRole}
                            defaultValue={
                              title == "Edit Admin Details"
                                ? adminData?.user_role
                                : adminRole[0].value
                            }
                            name="user_role"
                            className="form-select form-control"
                            control={controlForAddNewAdminForm}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="inline-btn justify-content-center">
                      <CommonButton
                        type="submit"
                        label={
                          title === "Edit Admin Details"
                            ? translation("update")
                            : translation("add_admin")
                        }
                        className="theme-btn success-btn text-uppercase fontBold"
                        loading={addAdminLoading}
                        disabled={addAdminLoading}
                      />

                      <CommonButton
                        type="button"
                        label={translation("cancel")}
                        className="theme-btn white-btn text-uppercase fontBold"
                        onClick={onClose}
                      />
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {successModal && (
        <InfoModel
          onClose={() => {
            getAccountRecord();
            setSuccessModal(false);
          }}
          name="downgradeModel"
          title="Account Changes Complete"
          header={true}
          hasImage={true}
        />
      )}
    </>
  );
};

export default CommonModal;
