import {
  addOrRemovePageBubble,
  clusterCenterCoords,
  fillClusterEmpty,
  fillClusterSelected,
  getPagesList,
  pageViewDisabled,
  selectedDateRange,
  selectedEndDateRange,
} from "./clusterService";
import { session } from "../services/session";
import { site } from "../services/site";
import * as d3 from "d3";
import { DEFAULT_VALUE } from "../constants/commonConstants";
import storage from "./storage";

import { eventEmitter } from "../eventEmitters/EventEmitters";

export let data: any;
const UNKNOWN_URL_CLUSER_INDEX = 10;
export interface PageEntity {
  name: string;
  selected: boolean;
  restricted: boolean;
  firstVisitDate?: Date;
  slotIndex?: number;
}
export const maxRadius = 32;
export interface PageUiEntity extends PageEntity {
  disabled?: boolean;
  currentVisitors?: number;
  overallVisitors?: number;
}
let missingPaths = [];
export let clusterIndexToSet: number;

export const setPages = async (recods: { siteId: string; pages: any[] }) => {
  const pages = recods.pages;
  data = pages?.filter((page) => !page.restricted);
  missingPaths = pages.filter((page) => page.restricted);

  setHomeUrlDisabled(data);
  data
    .filter((page) => page.selected)
    .forEach((page) => fillClusterSelected(page.slotIndex, page.name));
  await getPagesStatistics();
  return data;

};
export const d3PagesDataRecord = () => {
  return data;
};
const setHomeUrlDisabled = (data) => {
  const homePath = data.find((page) => page.name === "/");

  if (homePath) {
    homePath.disabled = true;
  }
};

export const getPagesStatistics = async () => {
  const pages = await session({
    jsonprc: "2.0",
    method: "getPageVisitsCount",
    params: {

      endDate: selectedEndDateRange
      ? selectedEndDateRange
      : new Date().toISOString(),
      siteId: storage.get("siteId"),

      startDate:  selectedDateRange && selectedDateRange.value
      ? new Date(selectedDateRange.value).toISOString()
      : new Date(new Date().setHours(0,0,0,0)).toISOString(),
    },
    id: 0,
  });
  return data.forEach((path) => {
    const pathInfo = pages?.data?.result?.find((page) => page._id === path.name);

    path.overallVisitors = pathInfo ? pathInfo?.overallVisitors || 0 : 0;
  });
};

export const selectPage = (record: { pageName: string; siteId: string }) => {
  const { pageName, siteId } = record;
  const selectedPages = data.filter((page) => page.selected);
  if (!isAllowedToSelectPage()) {
    // throw new Error(this.ERROR_MESSAGE.MAX_MAIN_PAGE_VIEW_ERROR);
    return {error:"You have reached the maximum page amount to display for this view according to your subscription"};
  }
  if (selectedPages.length >= DEFAULT_VALUE.UNKNOWN_URL_CLUSER_INDEX) {
    return {error:"You have reached the maximum page amount to display for this view according to your subscription"};

  }
  const indexToSet =
   clusterIndexToSet
      ? clusterIndexToSet
      :
    findEmptyIndex(data);
  const selectedPage = data.find((page) => page.name === pageName);


  selectedPage.selected = true;
  selectedPage.slotIndex = indexToSet;

  fillClusterSelected(indexToSet, selectedPage.name);
  clusterIndexToSet = null;

   setPageSelected({ pageName, indexToSet, siteId });
  addOrRemovePageBubble("addPage",pageName );
};

export const clearSelectedPages = ()=> {
  return new Promise(async (resolve) => {
const filterData = data.filter(page => page.selected && !page.disabled);
data.filter(page => page.selected && !page.disabled);
if(filterData.length ){
  for(let i=0; i< filterData.length; i++){
    await unselectPage({pageName:filterData[i].name,siteId:storage.get("siteId")});
    if(i === (filterData.length-1) ){
      resolve("ok");
    }
  }
  eventEmitter.emit("d3componentUpdate");
} else{
  resolve("ok");
}
  });
 
};
const setPageSelected = async (record: {
  pageName: string;
  indexToSet: number;
  siteId: string;
}) => {
  const param = {
    id: 0,
    jsonprc: "2.0",
    method: "selectPage",
    params: {
      pageName: record.pageName,
      siteId: record.siteId,
      slotIndex: record.indexToSet,
    },
  };
  const updateSelectedPage = await site(param);
  const uniquePages = updateSelectedPage?.data?.result.pages.filter((obj, index) => {
    return index === updateSelectedPage?.data?.result.pages.findIndex(o => obj.name === o.name);
});
  storage.set("pages",JSON.stringify(uniquePages));
  
  getPagesList();
  eventEmitter.emit("d3componentUpdate");
  return updateSelectedPage?.data?.result.pages;


};

const findEmptyIndex = (pages: PageUiEntity[]) => {
  const clusterIndexes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  const takenClusterIndexes = pages
    .filter((page) => page.selected)
    .map((page) => page.slotIndex);

  return clusterIndexes.find((index) => !takenClusterIndexes.includes(index));
};

export const unselectPage = async(record: { pageName: string; siteId: string }) => {
  return new Promise(async (resolve) => {

    
      const page = data.find((page) => page.name === record.pageName);
      fillClusterEmpty(page?.slotIndex);
    
      page.selected = false;
      page.slotIndex = null;
    await setPageUnselected({ pageName: record.pageName, siteId: record.siteId });
   await addOrRemovePageBubble("removePage",record.pageName);
      resolve("ok");
  });
};
const setPageUnselected = async (record: {
  pageName: string;
  siteId: string;
}) => {
  return new Promise(async(resolve, reject) => {

    const param = {
      id: 0,
      jsonprc: "2.0",
      method: "unselectPage",
      params: {
        pageName: record.pageName,
        siteId: record.siteId,
      },
    };
    const updateSelectedPage = await site(param);
    const uniquePages = updateSelectedPage?.data?.result.pages.filter((obj, index) => {
      return index === updateSelectedPage?.data?.result.pages.findIndex(o => obj.name === o.name);
    });
     storage.set("pages",JSON.stringify(uniquePages));
     getPagesList();
    resolve(updateSelectedPage?.data?.result.pages);
  });

};


export const getClusterNumber = (path) => {
  const nodePage = data.find((page) => page.name === path && page.selected);

  return nodePage ? nodePage.slotIndex : UNKNOWN_URL_CLUSER_INDEX;
};

export const createCollidesHandler = (bubblesData1) => {
  const bubblesData = JSON.parse(JSON.stringify(bubblesData1));
  const clusterPadding = 0;
  const alpha = 0.5;
  const padding = 1;

  const quadtree = d3
    .quadtree()
    .x((d: any) => d.vx) // Replace 'x' with the key that stores the x-coordinate in your data objects
    .y((d: any) => d.vy) // Replace 'y' with the key that stores the y-coordinate in your data objects
    .addAll(bubblesData);

  return  (d) => {
    const r = d.radius + maxRadius + Math.max(padding, maxRadius);
    const nx1 = d.x - r;
    const nx2 = d.x + r;
    const ny1 = d.y - r;
    const ny2 = d.y + r;
    quadtree.visit((quad: any, x1, y1, x2, y2) => {

      if (quad.data && quad.data !== d) {
        let x = d.x - quad.data.x;
        let y = d.y - quad.data.y;

        let l = Math.sqrt(x * x + y * y);
        const r =
          d.radius +
          quad.data.radius +
          (d.cluster === quad.data.cluster ? padding : clusterPadding);


        if (l < r) {
          l = ((l - r) / l) * alpha;

          d.x -= x *= l;
          d.y -= y *= l;
          quad.data.x += x;
          quad.data.y += y;

        }
      }


      return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
    });
  };
};

export const createGravityHandler = (alpha) => {
 
  return (d1) => {
    const d = JSON.parse(JSON.stringify(d1));
    const nodeClusterName = d.currentUrlName;

    const centerIndex = DEFAULT_VALUE.DEFAULT_CLUSTER_CENTER[nodeClusterName]
      ? DEFAULT_VALUE.DEFAULT_CLUSTER_CENTER[nodeClusterName].centerIndex
      : pageViewDisabled
      ? DEFAULT_VALUE.ALL_CLUSTER_INDEX
      : getClusterNumber(d.currentUrlName);

    d.x += (clusterCenterCoords[centerIndex].exteraX - d.x) * (alpha + 0.5);
    d.y += (clusterCenterCoords[centerIndex].exteraY - d.y) * (alpha + 0.5);
  };
};
export const handleVisitorMovePagesModel = async(previousPageName, newPageName) => {
  const pagesVisitorData = await session({
    jsonprc: "2.0",
    method: "getPageVisitsCount",
    params: {

      endDate: selectedEndDateRange
      ? selectedEndDateRange
      : new Date().toISOString(),
      siteId: storage.get("siteId"),

      startDate:  selectedDateRange && selectedDateRange.value
      ? new Date(selectedDateRange.value).toISOString()
      : new Date(new Date().setHours(0,0,0,0)).toISOString(),
    },
    id: 0,
  });

  const newPage = data.find(
    (page) => !page.restricted && page.name === newPageName
  );

  const previousPage = data.find(
    (page) => !page.restricted && page.name === previousPageName
  );

  if (previousPage) {
    previousPage.currentVisitors--;
  }

  if (newPage) {
   const newPageOverAllVisitor= pagesVisitorData.data?.result.filter(
      (page) =>  page._id === newPageName
    );
    
    newPage.overallVisitors =newPageOverAllVisitor[0]?.overallVisitors ?newPageOverAllVisitor[0]?.overallVisitors : 0;
    // newPage.overallVisitors++;
    newPage.currentVisitors = Number(newPage.currentVisitors)
    ? newPage.currentVisitors + 1
    : 1;
    
    return;
  }
  if (newPageName !== null) {
    const urlsToTrack =Number(storage.get("urlsToTrack"));

  const limitNotReached = urlsToTrack > 0 && data.length < urlsToTrack;
  if (limitNotReached || urlsToTrack === -1) {

    return data.push({
      name: newPageName,
      selected: false,
      restricted: false,
      currentVisitors: 1,
      overallVisitors: 1,
    });
  }


    const nonTrackedPath = missingPaths.find(
      (path) => path.name === newPageName
    );

    if (!nonTrackedPath) {
      missingPaths.push({
        name: newPageName,
      });
    }
  }

};
export const isAllowedToSelectPage=() =>{
  const totalMainPageViews = storage.get("mainViewSlots");

  const selectedPages =data.filter(page => page.selected);
  return selectedPages.length < totalMainPageViews;
};

export const setClusterIndexToSet =(data)=>{
  clusterIndexToSet = data;
};
