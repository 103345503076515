import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import InputWrapper from "../utilities/formElements/commonInput/InputWrapper";
import CommonButton from "../utilities/formElements/commonButton/CommonButton";
import { changePasswordValidationSchema } from "../utilities/validationSchema";
import {
  toastMessageError,
  toastMessageSuccess,
} from "../utilities/commonToastMessage";

import { changePassword } from "../../services/user";
import { disconnectSocket } from "../../util/socket";
import { emptyLocalStorageOnLogout } from "../../util/helper";

import {
  CommonModalProps,
  IPassword,
} from "../../Interfaces/CommonModalInterface";
import ActionType from "../../resources/enums";
import { typePassword, typeText } from "../../constants/commonConstants";

import EyeIcon from "../../assets/images/eye-outline.svg";
import EyeCloseIcon from "../../assets/images/hidden-password.svg";

const ChangePassword: React.FC<CommonModalProps> = ({
  title,
  onClose,
  closeModel,
}) => {
  const { t: translation } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [viewPasswordAndConfirmPassword, setViewPasswordAndConfirmPasword] =
    useState({ viewPassword: false, viewConfirmPassword: false });

  const {
    handleSubmit: handleSubmitForChangePassword,
    formState: { errors: errorsForChangePassword },
    control: controlForChangePasswordForm,
  } = useForm({
    resolver: yupResolver(changePasswordValidationSchema(translation)),
  });

  const formDataSubmitForChangePassword = async (data: IPassword) => {
    setLoading(true);

    const result = await changePassword(data);
    if (result && result.data) {
      toastMessageSuccess(
        translation("pass_changes_successfully"),
        "passwordChanged"
      );
      onClose;
      handleLogout();
    } else {
      if (
        result &&
        result.error &&
        result.error.message === "Your old password not matched"
      ) {
        toastMessageError(result?.error?.message, "oldPasswordIncorrect");
      }
    }
    setLoading(false);
  };

  /**
   *
   * logout handler
   */
  const handleLogout = () => {
    dispatch({
      type: ActionType.LOGOUT,
      payload: "",
    });
    disconnectSocket();
    emptyLocalStorageOnLogout();
  };

  return (
    <div className="modal show-modal common-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
            <CommonButton
              type="button"
              className="btnC btn-close btn-close-white"
              onClick={onClose}
              data-bs-dismiss="modal"
            />{" "}
          </div>

          <div className="modal-body common-body text-primary">
            <form
              onSubmit={handleSubmitForChangePassword((data) =>
                formDataSubmitForChangePassword(data as IPassword)
              )}
            >
              <div className="form-group">
                <InputWrapper
                  label={translation("old_password")}
                  control={controlForChangePasswordForm}
                  name="oldPassword"
                  className="form-control"
                  placeholder={translation("old_password")}
                  error={errorsForChangePassword?.oldPassword}
                  type="password"
                />
              </div>

              <div className="form-group">
                <label>{translation("new_password")}</label>
                <div className="input-icon">
                  <InputWrapper
                    control={controlForChangePasswordForm}
                    name="newPassword"
                    className="form-control"
                    placeholder={translation("new_password")}
                    error={errorsForChangePassword?.newPassword}
                    type={
                      viewPasswordAndConfirmPassword.viewPassword
                        ? typeText
                        : typePassword
                    }
                  />
                  <CommonButton
                    type="button"
                    onClick={() =>
                      setViewPasswordAndConfirmPasword((prevState) => ({
                        ...prevState,
                        viewPassword:
                          !viewPasswordAndConfirmPassword.viewPassword,
                      }))
                    }
                    iconSrc={
                      viewPasswordAndConfirmPassword.viewPassword
                        ? EyeIcon
                        : EyeCloseIcon
                    }
                  />
                </div>
              </div>

              <div className="form-group">
                <label>{translation("confirm_password")}</label>
                <div className="input-icon">
                  <InputWrapper
                    control={controlForChangePasswordForm}
                    name="confirmPassword"
                    className="form-control"
                    placeholder={translation("confirm_new_password")}
                    error={errorsForChangePassword?.confirmPassword}
                    type={
                      viewPasswordAndConfirmPassword.viewConfirmPassword
                        ? typeText
                        : typePassword
                    }
                  />

                  <CommonButton
                    type="button"
                    onClick={() =>
                      setViewPasswordAndConfirmPasword((prevState) => ({
                        ...prevState,
                        viewConfirmPassword:
                          !viewPasswordAndConfirmPassword.viewConfirmPassword,
                      }))
                    }
                    iconSrc={
                      viewPasswordAndConfirmPassword.viewConfirmPassword
                        ? EyeIcon
                        : EyeCloseIcon
                    }
                  />
                </div>
              </div>

              <div className="text-center mt-4 mb-3">
                <CommonButton
                  type="submit"
                  className="theme-btn success-btn text-uppercase fontBold"
                  label={translation("change_password")}
                  loading={loading}
                  disabled={loading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChangePassword;
