import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import PrivateRoutes from "./PrivateRoute";

import ROUTES from "../constants/routes";
import Login from "../components/views/userAuth/Login";
import Signup from "../components/views/userAuth/signup/Signup";
import Sidebar from "../components/sidebar/Sidebar";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import ResetPassword from "../components/views/userAuth/ResetPassword";
import ConversionSetup from "../components/views/conversionSetup/ConversionSetup";
import AdminLogin from "../components/adminPanel/adminAuth/AdminLogin";
import AdminHeader from "../components/adminPanel/adminHeader/AdminHeader";
import AdminSidebar from "../components/adminPanel/adminSidebar/Sidebar";
import Settings from "../components/views/settings/Settings";

import { RootState } from "../state_management/reducers";

const AnalyticsView = React.lazy(
  () => import("../components/views/analyticsView/AnalyticsView")
);

const Users = React.lazy(
  () => import("../components/adminPanel/users/UsersList")
);
const Admin = React.lazy(
  () => import("../components/adminPanel/admin/AdminList")
);
const DeletedUsers = React.lazy(
  () => import("../components/adminPanel/deletedUsers/DeletedUsersList")
);

import "../../src/assets/theme/Styles.scss";

interface Props {
  component: React.ComponentType | any;
}

export const WithHeader: React.FC<Props> = (props) => {
  return (
    <>
      <div className="main-container">
        <div className="main-box">
          <Header />
          <div className="common-box">
            <PrivateRoutes {...props} />
          </div>
        </div>
      </div>
    </>
  );
};

export const WithoutHeaderAndSideBar: React.FC<Props> = (props) => {
  return (
    <>
      <div className="main-container">
        <div className="main-box">
          <div className="common-box w-100">
            <PrivateRoutes {...props} />
          </div>
        </div>
      </div>
    </>
  );
};
export const WithSideBarAndHeader: React.FC<Props> = (props) => {
  return (
    <>
      <Header />
      <div className="main-box">
        <Sidebar />
        <div className="dashboard-container">
          <div className="common-box">
            <PrivateRoutes {...props} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export const WithSideBarAndHeaderForAdmin: React.FC<Props> = (props) => {
  return (
    <>
      <div className="admin-main">
        <AdminSidebar />
        <div className="main-wrapper">
          <AdminHeader />
          <div className="main-wrapper-inner">
            <div className="wrapper-pages">
              <PrivateRoutes {...props} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const PublicRoutes: React.FC = () => {
  const authReducer: any = useSelector((state: RootState) => state.AuthReducer);
  const adminauthReducer: any = useSelector(
    (state: RootState) => state?.AdminAuthReducer
  );
  return (
    <>
      <Suspense fallback={<p>Loading.....</p>}>
        <Routes>
          <Route path={ROUTES.LOGIN} element={<Login />} />
          <Route path={ROUTES.SIGNUP} element={<Signup />} />
          <Route path="/" element={<Navigate to={ROUTES.SIGNUP} />} />

          <Route
            path={
              adminauthReducer && adminauthReducer.isLoggedIn
                ? ROUTES.ANALYTICS_VIEW
                : ROUTES.ANALYTICS_VIEW.replace("/:id", "")
            }
            element={
              (authReducer && authReducer.isLoggedIn) ||
              (adminauthReducer && adminauthReducer.isLoggedIn) ? (
                <WithSideBarAndHeader component={AnalyticsView} />
              ) : (
                <Navigate to={ROUTES.LOGIN} />
              )
            }
          />
          <Route path={ROUTES.EMAIL_VALIDATION} element={<Signup />} />
          <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
          <Route
            path={
              adminauthReducer && adminauthReducer.isLoggedIn
                ? ROUTES.CONVERSION_SETUP
                : ROUTES.CONVERSION_SETUP.replace("/:id", "")
            }
            element={
              (authReducer && authReducer.isLoggedIn) ||
              (adminauthReducer && adminauthReducer.isLoggedIn) ? (
                <WithoutHeaderAndSideBar component={ConversionSetup} />
              ) : (
                <Navigate to={ROUTES.LOGIN} />
              )
            }
          />

          <Route
            path={
              adminauthReducer && adminauthReducer.isLoggedIn
                ? ROUTES.SETTINGS
                : ROUTES.SETTINGS.replace("/:id", "")
            }
            element={
              authReducer && authReducer.isLoggedIn ? (
                <WithoutHeaderAndSideBar component={Settings} />
              ) : (
                <Navigate to={ROUTES.LOGIN} />
              )
            }
          />
          <Route path={ROUTES.ADMINLOGIN} element={<AdminLogin />} />

          <Route
            path={ROUTES.USERS}
            element={
              adminauthReducer && adminauthReducer.isLoggedIn ? (
                <WithSideBarAndHeaderForAdmin component={Users} />
              ) : (
                <Navigate to={ROUTES.ADMINLOGIN} />
              )
            }
          />

          <Route
            path={ROUTES.ADMIN}
            element={
              adminauthReducer && adminauthReducer.isLoggedIn ? (
                <WithSideBarAndHeaderForAdmin component={Admin} />
              ) : (
                <Navigate to={ROUTES.ADMINLOGIN} />
              )
            }
          />
          <Route
            path={ROUTES.DELETEDUSERS}
            element={
              adminauthReducer && adminauthReducer.isLoggedIn ? (
                <WithSideBarAndHeaderForAdmin component={DeletedUsers} />
              ) : (
                <Navigate to={ROUTES.ADMINLOGIN} />
              )
            }
          />
          <Route
            path={ROUTES.RESET_ADMIN_PASSWORD}
            element={<ResetPassword />}
          />
        </Routes>
      </Suspense>
    </>
  );
};

export default PublicRoutes;

