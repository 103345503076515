import React, { useState } from "react";
import { FieldErrors, FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import { forgetAdminPassword } from "../../../services/admin";

import {
  toastMessageError,
  toastMessageSuccess,
} from "../../utilities/commonToastMessage";
import { forgetPasswordFormEmailValidation } from "../../utilities/validationSchema";
import CommonButton from "../../utilities/formElements/commonButton/CommonButton";
import InputWrapper from "../../utilities/formElements/commonInput/InputWrapper";

import storage from "../../../util/storage";

interface Iprops {
  className?: string;
  name?: string;
  defaultValue?: string;
  error?: FieldErrors<FieldValues>;
  closeModel?: any;
}
const ForgetPasswordModel: React.FC<Iprops> = (props) => {
  const { t: translation } = useTranslation();
  const { closeModel } = props;

  const {
    control: controlForm,
    handleSubmit: handleSubmitForm,
    formState: { errors },
    getValues: getValuesForm,
  } = useForm({
    resolver: yupResolver(forgetPasswordFormEmailValidation(translation)),
  });

  const [loading, setLoading] = useState(false);

  /**
   *
   * calling the forget admin password api
   */
  const formDataSubmit = async (data: FieldValues) => {
    setLoading(true);
    storage.set("email", data.email);
    const sendForgetpasswordCode = await forgetAdminPassword(data.email);
    if (sendForgetpasswordCode && sendForgetpasswordCode.data) {
      toastMessageSuccess(translation("email_sent"), "emailSent");
    } else {
      toastMessageError(translation("email_not_registered"), "emailNotFound");
    }
    setLoading(false);
  };

  return (
    <div className="modal show-modal common-modal">
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{translation("forget_pass")}</h4>
          </div>

          <div className="modal-body common-body text-primary">
            <form onSubmit={handleSubmitForm((data) => formDataSubmit(data))}>
              <div className="form-group text-start">
                <label className="font14 fontBold">
                  {translation("email_label")}
                </label>

                <InputWrapper
                  control={controlForm}
                  name="email"
                  className="form-control"
                  placeholder={translation("email_placeholder")}
                  error={errors?.email}
                  type="email"
                />
              </div>
              <div className="text-center mt-4 mb-3">
                <CommonButton
                  type="submit"
                  label={translation("forget_pass")}
                  className="theme-btn success-btn text-uppercase fontBold me-3"
                  disabled={loading}
                  loading={loading}
                />
                <CommonButton
                  type="button"
                  label={translation("cancel")}
                  className="theme-btn white-btn text-uppercase fontBold"
                  onClick={closeModel}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgetPasswordModel;
