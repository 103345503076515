export default {
  auth: {
    LOGIN: "auth/login",
    SIGNUP: "auth/signup",
    ADMIN_LOGIN: "auth/admin-login",
  },
  user: {
    CURRENT_USER: "user/currentUser",
    EMAIL_AVAILABLE: "user/emailAvailable/:id",
    TWO_FACTOR_AUTHENCTICATION_CONFIRMATION: "user/twoFactorConfirmation",
    FORGET_PASSWORD: "user/forgetPassword",
    RESET_PASSWORD: "user/resetPassword",
    VERIFY_RESET_LINK: "user/verifyResetLink",
    CHANGE_PASSWORD: "user/changePassword",
    DELETE_USER: "user/deleteUserDetail",
    EDIT_USER_DETAIL: "user/editUserDetail",
    RESET_SETTINGS: "user/resetSettings",
    SEND_TWO_FACTOR_REGISTRATION: "user/sendTwoFactorRegistration",
    USER_FROM_ADMIN: "user/:id",
  },
  sites: {
    SITE: "/sites",
    SITE_AVAILABLE: "/sites/isDomainRegistered",
  },
  subscription: {
    SUBSCRIPTION: "/subscription",
    ADMIN_SUBSCRIPTION: "/admin-subscription",
  },
  Session: {
    SESSION: "/session",
  },
  admin: {
    CURRENT: "admin/current",
    ADMIN_USER_DETAIL: "admin/getAdminUserDetail",
    USER_LIST: "admin/getUserList/",
    FORGET_PASSWORD: "admin/forgetAdminPassword",
    RESET_PASSWORD: "admin/resetAdminPassword",
    VERIFY_RESET_LINK: "admin/verifyAdminResetLink",
  },
  superAdmin: {
    DELETED_USER_DETAILS: "superAdmin/getDeletedUserDetails",
    SAVE_ADMIN_USER_DETAILS: "superAdmin/saveAdminUserDetail",
    EDIT_ADMIN_USER_DETAILS: "superAdmin/editAdminUserDetail",
    DELETE_ADMIN_USER_DETAILS: "superAdmin/deleteAdminUserDetail",
    DELETE_USER: "superAdmin/deleteUserDetail",
  },
} as const;
