import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import ROUTES from "../../../constants/routes";
import { breadCrumb } from "../../../constants/commonConstants";

import ActionType from "../../../resources/enums";

import {
  capitalizeFirstLetter,
  emptyLocalStorageOnLogout,
} from "../../../util/helper";
import { disconnectSocket } from "../../../util/socket";

import userPlaceholder from "../../../assets/images/user-placeholder.jpg";
import "./AdminHeader.scss";

const AdminHeader: React.FC = () => {
  const { t: translation } = useTranslation();
  const dispatch = useDispatch();

  const authReducer = useSelector((state: any) => state?.AdminAuthReducer);

  return (
    <div className="admin-header">
      <div className="container-fluid">
        <div className="section-inner">
          <div className="row">
            <div className="col-md-7">
              <div className="header-breadcrumb">
                <ul>
                  <li>
                    <Link to={ROUTES.USERS}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28.484"
                        height="25"
                        viewBox="0 0 28.484 25"
                      >
                        <path
                          id="Path_5"
                          data-name="Path 5"
                          d="M14.374-2.237h6.8v-8.552a.89.89,0,0,0-.245-.659.892.892,0,0,0-.66-.245H15.291a.913.913,0,0,0-.666.245.878.878,0,0,0-.251.659ZM9.76-.856H25.715a2.769,2.769,0,0,0,2.027-.711,2.664,2.664,0,0,0,.724-1.992V-16.6l-2.116-1.439V-4.153a1.188,1.188,0,0,1-.3.868,1.137,1.137,0,0,1-.85.3H10.271a1.137,1.137,0,0,1-.85-.3,1.188,1.188,0,0,1-.3-.868V-18.042L7.009-16.6V-3.559a2.664,2.664,0,0,0,.724,1.992A2.769,2.769,0,0,0,9.76-.856ZM3.53-14.082a1.026,1.026,0,0,0,.3.729,1.082,1.082,0,0,0,.822.317,1,1,0,0,0,.489-.124,2.487,2.487,0,0,0,.419-.288l11.765-9.866a.661.661,0,0,1,.457-.194.705.705,0,0,1,.469.194l11.741,9.866a2.936,2.936,0,0,0,.43.288,1,1,0,0,0,.49.124,1.166,1.166,0,0,0,.781-.271.92.92,0,0,0,.32-.741,1.126,1.126,0,0,0-.408-.9L19.33-25.25a2.3,2.3,0,0,0-1.533-.605,2.389,2.389,0,0,0-1.557.605L3.961-14.942A1.07,1.07,0,0,0,3.53-14.082ZM25.3-19.613l3.165,2.667v-5.578a.844.844,0,0,0-.234-.625.845.845,0,0,0-.625-.234H26.174a.867.867,0,0,0-.632.234.832.832,0,0,0-.24.625Z"
                          transform="translate(-3.53 25.856)"
                        />
                      </svg>
                    </Link>
                  </li>
                  <li>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="512"
                        viewBox="0 0 512 512"
                        width="512"
                      >
                        <g id="ARROW_48" data-name="ARROW 48">
                          <path d="m24 232h406.09l-126.34-126.35a24 24 0 0 1 0-33.94 24 24 0 0 1 33.94 0l166.89 166.85a24.66 24.66 0 0 1 0 34.89l-166.89 166.84a24 24 0 0 1 -33.94 0 24 24 0 0 1 0-33.94l126.34-126.35h-406.09a24 24 0 0 1 -24-24 24 24 0 0 1 24-24z" />
                        </g>
                      </svg>
                    </span>
                  </li>
                  <li className="active">
                    <Link
                      to={
                        window.location.pathname === ROUTES.ADMIN
                          ? ROUTES.ADMIN
                          : window.location.pathname === ROUTES.DELETEDUSERS
                          ? ROUTES.DELETEDUSERS
                          : ROUTES.USERS
                      }
                    >
                      {window.location.pathname === ROUTES.ADMIN
                        ? breadCrumb.ADMIN
                        : window.location.pathname === ROUTES.DELETEDUSERS
                        ? breadCrumb.DELETED_USERS
                        : breadCrumb.USERS}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-5">
              <div className="header-right">
                <div className="dropdown user-drop">
                  <button
                    type="button"
                    className="user-drop-btn dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <div className="circle-img">
                      <img src={userPlaceholder} alt="userPlaceholder" />
                    </div>
                    {capitalizeFirstLetter(authReducer.adminauthData.firstname)}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="11.25"
                      viewBox="0 0 20 11.25"
                    >
                      <path
                        id="Arrow_-_Down_2"
                        data-name="Arrow - Down 2"
                        d="M4.3,7.807a1.014,1.014,0,0,1,1.34-.1l.115.1L14,16.217l8.244-8.409a1.014,1.014,0,0,1,1.34-.1l.115.1a1.065,1.065,0,0,1,.1,1.366l-.1.118-8.971,9.151a1.014,1.014,0,0,1-1.34.1l-.115-.1L4.3,9.292A1.064,1.064,0,0,1,4.3,7.807Z"
                        transform="translate(-4 -7.5)"
                        fill="#fff"
                      />
                    </svg>
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={() => {
                          dispatch({
                            type: ActionType.LOGOUT,
                            payload: "",
                          });
                          dispatch({
                            type: ActionType.ADMINLOGOUT,
                            payload: "",
                          });
                          disconnectSocket();
                          emptyLocalStorageOnLogout();
                        }}
                        to={ROUTES.ADMINLOGIN}
                      >
                        {translation("logout")}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="Logout"
                          width="20"
                          height="19.003"
                          viewBox="0 0 20 19.003"
                        >
                          <path
                            id="Path_432"
                            data-name="Path 432"
                            d="M21.806,12.723H9.751a.6.6,0,0,1,0-1.2H21.806a.6.6,0,0,1,0,1.2Z"
                            transform="translate(-2.408 -2.504)"
                          />
                          <path
                            id="Path_433"
                            data-name="Path 433"
                            d="M18.865,15.645a.6.6,0,0,1-.424-1.026l2.5-2.494-2.5-2.494a.6.6,0,1,1,.848-.851L22.22,11.7a.6.6,0,0,1,0,.851L19.289,15.47A.6.6,0,0,1,18.865,15.645Z"
                            transform="translate(-2.397 -2.509)"
                          />
                          <path
                            id="Path_434"
                            data-name="Path 434"
                            d="M8.988,21.153c-3.587,0-5.441-.645-6.4-2.227-.929-1.533-.929-3.817-.929-7.275s0-5.742.929-7.275C3.547,2.8,5.4,2.15,8.988,2.15c2.73,0,4.361.351,5.455,1.172a5.363,5.363,0,0,1,1.775,4.061.571.571,0,1,1-1.137.106C14.8,4.4,13.837,3.308,8.988,3.308c-3.133,0-4.705.485-5.426,1.675C2.8,6.237,2.8,8.391,2.8,11.652s0,5.414.76,6.669C4.282,19.51,5.854,20,8.988,20c4.85,0,5.812-1.1,6.093-4.182a.571.571,0,1,1,1.137.106,5.363,5.363,0,0,1-1.775,4.061C13.348,20.8,11.717,21.153,8.988,21.153Z"
                            transform="translate(-1.659 -2.15)"
                          />
                        </svg>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminHeader;
