import React from "react";
import {
  UseFormRegister,
  FieldErrors,
  FieldValues,
  Control,
  Controller,
  FieldError,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import CommonErrorText from "../commonError/CommonErrorText";

interface IOptions {
  id?: string | number;
  name?: string;
  value?: string | number;
  _id?: string;
}

interface Iprops {
  option: IOptions[];
  errors?: FieldError | FieldErrors<FieldValues>;
  register?: UseFormRegister<FieldValues>;
  name: string;
  className: string;
  firstOption?: string;
  defaultValue?:
  | {
    name: string;
    id: string;
  }
  | string;
  control?: Control;
  disabled?: boolean;
  label?: string;
  required?: boolean;
  placeholder?: string;
  id?: string;
  value?:any;
  onChange?: (value: string) => void;
}

const CommonSelect: React.FC<Iprops> = (props) => {
  const {
    option,
    errors,
    className = "",
    register,
    name,
    defaultValue = "",
    control,
    firstOption,
    disabled,
    label,
    required,
    placeholder,
    id,
    onChange
  } = props;

  const { t } = useTranslation();

  return (
    <>
      {label && (
        <label htmlFor={name} className="font14">
          {label}
          {required ? <sup className="colorRed">*</sup> : null}
        </label>
      )}

        <Controller
          render={({ field }) => (
            <select {...field} className={className} disabled={disabled} defaultValue={defaultValue as string}
            onChange={(e) => {
              field.onChange(e);
              onChange && onChange(e.target.value);
            }}
            >
              {/* {placeholder && (
                <option value="" disabled={!defaultValue}>
                  {placeholder}
                </option>
              )} */}
              <option value="" disabled selected>Please Select</option>
              {option?.map((item) => (
                <option key={item.name} value={item.value}>
                  {item.name}
                </option>
              ))}
            </select>
          )}
          name={name}
          control={control}
          defaultValue={defaultValue as string}
        />
      
      {errors && errors.message ? (
        <p className="auth-msg danger">{errors?.message as string}</p>
      ) : null}

    </>
  );
};

export default CommonSelect;
