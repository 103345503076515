import ApiResponse from "../resources/domain/entity/IApiResponse";
import * as http from "../util/http";
import endpoint from "../constants/endpoint";
import { ITwoFactorAuthenticationConfrimation } from "../Interfaces/AuthInterface";

export const emailAvailability = (email: string): Promise<ApiResponse> => {
  return http.get(`${endpoint.user.EMAIL_AVAILABLE.replace(":id", email)}`);
};
export const twoFactorAuthenticationConfirmation = (
  data: ITwoFactorAuthenticationConfrimation
): Promise<ApiResponse> => {
  return http.post(
    `${endpoint.user.TWO_FACTOR_AUTHENCTICATION_CONFIRMATION}`,
    data
  );
};

export const forgetPassword = (email: string): Promise<ApiResponse> => {
  return http.post(`${endpoint.user.FORGET_PASSWORD}`, { email });
};
export const resetPassword = (data: any): Promise<ApiResponse> => {
  return http.post(`${endpoint.user.RESET_PASSWORD}`, data);
};
export const verifyResetLink = (token: string): Promise<ApiResponse> => {
  return http.post(`${endpoint.user.VERIFY_RESET_LINK}`, { token });
};
export const changePassword = (data: any): Promise<ApiResponse> => {
  return http.post(`${endpoint.user.CHANGE_PASSWORD}`, data);
};
export const deleteAccount = (data: any): Promise<ApiResponse> => {
  return http.post(`${endpoint.user.DELETE_USER}`, data);
};
export const editUserDetail = (data: any): Promise<ApiResponse> => {
  return http.post(`${endpoint.user.EDIT_USER_DETAIL}`, data);
};
export const resetSettings = (userId: any): Promise<ApiResponse> => {
  return http.post(`${endpoint.user.RESET_SETTINGS}`, userId);
};
export const resendTwoFactorCode = (email: any): Promise<ApiResponse> => {
  return http.post(`${endpoint.user.SEND_TWO_FACTOR_REGISTRATION}`, email);
};
export const userFromAdminPanel = (id: string): Promise<ApiResponse> => {
  return http.get(`${endpoint.user.USER_FROM_ADMIN.replace(":id",id)}`);
};

