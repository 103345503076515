import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useStripe, useElements } from "@stripe/react-stripe-js";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import CommonButton from "../../utilities/formElements/commonButton/CommonButton";

import { subscription } from "../../../services/susbscription";
import LockIcon from "../../../assets/images/lock-green.svg";
import { countries } from "../../../constants/commonConstants";
import { ISubscription } from "../../../Interfaces/SettingInterface";
import { toastMessageSuccess } from "../../utilities/commonToastMessage";

import Logo from "../../../assets/images/Skydive_logo_beta.svg";
import CongratsModel from "../../commonModel/InfoModel";

export interface PaymentModalProps {
  className?: string;
  title?: string;
  onClose?: any;
  children?: React.ReactNode;
  closeModel?: any;
  subscriptionData?: ISubscription;
  modelName?: string;
}

export interface IErrors {
  incomplete_number?: string;
  incomplete_expiry?: string;
  incomplete_cvc?: string;
  invalid_number?: string;
  invalid_expiry_year_past?: string;
}

const PaymentMethod: React.FC<PaymentModalProps> = ({
  onClose,

  modelName,
}) => {
  const authReducer = useSelector((state: any) => state?.AuthReducer);
  const { t: translation } = useTranslation();

  // stripe items
  const stripe = useStripe();
  const elements = useElements();

  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [stripeValidation, setStripeValidation] = useState<any>({
    cardNumber: { message: "" },
    cardExpiry: { message: "" },
    cvc: { message: "" },
  });

  const [nameError, setNameError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [cityError, setCityError] = useState("");

  const [errors, setErrors] = useState<IErrors>({
    incomplete_number: "",
    incomplete_expiry: "",
    incomplete_cvc: "",
    invalid_expiry_year_past: "",
    invalid_number: "",
  });

  const onClickClodeModel = () => {
    onClose();
  };

  // Process Upgrade
  const createSubscription = async () => {
    if (!name) {
      setNameError(translation("card_name_required"));
    }

    if (!city) {
      setCityError(translation("card_city_required"));
    }

    if (!country) {
      setCountryError(translation("card_country_required"));
    }

    setLoader(true);
    const cardElement = elements?.getElement(CardNumberElement);
    const cardExpiryElement = elements?.getElement(CardExpiryElement);
    const cardCvcElement = elements?.getElement(CardCvcElement);

    try {
      if (cardElement && cardExpiryElement && cardCvcElement) {
        const paymentMethod = await stripe?.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            name,
            address: {
              country,
              city,
              postal_code: postalCode,
            },
          },
        });

        if (paymentMethod?.error && paymentMethod?.error?.code) {
          const tempErr: IErrors = errors;
          tempErr[paymentMethod?.error?.code as keyof IErrors] =
            paymentMethod?.error.message;

          setErrors((prevErrors) => ({
            [paymentMethod?.error?.code as keyof IErrors]:
              paymentMethod?.error.message,
          }));
        }

        cardElement.on("change", function (event) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            incomplete_number: "",
          }));
          setStripeValidation((prevValidation: any) => ({
            ...prevValidation,
            cardNumber: { message: event.error ? event.error.message : "" },
          }));
        });

        cardExpiryElement.on("change", function (event) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            incomplete_expiry: "",
          }));
          setStripeValidation((prevValidation: any) => ({
            ...prevValidation,
            cardExpiry: { message: event.error ? event.error.message : "" },
          }));
        });

        cardCvcElement.on("change", function (event) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            incomplete_cvc: "",
          }));
          setStripeValidation((prevValidation: any) => ({
            ...prevValidation,
            cvc: { message: event.error ? event.error.message : "" },
          }));
        });

        if (paymentMethod && paymentMethod.paymentMethod) {
          if (modelName === "updateSuspendedAccountPaymentDetails") {
             await subscription({
              jsonprc: "2.0",
              method: "updateSuspendedAccountPaymentDetails",
              params: {
                userId: authReducer.authData._id,
                paymentMethod: {
                  id: paymentMethod?.paymentMethod?.id,
                  billingDetails: paymentMethod?.paymentMethod?.billing_details,
                  customer: authReducer?.authData?.stripe_id,
                },
              },
              id: 0,
            });
          } else {
             await subscription({
              jsonprc: "2.0",
              method: "setPaymentMethod",
              params: {
                userId: authReducer.authData._id,
                paymentMethod: {
                  id: paymentMethod?.paymentMethod?.id,
                  billingDetails: paymentMethod?.paymentMethod?.billing_details,
                  customer: authReducer?.authData?.stripe_id,
                },
              },
              id: 0,
            });
          }

          toastMessageSuccess(translation("payment_method_updated"));
          onClickClodeModel();
        }

        setLoader(false);
      }
    } catch (error) {}
    setLoader(false);
  };

  return (
    <>
      <div className="modal show-modal common-modal">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                {translation("upgrade_your_payment_method")}
              </h4>
              <CommonButton
                type="button"
                className="btn-close btn-close-white"
                onClick={onClose}
                data-bs-dismiss="modal"
              />
            </div>
            <div className="modal-body common-body text-primary upgrade-subscription">
              <div className="row g-4">
                <div className="col-md-12">
                  <div className="subscription-desc">
                    <div className="card-form">
                      <form>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>{translation("name_on_card")}</label>
                              <input
                                id="name"
                                className="form-control"
                                placeholder={translation("name_on_card")}
                                type="text"
                                value={name}
                                onChange={(e) => {
                                  setName(e.target.value);
                                  setNameError("");
                                }}
                              />
                              {nameError && (
                                <p className="auth-msg danger">{nameError}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>{translation("card_number")}</label>
                              <CardNumberElement
                                className="form-control"
                                id="cardNumber"
                              />
                              {(errors.incomplete_number ||
                                stripeValidation.cardNumber.message) && (
                                <p className="auth-msg danger">
                                  {stripeValidation.cardNumber.message ? (
                                    <span className="auth-msg danger">
                                      {stripeValidation.cardNumber.message}
                                    </span>
                                  ) : (
                                    <span className="auth-msg danger">
                                      {errors.incomplete_number}
                                    </span>
                                  )}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group" id="myDiv">
                              <label>{translation("expiration_date")}</label>
                              <CardExpiryElement
                                className="form-control"
                                id="expiry"
                              />
                              {(errors.incomplete_expiry ||
                                stripeValidation.cardExpiry.message) && (
                                <p className="auth-msg danger">
                                  {stripeValidation.cardExpiry.message
                                    ? stripeValidation.cardExpiry.message
                                    : errors.incomplete_expiry}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>{translation("cvc")}</label>
                              <CardCvcElement
                                className="form-control"
                                id="cvc"
                              />
                              {(errors.incomplete_cvc ||
                                stripeValidation.cvc.message) && (
                                <p className="auth-msg danger">
                                  {stripeValidation.cvc.message
                                    ? stripeValidation.cvc.message
                                    : errors.incomplete_cvc}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>{translation("country")}</label>
                              <select
                                id="country"
                                onChange={(e) => {
                                  setCountry(e.target.value);
                                  setCountryError("");
                                }}
                                name="selectedReason"
                                className="form-select form-control country-select"
                              >
                                <option value="" disabled selected>
                                  {translation("please_select")}
                                </option>
                                {countries?.map((item) => (
                                  <option key={item.name} value={item.value}>
                                    {item.name ? item.name : item.value}
                                  </option>
                                ))}
                              </select>
                              {countryError && (
                                <p className="auth-msg danger">
                                  {countryError}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>{translation("city_town")}</label>
                              <input
                                id="city"
                                className="form-control"
                                placeholder={translation("city_town")}
                                type="text"
                                value={city}
                                onChange={(e) => {
                                  setCity(e.target.value);
                                  setCityError("");
                                }}
                              />
                              {cityError && (
                                <p className="auth-msg danger">{cityError}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>{translation("zip")}</label>
                              <input
                                className="form-control"
                                placeholder={translation("zip")}
                                type="text"
                                value={postalCode}
                                onChange={(e) => setPostalCode(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                      <p className="encrypt-text">
                        <img src={LockIcon} alt="icon" />
                        {translation("secure_payment_with_rsa_encryption")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="process-upgrade">
                    <p>{translation("stripe_terms")}</p>
                    <CommonButton
                      label="Submit Payment Details"
                      className="theme-btn success-btn text-uppercase"
                      onClick={createSubscription}
                      loading={loader}
                      disabled={!stripe || loader}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer upgrade-main-footer">
              <div className="upgrade-footer">
                <div>
                  <p>{translation("head_for_the_rarefied_air")}</p>
                  <h5>
                    {translation(
                      "upgrade_your_account_today_for_more_features"
                    )}
                  </h5>
                </div>
                <img src={Logo} alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {successModal && (
        <CongratsModel
          onClose={() => setSuccessModal(false)}
          name="OrderComplete"
          title="Order Complete"
          hasImage={true}
          header={true}
        />
      )}
    </>
  );
};

export default PaymentMethod;
