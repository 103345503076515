import "./components/i18n/I18n";
import { ToastContainer } from "react-toastify";

import "../node_modules/bootstrap/dist/css/bootstrap.css";

import "./assets/theme/Styles.scss";
import PublicRoutes from "./routes/PublicRoutes";
import { useEffect } from "react";
import { eventEmitter } from "./eventEmitters/EventEmitters";
import { disconnectSocket } from "./util/socket";
import { emptyLocalStorageOnLogout } from "./util/helper";
import { useDispatch } from "react-redux";
import ActionType from "./resources/enums";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    logoutEvent();
  }, []);
  const logoutEvent = () => {
    eventEmitter.addListener("logout", () => {
      dispatch({
        type: ActionType.LOGOUT,
        payload: "",
      });
      disconnectSocket();
      emptyLocalStorageOnLogout();
    });
  };

  return (
    <div className="App">
      <PublicRoutes />
      <ToastContainer />
    </div>
  );
};

export default App;
