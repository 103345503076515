import React, { MouseEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import { Control, FieldErrors, FieldValues, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import storage from "../../util/storage";

import { forgetPassword } from "../../services/user";

import InputWrapper from "./formElements/commonInput/InputWrapper";
import CommonButton from "./formElements/commonButton/CommonButton";

import { toastMessageError, toastMessageSuccess } from "./commonToastMessage";
import { forgetPasswordFormEmailValidation } from "./validationSchema";

interface Iprops {
  className?: string;
  name?: string;
  defaultValue?: string;
  error?: FieldErrors<FieldValues>;
  submitResponse?: any;
  closeModel?: any;
}
const CommonModel: React.FC<Iprops> = (props) => {
  const { t: translation } = useTranslation();
  const {
    className = "",
    name,
    defaultValue,
    error,
    submitResponse,
    closeModel,
  } = props;

  const {
    control: controlForm,
    handleSubmit: handleSubmitForm,
    formState: { errors },
    getValues: getValuesForm,
  } = useForm({
    resolver: yupResolver(forgetPasswordFormEmailValidation(translation)),
  });

  const [loading, setLoading] = useState(false);
  const formDataSubmit = async (data: any) => {
    setLoading(true);
    storage.set("email", data.email);
    const sendForgetpasswordCode = await forgetPassword(data.email);
    if (sendForgetpasswordCode && sendForgetpasswordCode.data) {
      toastMessageSuccess(translation("email_sent"), "emailSent");
    } else {
      toastMessageError(translation("email_not_registered"), "emailNotFound");
    }
    setLoading(false);
  };

  return (
    <div className="modal show-modal common-modal">
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Password Reset</h4>
          </div>

          <div className="modal-body common-body text-primary">
            <form onSubmit={handleSubmitForm((data) => formDataSubmit(data))}>
              <div className="form-group text-start">
                <label className="font14 fontBold">Email</label>

                <InputWrapper
                  control={controlForm}
                  name="email"
                  className="form-control"
                  placeholder="Enter email address"
                  error={errors?.email}
                  type="email"
                />
              </div>
              <div className="text-center mt-4 mb-3">
                <CommonButton
                  type="submit"
                  label={translation("send_reset_mail")}
                  className="theme-btn success-btn text-uppercase fontBold me-3"
                  disabled={loading}
                  loading={loading}
                />

                <CommonButton
                  type="button"
                  label={translation("cancel")}
                  className="theme-btn white-btn text-uppercase fontBold"
                  onClick={closeModel}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CommonModel;
