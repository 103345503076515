import React from "react";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DateRange } from "react-date-range";

import CommonButton from "../utilities/formElements/commonButton/CommonButton";
import OutsideAlerter from "../utilities/useOutSideComponentClick";

import { rgbToHex } from "../../util/tooltipService";
import {
  DateRangeFilterSet,
  availableFilters,
  handleFilterChange,
  historyDataDateRange,
  items,
  selectedDateRange,
  selectedEndDateRange,
  selectedFilter,
  setInitialDateFilter,
} from "../../util/clusterService";

import { ISubscription } from "../../Interfaces/SettingInterface";
import { IPaymentData } from "../../Interfaces/CommonInterface";

import { subscription } from "../../services/susbscription";

import { eventEmitter } from "../../eventEmitters/EventEmitters";

import {
  initialHistoryDataDateRange,
  baseJumpPlan,
  INITIAL_FILTERS,
} from "../../../src/constants/commonConstants";
import Payment from "../views/paymentModel/Payment";
import ActionType from "../../resources/enums";

import Logo from "../../assets/images/Skydive_logo_beta.svg";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./Sidebar.scss";

const Sidebar = () => {
  const { t: translation } = useTranslation();
  const authReducer: any = useSelector((state: any) => state?.AuthReducer);
  const [dateRangeType, setDateRangeType] = useState(
    selectedDateRange
      ? selectedDateRange
      : authReducer?.historicalFilterSelected.name
      ? authReducer?.historicalFilterSelected.name
      : historyDataDateRange[0]
  );
  const dispatch = useDispatch();
  useEffect(() => {
    getMetricsDataInfo();
  }, []);
  useEffect(() => {
    setClosed(false);
  }, [authReducer?.isClose]);
  useEffect(() => {
    setShowAvailableFilters(availableFilters);
  }, [availableFilters]);
  eventEmitter.addListener("userCountChanged", (count) => {
    setActiveUserDetails(JSON.parse(JSON.stringify(items)));
  });
  const siteId = authReducer.authData.site._id;
  const [menuType, setMenuType] = useState<any>(
    selectedFilter ? selectedFilter : INITIAL_FILTERS[0]
  );
  const [changePaymentDetails, setChangePaymentDetails] =
    useState<boolean>(false);
  const [changeCustomRange, setChangeCustomRange] = useState<boolean>(false);
  const [subscriptionDetails, setSubscriptionDetails] =
    useState<ISubscription>();
  const [paymentMethod, setPaymentMethod] = useState<IPaymentData>();
  const [restrictedRealtimeFilters, setRestrictedRealtimeFilters] =
    useState<any>(
      availableFilters && availableFilters[0]
        ? availableFilters[0]
        : INITIAL_FILTERS[0]
    );

  const [showRangeFilter, setShowRangeFilter] = useState(false);
  const [dropClass, setDropClass] = useState(false);
  const [showAvailableFilters, setShowAvailableFilters] =
    useState(availableFilters);

  const [isSelected, setIsSelected] = useState({
    selected: true,
    name:
      selectedDateRange && selectedDateRange.name
        ? selectedDateRange.name
        : authReducer?.historicalFilterSelected.name
        ? authReducer?.historicalFilterSelected.name
        : historyDataDateRange[0].name,
  });
  const [selectedHistoricalFilter, setSelectedHistoricalFilter] = useState(
    historyDataDateRange &&
      historyDataDateRange[0] &&
      historyDataDateRange[0].value
      ? historyDataDateRange[0]
      : initialHistoryDataDateRange[0]
  );

  const dateRangeRef = useRef<any>(null);
  const [closed, setClosed] = useState<boolean>(true);

  const handleHistoricalFilterSelect = (selectedFilter, e) => {
   
    
    e.stopPropagation();
    setSelectedHistoricalFilter(selectedFilter);
    setIsSelected({
      selected: true,
      name: selectedFilter.name,
    });
    handleApplyFilter(selectedFilter);
   
  };
  const handleApplyFilter = (filters?: { name: string; value: number }) => {
   
    const selectedFilters = filters ? filters : selectedHistoricalFilter;
    setDropClass(false);
    setChangeCustomRange(false);
    setShowRangeFilter(false);
    if (selectedFilters) {
      DateRangeFilterSet(
        selectedFilters.name === "All"
          ? { name: "All", value: new Date(authReducer.authData.createdAt) }
          : {
              name: selectedFilters.name,
              value: selectedFilters.value,
            }
          ? {
              name: selectedFilters.name,
              value: new Date(selectedFilters.value).toISOString(),
            }
          : { name: "Today", value: new Date().toISOString() },
        selectedFilters.name === "Custom Range"
          ? {
              name: "Custom Range",
              value:    new Date(dateRange?.[0]?.endDate).toDateString() ===new Date().toDateString() ?new Date().toISOString() : new Date(dateRange?.[0]?.endDate).toISOString(),
            }
          : { name: "Custom Range", value: new Date().toISOString() }
      );
      setDateRangeType(selectedFilters);
      dispatch({
        type: ActionType.HISTORICAL_FILTER_SELECTED,
        payload: selectedFilters,
      });
    }
   
  };

  const handleCustomRangeClick = (selectedFilter, e) => {
    setShowRangeFilter(!showRangeFilter);
    e.stopPropagation();
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setChangeCustomRange(!changeCustomRange);
  };

  const getMetricsDataInfo = async () => {
    eventEmitter.emit("realtimeFilterChanged");
  };
  const [activeUserDetails, setActiveUserDetails] = useState([]);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const events = () => {
    eventEmitter.addListener("sideBarRealtimeItemsUpdated", (data) => {
      setActiveUserDetails(JSON.parse(JSON.stringify(items)));
    });
  };

  useEffect(() => {
    if (dateRangeRef.current) {
      const { current: dateRangePicker } = dateRangeRef;

      dateRangePicker.addEventListener("change", (event: any) => {
        const { startDate, endDate } = event.target.value;
      });
    }
  }, []);

  useEffect(() => {
    events();
    fetchData();
  }, []);

  const fetchData = async () => {
    setSubscriptionDetails(authReducer?.accountRecord);
    setRestrictedRealtimeFilters(
      authReducer?.accountRecord?.subscriptionData?.restrictedRealtimeFilters
    );
    setInitialDateFilter(authReducer?.accountRecord);
    const getPaymentMethod = await subscription({
      jsonprc: "2.0",
      method: "getPaymentMethod",
      params: {
        userId: authReducer.authData._id,
      },
      id: 0,
    });
    setPaymentMethod(getPaymentMethod?.data?.result);
  };
  const someThing =
    window.innerHeight * 0.85 > 500 ? window.innerHeight * 0.85 : 500;

  const handleOutsideClick = () => {
    setDropClass(false);
  };

  const mainClusterHeight = someThing * 0.75 + 130;
  return (
    <>
      <div className="sidebar-main" style={{ minHeight: mainClusterHeight }}>
        <div>
          <div className="real-time">
            <h5>{translation("real_time")}</h5>
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                onClick={() => setDropClass(false)}
              >
                {<span>{translation("by")} </span>}
                {menuType.name}
              </button>
              <ul className="dropdown-menu">
                {showAvailableFilters &&
                  showAvailableFilters.map((ele: any) => (
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        handleFilterChange(ele);
                        setMenuType(ele);
                      }}
                    >
                      {ele.name}
                    </li>
                  ))}
                {authReducer?.accountRecord?.subscriptionData?.title ===
                  baseJumpPlan && (
                  <div className="missing-filters">
                    <p>{translation("want_more_filters")}</p>
                    <div className="missing-filters-inline">
                      <img src={Logo} alt="logo" />
                      <Link
                        to="#"
                        className="upgrade-action"
                        onClick={() => {
                          setClosed(true);
                          setChangePaymentDetails(true);
                        }}
                      >
                        {translation("upgrade_now")}
                      </Link>
                    </div>
                  </div>
                )}
              </ul>
            </div>
          </div>

          <ul className="filter-data">
            {activeUserDetails &&
              activeUserDetails?.map((ele: any, index: number) => (
                <li>
                  <p>{ele.name}</p>
                  <p>
                    {ele.currentUsers}
                    {ele.colorInfo && (
                      <span
                        className="color-status"
                        style={{ backgroundColor: rgbToHex(ele.colorInfo) }}
                      />
                    )}
                  </p>
                </li>
              ))}
          </ul>
        </div>

        <div className="historical">
          <h5>{translation("historical")}</h5>
          <OutsideAlerter onOutsideClick={handleOutsideClick}>
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-primary dropdown-toggle"
                onClick={() => setDropClass(!dropClass)}
              >
                {dateRangeType.name === "Custom Range"
                  ? `${new Date(selectedDateRange.value).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      }
                    )}
                  
                  - ${new Date(selectedEndDateRange).toLocaleDateString(
                    "en-US",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  )}`
                  : dateRangeType.name === "All"
                  ? `
                  ${new Date(
                    authReducer.authData?.createdAt
                  ).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })} 
                   - Today`
                  : dateRangeType.name
                  ? dateRangeType.name
                  : "Today"}
              </button>

              {dropClass && (
                <div
                  className={
                    showRangeFilter
                      ? "dropdown-menu historical-dropwown show-custom-range-width"
                      : "dropdown-menu historical-dropwown"
                  }
                >
                  <ul>
                    {historyDataDateRange &&
                      historyDataDateRange.map((ele: any) => (
                        <li
                          className={
                            isSelected.name === ele.name && isSelected.selected
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          onClick={(e) => {
                            ele.name === "Custom Range"
                              ? (setIsSelected({
                                  selected: true,
                                  name: ele.name,
                                }),
                                handleCustomRangeClick(ele, e))
                              : (setIsSelected({
                                  selected: true,
                                  name: ele.name,
                                }),
                                handleHistoricalFilterSelect(ele, e));
                          }}
                        >
                          {ele.name}
                        </li>
                      ))}

                    {authReducer?.accountRecord?.subscriptionData?.title ===
                      baseJumpPlan && (
                      <div className="missing-filters">
                        <p>{translation("want_more_customize_range")}</p>
                        <div className="missing-filters-inline">
                          <img src={Logo} alt="logo" />
                          <Link
                            to="#"
                            className="upgrade-action"
                            onClick={() => {
                              setClosed(true);
                              setChangePaymentDetails(true);
                            }}
                          >
                            {translation("upgrade_now")}
                          </Link>
                        </div>
                      </div>
                    )}
                  </ul>

                  {changeCustomRange && (
                    <div>
                      <DateRange
                        editableDateInputs={true}
                        onChange={(item) => {
                          setDateRange([item.selection]);
                          setSelectedHistoricalFilter({
                            name: "Custom Range",
                            value: item.selection.startDate,
                          });
                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={dateRange}
                        direction="horizontal"
                        maxDate={new Date()}
                      />
                      {historyDataDateRange.filter(
                        (item) => item.name === "Custom Range"
                      ).length ? (
                        <div className="button-align-sidebar">
                          <CommonButton
                            type="submit"
                            className="theme-btn success-btn text-uppercase"
                            label="Apply"
                            onClick={() => handleApplyFilter()}
                          />
                          <CommonButton
                            type="button"
                            className="theme-btn white-btn text-uppercase"
                            label="Cancel"
                            onClick={() => {
                              setDropClass(false);
                              setChangeCustomRange(false);
                              setShowRangeFilter(false);
                              setIsSelected({
                                selected: false,
                                name: authReducer?.historicalFilterSelected.name
                                  ? authReducer?.historicalFilterSelected.name
                                  : historyDataDateRange[0].name,
                              });
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </OutsideAlerter>
        </div>
      </div>
      {changePaymentDetails && closed && (
        <Payment
          onClose={() => {
            setChangePaymentDetails(false);
          }}
          subscriptionData={subscriptionDetails}
          paymentMethod={paymentMethod}
        />
      )}
    </>
  );
};

export default Sidebar;
