import ApiResponse from "../resources/domain/entity/IApiResponse";
import * as http from "../util/http";
import endpoint from "../constants/endpoint";
import { ICommonPayload } from "../Interfaces/CommonInterface";

export const site = (data:ICommonPayload): Promise<ApiResponse> => {
    return http.post(`${endpoint.sites.SITE}`,data);
  };

export const siteAvailable = (data:string): Promise<ApiResponse> => {
    return http.get(`${endpoint.sites.SITE_AVAILABLE}?siteUrl=${data}`);
  };
