import { eventEmitter } from "../eventEmitters/EventEmitters";
import { session } from "../services/session";
import storage from "./storage";


interface MetricsData {
  totalSession: number;
  bounceRate: string;
  avgSessionDuration: string;
  newUsers: number;
  percentNewSessions: number;
  pageviewsPerSession: number;
}

// eslint-disable-next-line prefer-const
export let historical: MetricsData = {
  totalSession: 0,
  bounceRate: 0 + "%",
  avgSessionDuration: 0 + "min",
  newUsers: 0,
  percentNewSessions: 0,
  pageviewsPerSession: 0,
};
let totalPageViews: number;
 export let subscriptionPageViews: number;


 export const getSubscriptionPageViewCount = async(data:string) =>{
  const SubscriptionPageViewCount = await session({
    jsonprc: "2.0",
    method: "getSubscriptionPageViewCount",
    params: {
      userId: data,
    },
    id: 0,
  });
  subscriptionPageViews = SubscriptionPageViewCount?.data?.result;
};
export const getMetricsData = async (data: any) => {
  const requestParams = {
    id: 0,
    jsonprc: "2.0",
    method: "getMainMetrics",
    params: {
      startDate: new Date(data.startDate).toISOString(),
      endDate: data.endDate,
      siteId: storage.get("siteId"),
    },
  };
 

  const metricsData: any = await session(requestParams);
  const percentNewSessionsNumber =
    (metricsData?.data?.result?.newUsers /
      metricsData?.data?.result?.totalSession) *
      100 || 0;
  const percentNewSessions = parseFloat(percentNewSessionsNumber.toFixed(1));
  return {
    totalSession: metricsData?.data?.result?.totalSession,
    bounceRate: metricsData?.data?.result?.bounceRate,
    avgSessionDuration: metricsData?.data?.result?.avgSessionDuration,
    newUsers: metricsData?.data?.result?.newUsers,
    percentNewSessions,
    pageviewsPerSession: metricsData?.data?.result?.pageviewsPerSession,
  };
};



eventEmitter.addListener("newSession", () => {
  totalPageViews++;
  subscriptionPageViews++;
});

eventEmitter.addListener("sessionChanged", () => {
  totalPageViews++;
  subscriptionPageViews++;
});
export const updateSubscriptionPageViews = (data)=>{
  subscriptionPageViews = data;
};
