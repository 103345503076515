import React from "react";
import { useTranslation } from "react-i18next";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CommonButton from "../../utilities/formElements/commonButton/CommonButton";

import { ISubscription } from "../../../Interfaces/SettingInterface";
import { IPaymentData } from "../../../Interfaces/CommonInterface";

import PaymentModel from "./PaymentModal";
import config from "../../../config/config";

import Logo from "../../../assets/images/Skydive_logo_beta.svg";

export interface PaymentProps {
  className?: string;
  title?: string;
  onClose?: any;
  children?: React.ReactNode;
  closeModel?: any;
  name?: string;
  subscriptionData?: ISubscription;
  paymentMethod?: IPaymentData;
}

const Payment: React.FC<PaymentProps> = ({
  onClose,
  subscriptionData,
  paymentMethod,
}) => {
  const { t: translation } = useTranslation();

  const publishableKey = config.stripePublishableKey;

  const stripePromise = loadStripe(publishableKey as string);
  return (
    <>
      <div className="modal show-modal common-modal">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                {translation("upgrade_your_skydive_subscription")}
              </h4>
              <CommonButton
                type="button"
                className="btn-close btn-close-white"
                onClick={onClose}
                data-bs-dismiss="modal"
              />
            </div>
            <Elements stripe={stripePromise}>
              <PaymentModel
                subscriptionData={subscriptionData}
                paymentMethodData={paymentMethod}
              />
            </Elements>
            <div className="modal-footer upgrade-main-footer">
              <div className="upgrade-footer">
                <div>
                  <p>{translation("head_for_the_rarefied_air")}</p>
                  <h5>
                    {translation(
                      "upgrade_your_account_today_for_more_features"
                    )}
                  </h5>
                </div>
                <img src={Logo} alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Payment;
