enum ActionType {
  LOGIN = "LOGIN",
  USER_LOGIN_BY_ADMIN = "USER_LOGIN_BY_ADMIN",
  ACTIVEUSERCOUNT="ACTIVEUSERCOUNT",
  LOGOUT = "LOGOUT",
  UPDATECLUSTER = "UPDATECLUSTER",
  EMAILVALIDATED="EMAILVALIDATED",
  ADMINLOGIN = "ADMINLOGIN",
  ADMINLOGOUT = "ADMINLOGOUT",
  SUBSCRIPTIONTITLE = "SUBSCRIPTIONTITLE",
  SUBSCRIPTIONDATA= "SUBSCRIPTIONDATA",
  SORTING_ORDER="SORTING_ORDER",
  HISTORICAL_FILTER_SELECTED="HISTORICAL_FILTER_SELECTED",
  TOTALSESSION ="TOTALSESSION",
  CLOSEMODEL="CLOSEMODEL"

}

export default ActionType;
