import ApiResponse from "../resources/domain/entity/IApiResponse";
import * as http from "../util/http";
import endpoint from "../constants/endpoint";
import { IAdminDetails, INewAdmin } from "../Interfaces/AdminInterface";


export const getDeleteUserDetails = (data?: any): Promise<ApiResponse> => {
    const queryParams: string[] = [];
    if (data && Object.keys(data).length) {
        Object.keys(data).map((key: any) => {
            queryParams.push(`${key}=${data[key]}`);
        });
    }
    return http.post(`${endpoint.superAdmin.DELETED_USER_DETAILS}`, data);
};

export const saveAdminUserDetails = (data:INewAdmin): Promise<ApiResponse> => {
    return http.post(`${endpoint.superAdmin.SAVE_ADMIN_USER_DETAILS}`, data);
};

export const editAdminUserDetail = (data:IAdminDetails) :Promise<ApiResponse> => {
    return http.post(`${endpoint.superAdmin.EDIT_ADMIN_USER_DETAILS}`, data);
};

export const deleteAdminUserDetail = (data:string) :Promise<ApiResponse> => {
    return http.post(`${endpoint.superAdmin.DELETE_ADMIN_USER_DETAILS}`, {_id:data});
};

export const deleteUserDetail = (data:string) :Promise<ApiResponse> => {
    return http.post(`${endpoint.superAdmin.DELETE_USER}`,{userId:data});
};
