import ApiResponse from "../resources/domain/entity/IApiResponse";
import * as http from "../util/http";
import endpoint from "../constants/endpoint";
import { ICommonPayload } from "../Interfaces/CommonInterface";

export const subscription = (data:ICommonPayload): Promise<ApiResponse> => {
    return http.post(`${endpoint.subscription.SUBSCRIPTION}`,data);
  };

  export const adminSubscription = (data:ICommonPayload): Promise<ApiResponse> => {
    return http.post(`${endpoint.subscription.ADMIN_SUBSCRIPTION}`,data);
  };

  
