import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useStripe, useElements } from "@stripe/react-stripe-js";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import { toastMessageError } from "../../utilities/commonToastMessage";
import CommonButton from "../../utilities/formElements/commonButton/CommonButton";

import storage from "../../../util/storage";

import { subscription } from "../../../services/susbscription";
import { currentUser } from "../../../services/auth";

import { ISubscription } from "../../../Interfaces/SettingInterface";
import { IPaymentData } from "../../../Interfaces/CommonInterface";

import {
  baseJumpPlan,
  fourteenDaysPlan,
  paymentFormPlans,
} from "../../../constants/commonConstants";
import { countries } from "../../../constants/commonConstants";

import ActionType from "../../../resources/enums";
import CongratsModel from "../../commonModel/InfoModel";

import LockIcon from "../../../assets/images/lock-green.svg";
import Check from "../../../assets/images/checkIcon.svg";
import { eventEmitter } from "../../../eventEmitters/EventEmitters";

export interface PaymentModalProps {
  className?: string;
  title?: string;
  onClose?: any;
  children?: React.ReactNode;
  closeModel?: any;
  subscriptionData?: ISubscription;
  paymentMethodData?: IPaymentData;
}

export interface IErrors {
  incomplete_number?: string;
  incomplete_expiry?: string;
  incomplete_cvc?: string;
  invalid_number?: string;
  invalid_expiry_year_past?: string;
}

const PaymentModel: React.FC<PaymentModalProps> = ({
  subscriptionData,
  paymentMethodData,
}) => {
  const authReducer = useSelector((state: any) => state?.AuthReducer);
  const dispatch = useDispatch();
  const { t: translation } = useTranslation();

  const stripe = useStripe();
  const elements = useElements();

  const [paymentData, setPaymentData] = useState<IPaymentData | any>(
    paymentMethodData
  );
  const [addNewCard, setAddNewCard] = useState<boolean>(true);

  const [selected, setSelected] = useState<number>();
  const [currentPlan, setCurrentPlan] = useState(
    subscriptionData?.subscriptionData?.title
  );
  const [subscriptionTitle, setSubscriptionTitle] = useState("");
  const [checkError, setCheckError] = useState<boolean>(false);

  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [oldCard, setOldCard] = useState<boolean>(false);

  const [stripeValidation, setStripeValidation] = useState<any>({
    cardNumber: { message: "" },
    cardExpiry: { message: "" },
    cvc: { message: "" },
  });

  const [nameError, setNameError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [cityError, setCityError] = useState("");

  const [planState, setPlanState] = useState("");

  const [errors, setErrors] = useState<IErrors>({
    incomplete_number: "",
    incomplete_expiry: "",
    incomplete_cvc: "",
    invalid_expiry_year_past: "",
    invalid_number: "",
  });
  useEffect(() => {
    if (checkError) {
      !authReducer.susbcriptionTitle &&
        setPlanState(translation("please_select_sub_plan"));
    }
  }, [checkError]);

  const getAccountRecord = async () => {
    const data = await subscription({
      jsonprc: "2.0",
      method: "getAccountRecord",
      params: {
        userId: authReducer.authData._id,
      },
      id: 0,
    });
    dispatch({
      type: ActionType.SUBSCRIPTIONDATA,
      payload: data.data?.result,
    });
    storage.set("urlsToTrack", data.data?.result.subscriptionData.urlsToTrack);
    storage.set(
      "mainViewSlots",
      data.data?.result?.subscriptionData?.mainViewSlots
    );
  };
  /**
   *
   * Process Upgrade
   */
  const createSubscription = async () => {
    if (authReducer.subscriptionTitle) {
      if (paymentData) {
        setLoader(true);
        const changeSubscription = await subscription({
          jsonprc: "2.0",
          method: "changeSubscription",
          params: {
            userId: authReducer.authData._id,
            subscriptionTitle: authReducer?.subscriptionTitle,
          },
          id: 0,
        });

        if (changeSubscription?.data) {
          dispatch({
            type: ActionType.SUBSCRIPTIONTITLE,
            payload: { title: "" },
          });
          getAccountRecord();
          setLoader(false);
          setSuccessModal(true);
          const currentUserData: any = await currentUser();

          storage.set("siteId", currentUserData.data.site._id);

          if (currentUserData && currentUserData?.data) {
            dispatch({
              type: ActionType.LOGIN,
              payload: currentUserData.data,
            });
          }
        } else {
          toastMessageError(translation("something_went_wrong"));
        }
      } else {
        if (!name) {
          setNameError(translation("card_name_required"));
        }

        if (!city) {
          setCityError(translation("card_city_required"));
        }

        if (!country) {
          setCountryError(translation("card_country_required"));
        }

        setLoader(true);
        const cardElement = elements?.getElement(CardNumberElement);
        const cardExpiryElement = elements?.getElement(CardExpiryElement);
        const cardCvcElement = elements?.getElement(CardCvcElement);

        try {
          if (cardElement && cardExpiryElement && cardCvcElement) {
            const paymentMethod = await stripe?.createPaymentMethod({
              type: "card",
              card: cardElement,
              billing_details: {
                name,
                address: {
                  country,
                  city,
                  postal_code: postalCode,
                },
              },
            });

            if (paymentMethod?.error && paymentMethod?.error?.code) {
              const tempErr: IErrors = errors;
              tempErr[paymentMethod?.error?.code as keyof IErrors] =
                paymentMethod?.error.message;

              setErrors((prevErrors) => ({
                [paymentMethod?.error?.code as keyof IErrors]:
                  paymentMethod?.error.message,
              }));
            }

            cardElement.on("change", function (event) {
              setErrors((prevErrors) => ({
                ...prevErrors,
                incomplete_number: "",
              }));
              setStripeValidation((prevValidation: any) => ({
                ...prevValidation,
                cardNumber: { message: event.error ? event.error.message : "" },
              }));
            });

            cardExpiryElement.on("change", function (event) {
              setErrors((prevErrors) => ({
                ...prevErrors,
                incomplete_expiry: "",
              }));
              setStripeValidation((prevValidation: any) => ({
                ...prevValidation,
                cardExpiry: { message: event.error ? event.error.message : "" },
              }));
            });

            cardCvcElement.on("change", function (event) {
              setErrors((prevErrors) => ({
                ...prevErrors,
                incomplete_cvc: "",
              }));
              setStripeValidation((prevValidation: any) => ({
                ...prevValidation,
                cvc: { message: event.error ? event.error.message : "" },
              }));
            });
            if (paymentMethod && paymentMethod.paymentMethod) {
              await subscription({
                jsonprc: "2.0",
                method: "setPaymentMethod",
                params: {
                  userId: authReducer.authData._id,
                  paymentMethod: {
                    id: paymentMethod?.paymentMethod?.id,
                    billingDetails:
                      paymentMethod?.paymentMethod?.billing_details,
                    customer: authReducer?.authData?.stripe_id,
                  },
                },
                id: 0,
              });

              const changeSubscription = await subscription({
                jsonprc: "2.0",
                method: "changeSubscription",
                params: {
                  userId: authReducer.authData._id,
                  subscriptionTitle: authReducer?.subscriptionTitle,
                },
                id: 0,
              });

              if (changeSubscription) {
                dispatch({
                  type: ActionType.SUBSCRIPTIONTITLE,
                  payload: { title: "" },
                });
                getAccountRecord();
                setSuccessModal(true);
                const currentUserData: any = await currentUser();

                storage.set("siteId", currentUserData.data.site._id);

                if (currentUserData && currentUserData?.data) {
                  dispatch({
                    type: ActionType.LOGIN,
                    payload: currentUserData.data,
                  });
                }
              } else {
                toastMessageError(translation("something_went_wrong"));
              }
            }

            setLoader(false);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      setCheckError(true);

      setLoader(false);
    }
  };

  return (
    <>
      <div className="modal-body common-body text-primary upgrade-subscription">
        <div className="row g-2">
          <div className="col-md-6">
            <div className="subscription-desc">
              <h4 className="subscription-heading">
                {translation("select_subscription")}
              </h4>
              <div className="row">
                {paymentFormPlans.map(
                  (item, key) =>
                    (currentPlan === fourteenDaysPlan
                      ? item.title !== baseJumpPlan
                      : item.title !== currentPlan) && (
                      <div
                        className="col-lg-6 col-sm-12"
                        onClick={() => {
                          setSelected(key);
                          dispatch({
                            type: ActionType.SUBSCRIPTIONTITLE,
                            payload: item,
                          });
                          setSubscriptionTitle(item.title);
                          setPlanState("");
                        }}
                      >
                        <div
                          className={
                            key === selected
                              ? "card payment-card active"
                              : "card payment-card"
                          }
                        >
                          <h5>{item.title}</h5>
                          <div className="price-details">
                            <p className="price">{item?.cost}</p>
                            <p>{item.billingPeriod}</p>
                          </div>
                          <h6>{item.description}</h6>
                          <ul>
                            <li>
                              <span>{item.pageviews}</span>{" "}
                              {translation("pageviews")}
                            </li>
                            <li>
                              <span>{item.websites}</span>{" "}
                              {translation("website_to_track")}
                            </li>
                            <li>
                              <span>{item.dataStorage}</span>{" "}
                              {item.dataStorage === 30
                                ? translation("days_data_storage")
                                : translation("year_data_storage")}
                            </li>
                            <li>
                              <span>{item.urls}</span>{" "}
                              {translation("url_to_track")}
                            </li>
                            <li>
                              <span>{item.mainPageviews}</span>{" "}
                              {translation("main_page_views")}
                            </li>
                          </ul>
                          <CommonButton
                            className={
                              key === selected
                                ? "theme-btn success-btn text-uppercase btn-sm icon-btn"
                                : "theme-btn success-outline-btn text-uppercase btn-sm icon-btn"
                            }
                            label={
                              key === selected
                                ? "Selected"
                                : `SELECT ${item.title}`
                            }
                            iconSrc={key === selected ? Check : ""}
                          />
                        </div>
                      </div>
                    )
                )}
              </div>
              <p className="auth-msg danger">{planState}</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="subscription-desc">
              <h4 className="subscription-heading">
                {translation("complete_payment_details")}
              </h4>
              <div className="card-form">
                {paymentData && addNewCard ? (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          {" "}
                          {`${paymentMethodData?.cardBrand} ${translation(
                            "ending_in"
                          )}`}
                        </label>
                        <p>{`****${paymentMethodData?.lastFourDigits}`}</p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label> {"Card Address"}</label>
                        <p>{`${paymentMethodData?.name}, ${paymentMethodData?.address?.city} ${paymentMethodData?.address?.postalCode}, ${paymentMethodData?.address?.country}`}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <form>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>{translation("name_on_card")}</label>
                          <input
                            id="name"
                            className="form-control"
                            placeholder={translation("name_on_card")}
                            type="text"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                              setNameError("");
                            }}
                          />
                          {nameError && (
                            <p className="auth-msg danger">{nameError}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>{translation("card_number")}</label>
                          <CardNumberElement
                            className="form-control"
                            id="cardNumber"
                          />
                          {(errors.incomplete_number ||
                            stripeValidation.cardNumber.message) && (
                            <p className="auth-msg danger">
                              {stripeValidation.cardNumber.message ? (
                                <span className="auth-msg danger">
                                  {stripeValidation.cardNumber.message}
                                </span>
                              ) : (
                                <span className="auth-msg danger">
                                  {errors.incomplete_number}
                                </span>
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group" id="myDiv">
                          <label>{translation("expiration_date")}</label>
                          <CardExpiryElement
                            className="form-control"
                            id="expiry"
                          />
                          {(errors.incomplete_expiry ||
                            stripeValidation.cardExpiry.message) && (
                            <p className="auth-msg danger">
                              {stripeValidation.cardExpiry.message
                                ? stripeValidation.cardExpiry.message
                                : errors.incomplete_expiry}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label>{translation("cvc")}</label>
                          <CardCvcElement className="form-control" id="cvc" />
                          {(errors.incomplete_cvc ||
                            stripeValidation.cvc.message) && (
                            <p className="auth-msg danger">
                              {stripeValidation.cvc.message
                                ? stripeValidation.cvc.message
                                : errors.incomplete_cvc}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-inner-headiing">
                          <label>{translation("card_billing_address")}</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>{translation("country")}</label>
                          <select
                            id="country"
                            onChange={(e) => {
                              setCountry(e.target.value);
                              setCountryError("");
                            }}
                            name="selectedReason"
                            className="form-select form-control country-select"
                          >
                            <option value="" disabled selected>
                              {translation("please_select")}
                            </option>
                            {countries?.map((item) => (
                              <option key={item.name} value={item.value}>
                                {item.name ? item.name : item.value}
                              </option>
                            ))}
                          </select>
                          {countryError && (
                            <p className="auth-msg danger">{countryError}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>{translation("city_town")}</label>
                          <input
                            id="city"
                            className="form-control"
                            placeholder={translation("city_town")}
                            type="text"
                            value={city}
                            onChange={(e) => {
                              setCity(e.target.value);
                              setCityError("");
                            }}
                          />
                          {cityError && (
                            <p className="auth-msg danger">{cityError}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>{translation("zip")}</label>
                          <input
                            className="form-control"
                            placeholder={translation("zip")}
                            type="text"
                            value={postalCode}
                            onChange={(e) => setPostalCode(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                )}

                <p className="encrypt-text">
                  <img src={LockIcon} alt="icon" />
                  {translation("secure_payment_with_rsa_encryption")}
                </p>
              </div>
          {paymentData && addNewCard ? (
                <CommonButton
                  className="transparent-btn"
                  label={translation("change_credit_debit_card")}
                  onClick={() => {
                    setPaymentData(null);
                    setAddNewCard(false);
                    setOldCard(true);
                  }}
                />
              ) : oldCard ? (
                <CommonButton
                  className="transparent-btn"
                  label="USE MY EXISTING CARD DETAILS"
                  onClick={() => {
                    setPaymentData(paymentMethodData);
                    setAddNewCard(true);
                  }}
                />
              ) : null}
            </div>
          </div>
          <div className="col-md-12">
            <div className="process-upgrade">
              <p>{translation("stripe_terms")}</p>
              <CommonButton
                label={translation("process_upgrade")}
                className="theme-btn btn-md success-btn text-uppercase"
                onClick={createSubscription}
                loading={loader}
                disabled={!stripe || loader}
              />
            </div>
          </div>
        </div>
      </div>

      {successModal && (
        <CongratsModel
          onClose={() => setSuccessModal(false)}
          name="OrderComplete"
          title="Order Complete"
          hasImage={true}
          header={true}
        />
      )}
    </>
  );
};

export default PaymentModel;
