import { FC, InputHTMLAttributes } from "react";

import CommonInput from "./CommonInput";
import { Control, FieldError, FieldErrors, FieldValues } from "react-hook-form";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  icon?: boolean;
  align?: string;
  type?:
    | "email"
    | "hidden"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "file"
    | "url"
    | "checkbox"
    | "range"
    | "radio";
  parentClassName?: string;
  iconSrc?: string;
  inputmode?:string
  control?: Control;
  value?: string;
  label?: string;
  error?: FieldError | FieldErrors<FieldValues>;
  onClick?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputWrapper: FC<Props> = ({
  align,
  className,
  icon,
  parentClassName,
  iconSrc,
  error,
  label,
  onClick,
  ...props
}) => {
  return (
    <>
      {/* <div className={`form-group ${parentClassName}`}> */}
      {label && <label>{label}</label>}
      {icon ? (
        <div className={`icon-align ${align === "left" ? "left" : "right"}`}>
          <img
            src={iconSrc}
            alt="icon"
            onClick={onClick}
            className={props.type === "password" ? "cursorA" : ""}
          />
          <CommonInput className={`form-control ${className}`} {...props} />
        </div>
      ) : (
        <CommonInput className={`form-control ${className}`} {...props} />
      )}
      {error && error.message ? (
        <p className="auth-msg danger">{error?.message as string}</p>
      ) : null}
      {/* </div> */}
    </>
  );
};
export default InputWrapper;
