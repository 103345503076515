import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";

import { RootState } from "../state_management/reducers";
interface Props {
  component: React.ComponentType;
}

const PrivateRoutes: React.FC<Props> = ({ component: RouteComponent }) => {
  const authReducer = useSelector((state: RootState) => state?.AuthReducer);
  const adminauthReducer = useSelector(
    (state: RootState) => state?.AdminAuthReducer
  );

  if (authReducer.isLoggedIn || adminauthReducer.isLoggedIn)
    return <RouteComponent />;

  return <Navigate to="/login" />;
};

export default PrivateRoutes;
