import { FC, InputHTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import CommonInput from "./CommonInput";
import { Control, FieldError, FieldErrors, FieldValues } from "react-hook-form";
import { Link } from "react-router-dom";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  icon?: boolean;
  align?: string;
  labelText?: string;
  type?: "checkbox";

  parentClassName?: string;
  iconSrc?: string;
  control?: Control;
  value?: string;
  label?: string;
  error?: FieldError | FieldErrors<FieldValues>;
  onClick?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CommonCheckBox: FC<Props> = ({
  align,
  className,
  icon,
  parentClassName,
  iconSrc,
  error,
  labelText,
  label,
  onClick,
  ...props
}) => {
  const { t: translation } = useTranslation();

  return (
    <>

      <div className="forgot-check justify-content-center mb-2">
        {label && <label>{label}</label>}
        <CommonInput
          className={`form-control me-1 mt-0 ${className}`}
          {...props}
        />
        {labelText ? (
          <label className="form-check-label">{labelText}</label>
        ) : (
          <label className="form-check-label">
            {translation("i_agree_to")}
            <Link
              to="https://www.theskydiveapp.com/terms-of-service/"
              target="_blank"
            >
              {translation("terms_of_service")}
            </Link>
          </label>
        )}
      </div>
      {error && error.message ? (
        <p className="auth-msg danger justify-content-center">
          {error?.message as string}
        </p>
      ) : null}
    </>
  );
};
export default CommonCheckBox;
