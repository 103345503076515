import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CommonButton from "../utilities/formElements/commonButton/CommonButton";

import { subscription } from "../../services/susbscription";

import { disconnectSocket } from "../../util/socket";
import { emptyLocalStorageOnLogout } from "../../util/helper";

import { IPaymentData } from "../../Interfaces/CommonInterface";
import { ISubscription } from "../../Interfaces/SettingInterface";

import Payment from "../views/paymentModel/Payment";
import { paymentFormPlans } from "../../../src/constants/commonConstants";
import ActionType from "../../resources/enums";
import CongratsModel from "./InfoModel";

import InfoIcon from "../../assets/images/info-icon.svg";
import TrialBanner from "../../assets/images/skydiver.svg";

export interface ITrialFinishProps {
  className?: string;
  title?: string;
  onClose?: () => void;
  children?: React.ReactNode;
  closeModel?: any;
  name?: string;
}

const TrialFinishModel: React.FC<ITrialFinishProps> = ({
  title,
  onClose,
  name,
}) => {
  const { t: translation } = useTranslation();
  const dispatch = useDispatch();
  const authReducer: any = useSelector((state: any) => state?.AuthReducer);

  const [changePaymentDetails, setChangePaymentDetails] =
    useState<boolean>(false);
  const [downgradeAccountModel, setDowngradeAccountModel] =
    useState<boolean>(false);
  const [subscriptionDetails, setSubscriptionDetails] =
    useState<ISubscription>();
  const [paymentMethod, setPaymentMethod] = useState<IPaymentData>();
  const [closed, setClosed] = useState<boolean>(true);

  useEffect(() => {
    setClosed(false);
  }, [authReducer?.isClose]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const userId = authReducer.authData._id;

    const data = await subscription({
      jsonprc: "2.0",
      method: "getAccountRecord",
      params: {
        userId: authReducer.authData._id,
      },
      id: 0,
    });

    setSubscriptionDetails(data.data?.result);

    const getPaymentMethod = await subscription({
      jsonprc: "2.0",
      method: "getPaymentMethod",
      params: {
        userId: authReducer.authData._id,
      },
      id: 0,
    });
    setPaymentMethod(getPaymentMethod?.data?.result);
  };

  return (
    <>
      <div className="modal show-modal common-modal trial-modal">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              {name === "ChangeSub" ? (
                <h4 className="modal-title">
                  Change Your Skydive Subscription
                </h4>
              ) : (
                <h4 className="modal-title">Your Trial Period Has Ended</h4>
              )}
              {name === "ChangeSub" && (
                <CommonButton
                  type="button"
                  className="btnC btn-close btn-close-white"
                  onClick={onClose}
                  data-bs-dismiss="modal"
                />
              )}
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-5">
                  <div className="trial-banner-img">
                    <img
                      src={TrialBanner}
                      alt="trial_period_img"
                      className="banner-img-set"
                    />
                    <div className="banner-desc">
                      <p>
                        {" "}
                        By upgrading you receive many additional and useful
                        features as part of your account:
                      </p>

                      <ul>
                        <li>- Many more page views</li>
                        <li>- More URL's to track</li>
                        <li>- More view selection options</li>
                        <li>- All of your visitors and their data</li>
                        <li>- Longer data retention</li>
                      </ul>
                      <div className="mt-2">
                        <div className="tooltip-hover">
                          <u>Compare Plans</u>
                          <div className="tooltip-onimage">
                            <img src={InfoIcon} alt="info_icon" />

                            <div className="tooltip-list">
                              <ul>
                                {paymentFormPlans.map((item, key) => (
                                  <li>
                                    <p className="title">{item.title}</p>
                                    <div className="amount">
                                      <h3>{item.cost}</h3>
                                      <p> {item.billingPeriod}</p>
                                    </div>
                                    <h5>{item.description}</h5>
                                    <div className="points-list">
                                      <p>
                                        {item.pageviews} pageviews / {"month"}
                                      </p>
                                      <p> {item.websites} website to track</p>
                                      <p>
                                        {item.dataStorage}
                                        {item.cost === "$0"
                                          ? " days data storage"
                                          : " year data storage"}
                                      </p>
                                      <p>{item.urls} site URL’s to track</p>
                                      <p>
                                        {item.mainPageviews} bird’s eye view
                                        pages
                                      </p>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                              <div className="arrow-down" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="trial-perios-desc">
                    {name === "ChangeSub" ? (
                      <h5>Ready to make the jump?</h5>
                    ) : (
                      <h5>You did it!</h5>
                    )}
                    {name === "ChangeSub" ? (
                      <h2>Upgrade or downgrade your account</h2>
                    ) : (
                      <h2>Congrats on a successful trial dive.</h2>
                    )}

                    {name === "ChangeSub" ? (
                      <p>
                        {` Your 14-day trial will end on ${
                          authReducer?.accountRecord?.flags?.trialFinishDate &&
                          new Date(
                            authReducer?.accountRecord?.flags?.trialFinishDate
                          ).toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          })
                        }`}{" "}
                        . We hope you enjoyed all the thrills and excitement of
                        Skydiving.
                      </p>
                    ) : (
                      <p>
                        {` Your 14-day trial came to a close on ${
                          authReducer?.accountRecord?.flags?.trialFinishDate &&
                          new Date(
                            authReducer?.accountRecord?.flags?.trialFinishDate
                          ).toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          })
                        }`}{" "}
                        . We hope you enjoyed all the thrills and excitement of
                        Skydiving. We loved seeing you make the jump.
                      </p>
                    )}

                    <div>
                      {name === "ChangeSub" ? (
                        <p className="m-0">At any time you can:</p>
                      ) : (
                        <p className="m-0">You can:</p>
                      )}
                      <p className="m-0">
                        <Link to="#">Upgrade now to a paid plan</Link>
                      </p>
                      <p className="m-0">or</p>
                      <p className="m-0">
                        Downgrade to our Base Jump (forever free) plan
                      </p>
                    </div>

                    <div className="trial-align-btn">
                      <div>
                        <CommonButton
                          type="submit"
                          label="Downgrade to the free plan"
                          className="theme-btn success-outline-btn transparent text-uppercase fontBold"
                          onClick={() => {
                            setDowngradeAccountModel(true);
                          }}
                        />
                      </div>
                      <div className="button-label">
                        <CommonButton
                          type="submit"
                          label="Upgrade Now"
                          className="theme-btn success-btn text-uppercase fontBold"
                          onClick={() => {
                            setClosed(true);
                            setChangePaymentDetails(true);
                          }}
                        />
                        <p className="font12">For as low as $39 a month</p>
                      </div>
                    </div>
                    {name === "ChangeSub" ? (
                      ""
                    ) : (
                      <div>
                        <button
                          className="logout-btn"
                          onClick={() => {
                            dispatch({
                              type: ActionType.LOGOUT,
                              payload: "",
                            });
                            disconnectSocket();
                            emptyLocalStorageOnLogout();
                          }}
                        >
                          Logoff
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {downgradeAccountModel && (
        <CongratsModel
          title={"Downgrade Your Account"}
          onClose={() => {
            setDowngradeAccountModel(false);
          }}
          name="downgradeConfirmation"
          header={true}
        />
      )}

      {changePaymentDetails && closed && (
        <Payment
          onClose={() => {
            setChangePaymentDetails(false);
          }}
          subscriptionData={subscriptionDetails}
          paymentMethod={paymentMethod}
        />
      )}
    </>
  );
};
export default TrialFinishModel;
