import ActionType from "../../resources/enums/index";

export interface IRootState {
  isLoggedIn: boolean;
  isRegistered: string;

  userCount: number;
  emailValidated: boolean;
}

export interface IRootAdminState {
  isLoggedIn: boolean;
}

const initialState: IRootState = {
  isLoggedIn: false,
  isRegistered: "",

  userCount: 0,
  emailValidated: false,
};

const initialAdminState: IRootAdminState = {
  isLoggedIn: false,
};

interface LoginAction {
  type: ActionType.LOGIN;
  payload: any;
}

interface LogoutAction {
  type: ActionType.LOGOUT;
  payload: any;
}

interface UpdateClusterPreferenceAction {
  type: ActionType.UPDATECLUSTER;
  payload: any;
}

interface AdminLoginAction {
  type: ActionType.ADMINLOGIN;
  payload: any;
}

interface SubscriptionTitle {
  type: ActionType.SUBSCRIPTIONTITLE;
  payload: any;
}

export type Action =
  | LoginAction
  | LogoutAction
  | UpdateClusterPreferenceAction
  | AdminLoginAction
  | SubscriptionTitle;

const AuthReducer = (state = initialState, action?: any) => {
  switch (action?.type) {
    case ActionType.LOGIN: {
      return {
        ...state,
        isLoggedIn: true,
        isClose: true,
        authData: action.payload,
        bubbleClusterParameters: {
          speed: action.payload.settings.speed,
          friction: action.payload.settings.friction,
          gravity: action.payload.settings.gravity,
          charge: action.payload.settings.charge,
        },
      };
    }
    case ActionType.USER_LOGIN_BY_ADMIN: {
      return {
        ...state,
        isLoggedIn: false,
        isClose: true,
        authData: action.payload,
        bubbleClusterParameters: {
          speed: action.payload.settings.speed,
          friction: action.payload.settings.friction,
          gravity: action.payload.settings.gravity,
          charge: action.payload.settings.charge,
        },
      };
    }
    case ActionType.ACTIVEUSERCOUNT: {
      return {
        ...state,
        isLoggedIn: true,
        userCount: action.payload,
      };
    }
    case ActionType.SUBSCRIPTIONDATA: {
      return {
        ...state,
        isLoggedIn: true,
        accountRecord: action.payload,
      };
    }

    case ActionType.LOGOUT: {
      return {
        ...state,
        isLoggedIn: false,
        authData: "",
        accountRecord: "",
        subscriptionTitle: "",
        bubbleClusterParameters: {},
        userCount: 0,
        emailValidated: false,
        historicalFilterSelected: { name: "Today", value: "" },
      };
    }

    case ActionType.UPDATECLUSTER: {
      return {
        ...state,
        bubbleClusterParameters: action.payload,
      };
    }
    case ActionType.EMAILVALIDATED: {
      return {
        ...state,
        emailValidated: action.payload,
      };
    }
    case ActionType.SORTING_ORDER: {
      return {
        ...state,
        sortingOrder: action.payload,
      };
    }
    case ActionType.HISTORICAL_FILTER_SELECTED: {
      return {
        ...state,
        historicalFilterSelected: {
          name: action.payload.name,
          value: action.payload.value,
        },
      };
    }
    case ActionType.SUBSCRIPTIONTITLE: {
      return {
        ...state,
        subscriptionTitle: action.payload.title,
      };
    }
    case ActionType.TOTALSESSION: {
      return {
        ...state,
        totalSession: action.payload.totalSession,
      };
    }
    case ActionType.CLOSEMODEL: {
      return {
        ...state,
        isClose: action.payload.isClose,
      };
    }

    default:
      return state;
  }
};

export const AdminAuthReducer = (state = initialAdminState, action?: any) => {
  switch (action?.type) {
    case ActionType.ADMINLOGIN: {
      return {
        ...state,
        isLoggedIn: true,
        adminauthData: action.payload,
      };
    }

    case ActionType.ADMINLOGOUT: {
      return {
        ...state,
        isLoggedIn: false,
        adminauthData: "",
      };
    }
    default:
      return state;
  }
};

export default AuthReducer;
