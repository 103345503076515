import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ROUTES from "../../../constants/routes";

import authLogo from "../../../assets/images/Skydive_logo_beta.svg";

import "./Sidebar.scss";

const AdminSidebar: React.FC = () => {
  const { t: translation } = useTranslation();

  return (
    <div className="admin-sidebar">
      <div className="sidebar-top">
        <img src={authLogo} alt="authLogo" />
      </div>
      <div className="sidebar-mid">
        <Link
          to={ROUTES.USERS}
          className={window.location.pathname === ROUTES.USERS ? "active" : ""}
        >
          {translation("users")}
        </Link>
        <Link
          to={ROUTES.ADMIN}
          className={window.location.pathname === ROUTES.ADMIN ? "active" : ""}
        >
          {translation("admins")}
        </Link>
        <Link
          to={ROUTES.DELETEDUSERS}
          className={
            window.location.pathname === ROUTES.DELETEDUSERS ? "active" : ""
          }
        >
          {translation("deleted_users")}
        </Link>
      </div>
    </div>
  );
};
export default AdminSidebar;
