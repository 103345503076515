import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import CommonButton from "../../utilities/formElements/commonButton/CommonButton";
import { loginValidationSchema } from "../../utilities/validationSchema";
import InputWrapper from "../../utilities/formElements/commonInput/InputWrapper";
import CommonModel from "../../utilities/commonModel";
import { toastMessageError } from "../../utilities/commonToastMessage";

import storage from "../../../util/storage";

import { ILogin } from "../../../Interfaces/AuthInterface";

import { login } from "../../../services/auth";

import ActionType from "../../../resources/enums";
import ROUTES from "../../../constants/routes";
import { typePassword, typeText } from "../../../constants/commonConstants";

import Logo from "../../../assets/images/Skydive_logo_beta.svg";
import EyeIcon from "../../../assets/images/eye-outline.svg";
import EyeCloseIcon from "../../../assets/images/hidden-password.svg";

import "./UserAuth.scss";

const Login = () => {
  const { t: translation } = useTranslation();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(loginValidationSchema(translation)),
  });

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [passwordAndRememberMe, setPasswordAndRememberMe] = useState({
    passwordVisible: false,
    rememberMe: false,
  });
  const [forgetPasswordModel, setForgetPasswordModel] = useState(false);

  /**
   * close forget password model
   */
  const closeForgetPasswordModel = (event: any) => {
    setForgetPasswordModel(false);
  };

  /**
   * login
   */
  const onLogin = async (data: ILogin) => {
    setLoading(true);
    if (passwordAndRememberMe.rememberMe) {
      storage.set("loginData", JSON.stringify(data));
    } else {
      storage.set("loginData", "");
    }
    const result = await login(data);
    if (result && result.data && result.data.success) {
      dispatch({
        type: ActionType.EMAILVALIDATED,
        payload: true,
      });
      navigate(ROUTES.EMAIL_VALIDATION, {
        state: {
          email: data.email,
          action: "login",
        },
      });
    } else {
      if (result && result.error && result.error.message === "Unauthorized") {
        toastMessageError(translation("incorrect_password"), "unauthorized");
      } else {
        toastMessageError(translation("email_not_found"), "emailNotFound");
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    getRememberData();
  }, []);

  /**
   *  remember me functionality
   */
  const getRememberData = () => {
    if (storage.get("loginData")) {
      const data = JSON.parse(storage.get("loginData"));
      reset({
        email: data.email,
        password: data.password,
      });
      setPasswordAndRememberMe((prevState) => ({
        ...prevState,
        rememberMe: true,
      }));
    }
  };

  return (
    <>
      <div className="userauth-main">
        <div className="container-fluid">
          <img src={Logo} alt="logo" className="logo" />

          <div className="userauth-form">
            <div className="userauth-top p-0">
              <h4 className="w-100">{translation("sign_in_to_skydive")}</h4>
            </div>

            {window.location.pathname === ROUTES.LOGIN && (
              <form onSubmit={handleSubmit((data) => onLogin(data as ILogin))}>
                <div className="form-group">
                  <InputWrapper
                    control={control}
                    name="email"
                    className="form-control"
                    placeholder="Email Address"
                    error={errors?.email}
                    type="email"
                  />
                </div>

                <div className="form-group">
                  <div className="input-icon">
                    <InputWrapper
                      control={control}
                      name="password"
                      className="form-control"
                      placeholder={translation("pass")}
                      error={errors?.password}
                      type={
                        passwordAndRememberMe.passwordVisible
                          ? typeText
                          : typePassword
                      }
                    />

                    <CommonButton
                      type="button"
                      onClick={() =>
                        setPasswordAndRememberMe((prevState) => ({
                          ...prevState,
                          passwordVisible:
                            !passwordAndRememberMe.passwordVisible,
                        }))
                      }
                      iconSrc={
                        passwordAndRememberMe.passwordVisible
                          ? EyeIcon
                          : EyeCloseIcon
                      }
                    />
                  </div>
                </div>

                <div className="forgot-check">
                  <div className="form-check m-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="check1"
                      name="option1"
                      value="something"
                      onChange={() =>
                        setPasswordAndRememberMe((prevState) => ({
                          ...prevState,
                          rememberMe: !passwordAndRememberMe.rememberMe,
                        }))
                      }
                      checked={passwordAndRememberMe.rememberMe}
                    />
                    <label className="form-check-label">
                      {translation("remember_me")}
                    </label>
                  </div>
                  <span onClick={() => setForgetPasswordModel(true)}>
                    {translation("forget_password")}
                  </span>
                </div>
                <CommonButton
                  className="theme-btn success-btn text-uppercase mt-35"
                  label={translation("admin_sign_in")}
                  loading={loading}
                  disabled={loading}
                />
              </form>
            )}
            {forgetPasswordModel && (
              <CommonModel closeModel={closeForgetPasswordModel} />
            )}
          </div>

          <p className="auth-bottom">
            {translation("donot_have_skydive_account")}{" "}
            <Link to={ROUTES.SIGNUP}>{translation("sign_up_here")}</Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default Login;
