import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";

import { yupResolver } from "@hookform/resolvers/yup";

import InputWrapper from "../../../utilities/formElements/commonInput/InputWrapper";
import CommonButton from "../../../utilities/formElements/commonButton/CommonButton";
import CommonSelect from "../../../utilities/formElements/commonSelect/CommonSelect";
import {
  toastMessageError,
  toastMessageSuccess,
} from "../../../utilities/commonToastMessage";
import {
  signUpUserInfoValidationSchema,
  useVerificationCodeValidationSchema,
  userPasswordAndTermsAcceptValidationSchema,
  userSiteInfoValidationSchema,
  userSiteValidationSchema,
} from "../../../utilities/validationSchema";
import CommonCheckBox from "../../../utilities/formElements/commonInput/CommonCheckBox";

import {
  connectSocketForAnalyticsView,
  connectSocketForConversionSetup,
} from "../../../../util/socket";
import storage from "../../../../util/storage";

import { ISiteInfo, ISiteUrl } from "../../../../Interfaces/SiteInterface";
import {
  IUserNameAndEmail,
  IUserPasswordAndTermsAccept,
} from "../../../../Interfaces/UserInterface";
import { ISubscription } from "../../../../Interfaces/SettingInterface";

import {
  emailAvailability,
  resendTwoFactorCode,
  twoFactorAuthenticationConfirmation,
} from "../../../../services/user";
import { siteAvailable } from "../../../../services/site";
import { currentUser, signup } from "../../../../services/auth";
import { subscription } from "../../../../services/susbscription";

import ROUTES from "../../../../constants/routes";
import config from "../../../../config/config";

import {
  codeVerified,
  signUpQuizRole,
  siteMonthlyUsers,
  siteType,
} from "../../../../constants/commonConstants";
import ActionType from "../../../../resources/enums";

import EyeIcon from "../../../../assets/images/eye-outline.svg";
import EyeCloseIcon from "../../../../assets/images/hidden-password.svg";
import Logo from "../../../../assets/images/Skydive_logo_beta.svg";

import "../UserAuth.scss";

const FlowOne = () => {
  const { t: translation } = useTranslation();
  const authReducer: any = useSelector((state: any) => state?.AuthReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showRecaptchaError, setShowRecaptchaError] = useState<string>("");
  const {
    handleSubmit: handleSubmitUserInfo,
    formState: { errors: errorsForUserInfoForm },
    control: controlUserInfoForm,
    getValues: getValuesForUserInfoForm,
    reset: resetForUserInfoForm,
  } = useForm({
    resolver: yupResolver(signUpUserInfoValidationSchema(translation)),
  });
  const {
    handleSubmit: handlePasswordSubmit,
    formState: { errors: errorsForPasswordForm },
    control: controlForPasswordForm,
    getValues: getValuesForPasswordForm,
    setValue: setValueForPasswordForm,
    reset: resetForPasswordForm,
  } = useForm({
    resolver: yupResolver(
      userPasswordAndTermsAcceptValidationSchema(translation)
    ),
  });
  const {
    handleSubmit: handleWebsiteSubmit,
    formState: { errors: errorsForWebsiteForm },
    control: controlForWebsiteForm,
    getValues: getValuesForWebsiteForm,
    reset: resetForWebsiteForm,
  } = useForm({
    resolver: yupResolver(userSiteInfoValidationSchema(translation)),
  });
  const {
    handleSubmit: handleSiteUrlSubmit,
    formState: { errors: errorsForSiteUrlForm },
    control: controlForSiteUrlForm,
    getValues: getValuesForSiteUrlForm,
  } = useForm({
    resolver: yupResolver(userSiteValidationSchema(translation)),
  });
  const {
    handleSubmit: handleVerificationCodeSubmit,
    formState: { errors: errorsForVerificationCodeForm },
    control: controlForVerificationCodeForm,
  } = useForm({
    resolver: yupResolver(useVerificationCodeValidationSchema(translation)),
  });

  const [enableNextForm, setEnableNextForm] = useState({
    firstPage: true,
    secondPage: false,
    thirdPage: false,
    fourthPage: false,
  });

  const [viewPassword, setViewPassword] = useState(false);

  const locationData = useLocation();
  useEffect(() => {
    signupUser();
  }, [window.location.pathname === ROUTES.EMAIL_VALIDATION]);

  /**
   * signup
   */
  const signupUser = async () => {
    if (
      window.location.pathname === ROUTES.EMAIL_VALIDATION &&
      locationData &&
      locationData.state
    ) {
      if (locationData.state.action === "signup") {
        const signupUserData = await signup(locationData.state);
        if (
          signupUserData &&
          signupUserData.data &&
          signupUserData.data.success
        ) {

          toastMessageSuccess(
            translation("please_enter_a_verification_code_sent_to_your_email"),
            "verficationCodeSent"
          );
        }
      }
    }
  };
  /**
   *
   * user info form submission
   */
  const onUserInfoSubmit = async (data: IUserNameAndEmail) => {
    setLoading(true);
    const isEmailAvailabile = await emailAvailability(data.email);
    if (
      isEmailAvailabile &&
      isEmailAvailabile.data &&
      isEmailAvailabile.data.successMessage
    ) {
      setEnableNextForm((prevState) => ({
        ...prevState,
        firstPage: false,
        secondPage: true,
      }));
      setValueForPasswordForm("termsAccept", false);
    } else {
      toastMessageError(
        isEmailAvailabile?.data?.errorMessage,
        "emailAlreadyExistError"
      );
    }
    setLoading(false);
  };

  /**
   *
   * password form submission
   */
  const onPasswordSubmit = (data: IUserPasswordAndTermsAccept) => {
    setLoading(true);
    if (data.termsAccept) {
      setEnableNextForm((prevState) => ({
        ...prevState,
        secondPage: false,
        thirdPage: true,
      }));
    } else {
    }
    setLoading(false);
  };
  const onWebsiteInfoSubmit = (data: ISiteInfo) => {
    setLoading(true);
    setEnableNextForm((prevState) => ({
      ...prevState,
      thirdPage: false,
      fourthPage: true,
    }));
    setLoading(false);
  };

  /**
   *
   *  site url form submission
   */
  const onSiteUrlSubmit = async (data: ISiteUrl) => {
    setLoading(true);
    if (captchaToken) {
      const isSiteAvailable = await siteAvailable(data.siteUrl);
      if (
        isSiteAvailable &&
        isSiteAvailable.data &&
        isSiteAvailable.data.successMessage
      ) {
        setEnableNextForm((prevState) => ({
          ...prevState,
          thirdPage: false,
          fourthPage: false,
        }));

        navigate(ROUTES.EMAIL_VALIDATION, {
          state: {
            url: getValuesForSiteUrlForm().siteUrl,
            email: getValuesForUserInfoForm().email,
            password: getValuesForPasswordForm().password,
            firstname: getValuesForUserInfoForm().name,
            quiz: {
              role: getValuesForWebsiteForm().signupRole,
              siteType: getValuesForWebsiteForm().siteType,
              monthlyUsers: getValuesForWebsiteForm().siteMonthlyUsers,
            },
            recaptchaToken: captchaToken,
            action: "signup",
          },
        });
      } else {
        // site not available
        toastMessageError(
          translation("site_not_available"),
          "siteNotAvailable"
        );
      }
    } else {
      setShowRecaptchaError(translation("please_check_the_reCAPTCHA_checkbox"));
    }
    setLoading(false);
  };

  const [captchaToken, setCaptchaToken] = useState(false);

  const [subscriptionDetails, setSubscriptionDetails] =
    useState<ISubscription>();

  /**
   *
   * email verification (2fa)
   */
  const onVerificationCodeSubmit = async (data: any) => {
    setLoading(true);
    const params = {
      code: `${data.verificationCode}`,
      email: locationData.state.email,
      type: locationData.state.action === "login" ? "login" : "signup",
    };
    const isVerified = await twoFactorAuthenticationConfirmation(params);
    if (
      isVerified &&
      isVerified.data &&
      isVerified.data.message === codeVerified
    ) {
      const currentUserData: any = await currentUser();
      storage.set("siteId", currentUserData.data.site._id);

      if (
        currentUserData &&
        currentUserData?.data.flags &&
        currentUserData.data.flags.trackingCodeInstalled
      ) {
        dispatch({
          type: ActionType.LOGIN,
          payload: currentUserData.data,
        });
        storage.set("bubbleParameters", currentUserData.data.settings);
        dispatch({
          type: ActionType.SORTING_ORDER,
          payload: currentUserData.data.site.pagesSorting,
        });
        dispatch({
          type: ActionType.HISTORICAL_FILTER_SELECTED,
          payload: { name: "Today", value: "" },
        });
        await getAccountRecord(currentUserData.data._id);
        await connectSocketForAnalyticsView({
          query: `siteId=${currentUserData.data.site._id}&role=admin&roomStatus=dashboard`,
        });
        dispatch({
          type: ActionType.ADMINLOGOUT,
          payload: "",
        });
        navigate(ROUTES.ANALYTICS_VIEW.replace("/:id", ""));
      } else if (
        currentUserData &&
        currentUserData.data.flags &&
        !currentUserData.data.flags.trackingCodeInstalled
      ) {
        dispatch({
          type: ActionType.LOGIN,
          payload: currentUserData.data,
        });
        await getAccountRecord(currentUserData.data._id);
        storage.set("bubbleParameters", currentUserData.data.settings);
        connectSocketForConversionSetup({
          query: `siteId=${currentUserData.data.site._id}&role=admin&roomStatus=conversion-setup`,
        });
        dispatch({
          type: ActionType.ADMINLOGOUT,
          payload: "",
        });
        navigate(ROUTES.CONVERSION_SETUP.replace("/:id", ""));
      }
    } else {
      toastMessageError(translation("invalid_code"), "invalidCode");
    }

    setLoading(false);
  };

  const getAccountRecord = async (id: string) => {
    const data = await subscription({
      jsonprc: "2.0",
      method: "getAccountRecord",
      params: {
        userId: id,
      },
      id: 0,
    });
    setSubscriptionDetails(data.data?.result);
    dispatch({
      type: ActionType.SUBSCRIPTIONDATA,
      payload: data.data?.result,
    });
    storage.set("urlsToTrack", data.data?.result.subscriptionData.urlsToTrack);
    storage.set(
      "mainViewSlots",
      data.data?.result?.subscriptionData?.mainViewSlots
    );
  };

  /**
   *
   * recaptcha token
   */
  const handleVerify = (e: any) => {
    setCaptchaToken(e);
  };
  const [loading, setLoading] = useState(false);
  const onBackClick = () => {
    if (window.location.pathname === ROUTES.SIGNUP) {
      if (enableNextForm.firstPage) {
        navigate(ROUTES.LOGIN);
      } else if (enableNextForm.secondPage) {
        resetForUserInfoForm({
          email: getValuesForUserInfoForm().email,
          name: getValuesForUserInfoForm().name,
        });
        setEnableNextForm({
          firstPage: true,
          secondPage: false,
          thirdPage: false,
          fourthPage: false,
        });
      } else if (enableNextForm.thirdPage) {
        resetForPasswordForm({
          password: getValuesForPasswordForm().password,
          termsAccept: false,
        });
        setEnableNextForm({
          firstPage: false,
          secondPage: true,
          thirdPage: false,
          fourthPage: false,
        });
      } else if (enableNextForm.fourthPage) {
        resetForWebsiteForm({
          signupRole: getValuesForWebsiteForm().signupRole,
          siteType: getValuesForWebsiteForm().siteType,
          siteMonthlyUsers: getValuesForWebsiteForm().siteMonthlyUsers,
        });
        setEnableNextForm({
          firstPage: false,
          secondPage: false,
          thirdPage: true,
          fourthPage: false,
        });
      }
    }
  };

  const handleSendCode = () => {
    const result = resendTwoFactorCode({
      email: locationData.state.email,
      type: "signup",
    });
    toastMessageSuccess(translation("we_have_resend_the_code"), "resendCode");
  };

  return (
    <div className="userauth-main">
      <div className="container-fluid">
        <div className="userauth-top">
          <div>
            {!(window.location.pathname === ROUTES.EMAIL_VALIDATION) && (
              <CommonButton label="&lt; Back" onClick={onBackClick} />
            )}
          </div>
          <div className="text-end">
            <p>{translation("already_have_account")}</p>
            <Link to={ROUTES.LOGIN}>{translation("login_here")}</Link>
          </div>
        </div>

        <img src={Logo} alt="logo" className="logo" />

        <div className="signup-form-header">
          <h2>
            {enableNextForm.firstPage &&
            window.location.pathname === ROUTES.SIGNUP
              ? translation("start_your_skydive_account")
              : enableNextForm.secondPage
              ? translation("create_your_password")
              : enableNextForm.thirdPage
              ? translation("how_will_you_use_skydive")
              : enableNextForm.fourthPage
              ? translation("enter_your_site")
              : translation("please_verify_your_account")}
          </h2>
          <p className="font22 fontRegular text-primary">
            {enableNextForm.firstPage &&
            window.location.pathname === ROUTES.SIGNUP
              ? translation("try_14_days_trial")
              : enableNextForm.secondPage
              ? translation("choose_secure_password")
              : enableNextForm.thirdPage
              ? translation(
                  "let_us_know_about_yourself_and_what_type_of_site_skydive_will_be_used_for"
                )
              : enableNextForm.fourthPage
              ? translation(
                  "we_will_create_your_tracking_code_based_on_site_url"
                )
              : `${
                  window.location.pathname === ROUTES.EMAIL_VALIDATION &&
                  locationData &&
                  locationData.state
                    ? `We just sent an email to ${locationData.state.email}. Please enter your code below`
                    : " "
                }`}
          </p>
          <p className="font14 text-primary">
            {enableNextForm.firstPage &&
              window.location.pathname === ROUTES.SIGNUP &&
              translation("no_credit_card_required_downgrade_anytime")}
          </p>
        </div>
        <div className="userauth-form signup-form">
          {enableNextForm.firstPage &&
            window.location.pathname === ROUTES.SIGNUP && (
              <form
                onSubmit={handleSubmitUserInfo((data) =>
                  onUserInfoSubmit(data as IUserNameAndEmail)
                )}
                className="p-0"
              >
                <div className="form-group">
                  <label>{translation("full_name")}</label>
                  <InputWrapper
                    control={controlUserInfoForm}
                    name="name"
                    type="text"
                    placeholder={translation("signup_name_placeholder")}
                    className="form-control"
                    error={errorsForUserInfoForm?.name}
                  />
                </div>
                <div className="form-group">
                  <label>{translation("email_label")}</label>
                  <InputWrapper
                    control={controlUserInfoForm}
                    name="email"
                    className="form-control"
                    placeholder={translation("signup_email_placeholder")}
                    error={errorsForUserInfoForm?.email}
                    type="email"
                  />
                </div>
                <div className="text-center">
                  <CommonButton
                    className="theme-btn success-btn text-uppercase fontBold"
                    label={translation("continue_to_password")}
                    loading={loading}
                    disabled={loading}
                  />
                </div>
              </form>
            )}
          {enableNextForm.secondPage && (
            <form
              onSubmit={handlePasswordSubmit((data) =>
                onPasswordSubmit(data as IUserPasswordAndTermsAccept)
              )}
            >
              <div className="form-group">
                <label>{translation("password_ladel")}</label>
                <div className="input-icon">
                  <InputWrapper
                    control={controlForPasswordForm}
                    name="password"
                    className="form-control"
                    placeholder={translation("pass")}
                    error={errorsForPasswordForm?.password}
                    type={viewPassword ? "text" : "password"}
                  />
                  <CommonButton
                    type="button"
                    onClick={() => setViewPassword(!viewPassword)}
                    iconSrc={viewPassword ? EyeIcon : EyeCloseIcon}
                  />
                </div>
              </div>

              <CommonCheckBox
                control={controlForPasswordForm}
                name="termsAccept"
                type="checkbox"
                className="form-check-input"
                id="check1"
                checked={getValuesForPasswordForm().termsAccept.termsAccept}
                error={errorsForPasswordForm?.termsAccept}
                onChange={(value) => {
                  setValueForPasswordForm(
                    "termsAccept",
                    Boolean(value.target.checked)
                  );
                }}
              />

              <div className="text-center">
                <CommonButton
                  className="theme-btn success-btn text-uppercase fontBold"
                  label={translation("continue_to_site_info")}
                  loading={loading}
                  disabled={loading}
                />
              </div>
            </form>
          )}
          {enableNextForm.thirdPage && (
            <form
              onSubmit={handleWebsiteSubmit((data) =>
                onWebsiteInfoSubmit(data as ISiteInfo)
              )}
            >
              <div className="form-group">
                <label>{translation("your_role")}</label>

                <CommonSelect
                  placeholder={translation("please_select_role")}
                  option={signUpQuizRole}
                  name="signupRole"
                  className="form-select form-control"
                  control={controlForWebsiteForm}
                  errors={errorsForWebsiteForm?.signupRole}
                />
              </div>

              <div className="form-group">
                <label>
                  {translation("what_type_of_site_will_you_be_monitoring")}
                </label>
                <CommonSelect
                  placeholder={translation("please_select_site_type")}
                  option={siteType}
                  name="siteType"
                  className="form-select form-control"
                  control={controlForWebsiteForm}
                  errors={errorsForWebsiteForm?.siteType}
                />
              </div>

              <div className="form-group">
                <label>
                  {translation("how_many_monthly_users_do_you_anticipate")}
                </label>
                <CommonSelect
                  placeholder={translation("please_select_monthly_users_range")}
                  option={siteMonthlyUsers}
                  name="siteMonthlyUsers"
                  className="form-select form-control"
                  control={controlForWebsiteForm}
                  errors={errorsForWebsiteForm?.siteMonthlyUsers}
                />
              </div>

              <div className="text-center">
                <CommonButton
                  className="theme-btn success-btn text-uppercase fontBold"
                  label={translation("continue_to_site_url")}
                  loading={loading}
                  disabled={loading}
                />
              </div>
            </form>
          )}

          {enableNextForm.fourthPage && (
            <form
              onSubmit={handleSiteUrlSubmit((data) => {
                onSiteUrlSubmit(data as ISiteUrl);
              })}
            >
              <div className="form-group">
                <label>{translation("site_url")}</label>
                <InputWrapper
                  control={controlForSiteUrlForm}
                  name="siteUrl"
                  className="form-control"
                  placeholder={translation("dummy_site_url")}
                  error={errorsForSiteUrlForm?.siteUrl}
                />
              </div>
              <div className="d-flex justify-content-center">
                <ReCAPTCHA
                  sitekey={config.recaptcha_key!}
                  onChange={handleVerify}
                />
              </div>

              {!captchaToken && (
                <p className="auth-msg danger">{showRecaptchaError}</p>
              )}

              <div className="text-center mt-4">
                <CommonButton
                  className="theme-btn success-btn text-uppercase fontBold"
                  label={translation("start_using_skydive")}
                  loading={loading}
                  disabled={loading}
                  onClick={() =>
                    !captchaToken &&
                    setShowRecaptchaError(
                      translation("please_check_recaptcha_box")
                    )
                  }
                />
              </div>
            </form>
          )}
          {window.location.pathname === ROUTES.EMAIL_VALIDATION && (
            <>
              <form
                onSubmit={handleVerificationCodeSubmit((data) =>
                  onVerificationCodeSubmit(data)
                )}
              >
                <div className="form-group">
                  <label>{translation("authentication_code")}</label>
                  <InputWrapper
                    control={controlForVerificationCodeForm}
                    type="text"
                    inputmode="numeric"
                    placeholder={translation("enter_your_code")}
                    className="form-control"
                    name="verificationCode"
                    error={errorsForVerificationCodeForm?.verificationCode}
                  />
                </div>

                <div className="text-center">
                  <CommonButton
                    className="theme-btn success-btn text-uppercase fontBold"
                    label={translation("next")}
                    loading={loading}
                    disabled={loading}
                  />
                </div>
              </form>

              <p className="text-center">
                {translation("didn_get_a_code")}
                <span className="cursor-class" onClick={handleSendCode}>
                  <b>{translation("send_it_again")}</b>
                </span>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FlowOne;
