import * as d3 from "d3";
import { each } from "lodash";
import { selectedFilter, setColor } from "./clusterService";
import storage from "./storage";

export const setTooltipView = (userInfo) => {
  let html =
  "<div style=\"color:  transparent !important; background: inherit !important; -webkit-filter: grayscale(1) invert(1) brightness(128%) contrast(999) !important; filter:  grayscale(1) invert(1) brightness(128%) contrast(999) !important; -webkit-background-clip: text !important; background-clip: text !important;\">";
  each(userInfo, function (value, key) {
    html = html + key.toUpperCase() + " : " + value + "</br>";
  });
  

  return (html = html + "</div>");
};

export const rgbToHex = (rgb) => {
 
  return (
    "#" +
    ((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b).toString(16).slice(1)
  );
};
export const showHistoricalTooltip = (bubbleData) => {
  const calculatedPercent =
    (bubbleData.target.__data__.user / Number(storage.get("totalSession"))) *
    100;
  const tooltipData = {
    [selectedFilter.name]: bubbleData.target.__data__.menuName,
    sessions: bubbleData.target.__data__.user,
    percent: (calculatedPercent || 0).toFixed(2) + "%",
  };

  d3.select("#tooltip")
    .html(setTooltipView(tooltipData))
    .style("opacity", 0.9)
    .style("left", bubbleData.layerX + bubbleData.target.__data__.radius + "px")
    .style("top", bubbleData.layerY - bubbleData.target.__data__.radius + "px")
    .style("background-color", () =>
      rgbToHex(bubbleData.target.__data__.colorInfo)
    )
    .transition()
    .duration(200);
};

export const showRealtimeTooltip = (bubbleData) => {
  const userInfo = {
    Browser: bubbleData.target.__data__.sessionData.browser,
    "Device Type": bubbleData.target.__data__.sessionData.device,
    "Visitor Type": bubbleData.target.__data__.sessionData.visitor_type,
    "Country/Location": bubbleData.target.__data__.sessionData.country_name,
    OS: bubbleData.target.__data__.sessionData.os,
    "Page Visting": bubbleData.target.__data__.currentUrlName,
  };

  d3.select("#tooltip")
    .html(setTooltipView(userInfo))
    .style("opacity", 0.9)
    .style("background", rgbToHex(setColor(bubbleData.target.__data__.color)))
    .style("left", bubbleData.layerX + bubbleData.target.__data__.radius + "px")
    .style("top", bubbleData.layerY - bubbleData.target.__data__.radius + "px")
    .transition()
    .duration(200);
};

export const showSelectedUrlTooltip = (itemThis) => {
  if (itemThis && itemThis.target.getAttribute("is-wrap")) {
    d3.select("#tooltip")
      .attr("class", "selected-url-tooltip")
      .html(itemThis.target?.getAttribute("real-text"))
      .style("opacity", 0.9)
      .style("left", itemThis.layerX + "px")
      .style("top", itemThis.layerY + "px")
      .style("background", "#fff")
      .transition()
      .duration(200);
  }
};

export const hideRealtimeTooltip = () => {
  d3.select("#tooltip").transition().duration(500).style("opacity", 0);
};

export const hideHistoricalTooltip = () => {
  d3.select("#tooltip").transition().duration(500).style("opacity", 0);
};

export const hideSelectedUrlTooltip = () => {
  d3.select("#tooltip").transition().duration(500).style("opacity", 0);
};
