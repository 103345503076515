import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { toastMessageError } from "../../utilities/commonToastMessage";

import {
  emptyLocalStorageOnLogout,
  trakingCodeGenrator,
} from "../../../util/helper";
import {
  connectSocketForConversionSetup,
  disconnectSocket,
  socketForConversionSetup,
  disconnectSocketForConversionSetup
} from "../../../util/socket";

import ROUTES from "../../../constants/routes";
import { DEFAULT_VALUE, UseFulLinks } from "../../../constants/commonConstants";

import ActionType from "../../../resources/enums";

import Header from "../../header/Header";
import InfoModel from "../../commonModel/InfoModel";
import CommonModel from "../../commonModel/CommonModel";

import ClipIcon from "../../../assets/images/clippy.svg";
import "./ConversionSetup.scss";

const ConversionSetup = () => {
  const [windowInstance, setWindowInstance] = useState<boolean>(false);
  const authReducer: any = useSelector((state: any) => state?.AuthReducer);
  const dispatch = useDispatch();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const siteId = authReducer.authData.site._id;
  const socketOptions = {
    query: `siteId=${siteId}&role=admin&roomStatus=conversion-setup`,
  };
  const [showCongratsModel, setShowCongratsModel] = useState<boolean>(false);
  const [showFailModel, setShowFailModel] = useState<boolean>(false);
  const [deleteAcountModel, setDeleteAcountModel] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      window.location.pathname.split("/")[
        window.location.pathname.split("/").length - 1
      ] === "conversion-setup" &&
      authReducer.authData.flags.trackingCodeInstalled
    ) {
      return navigate(ROUTES.SIGNUP);
    }
    connectSocketForConversionSetup(socketOptions);
    getTrackingCode(siteId);
  }, []);

  const { t: translation } = useTranslation();
  const [trackingCode, setTrackingCode] = useState("");
  const getTrackingCode = (siteId: string) => {
    const trackingCodeData = trakingCodeGenrator(siteId);
    setTrackingCode(trackingCodeData);
  };

  let siteWindow: any;
  // @ts-ignore
  let siteConnected = false;
  const verifyTrackingCode = () => {
    try {
      siteWindow = window.open(
        authReducer.authData.site.siteUrl + "?verifySkydive=true",
        "_blank"
      );
      socketForConversionSetup.on("site-info", (payload: any) => {
        setWindowInstance(true);

        siteConnected = true;
        if (
          payload &&
          payload.message &&
          payload.message === "Site Connected Successfully"
        ) {
          setShowCongratsModel(true);
          siteWindow.close();
          clearTimeout(timer);
          disconnectSocketForConversionSetup();
          return;
        } else {
          socketConnectionCheck();
        }
        siteWindow.close();
      });
      if (!siteConnected) {
        socketConnectionCheck();
      }
    } catch (err) {}
  };
  const handleCopy = () => {
    if (textAreaRef.current) {
      textAreaRef.current.select();

      document.execCommand("copy");
    }
  };

  let timer;
  const socketConnectionCheck = () => {
    timer = setTimeout(() => {
      socketForConversionSetup.disconnect();
      toastMessageError(
        translation("verification_time_out"),
        "verificationTimeOutError"
      );

      siteWindow.close();
      disconnectSocketForConversionSetup();
    }, DEFAULT_VALUE.SETUP_TIMEOUT);
  };

  return (
    <>
      <Header />
      <div className="main-container setup-main">
        {showCongratsModel && (
          <InfoModel
            closeModel={() => {
              setShowCongratsModel(false);
              
              navigate(
                window.location.pathname.split("/")[
                  window.location.pathname.split("/").length - 1
                ] === "conversion-setup"
                  ? ROUTES.CONVERSION_SETUP.replace("/:id", "")
                  : ROUTES.CONVERSION_SETUP.replace(
                      ":id",
                      window.location.pathname.split("/")[
                        window.location.pathname.split("/").length - 1
                      ]
                    )
              );
            }}
            name="success"
          />
        )}
        {showFailModel && (
          <InfoModel closeModel={() => setShowFailModel(false)} name="fail" />
        )}
        <div className="container">
          <h1>
            {translation("install_your_tracking_code_on")}{" "}
            <strong>{authReducer.authData.site.host}</strong>
          </h1>

          <p>{translation("installing_tracking_code_description")}</p>
          <div className="form-group textarea-group copy-icon">
            <textarea
              ref={textAreaRef}
              rows={8}
              placeholder={translation("place_your_script_here")}
              className="form-control"
              defaultValue={trackingCode}
              readOnly
            />
            <span className="copy-icon" onClick={handleCopy}>
              <img src={ClipIcon} alt="ClipIcon" />
            </span>
          </div>
          <div className="mt-4 d-flex align-items-center justify-content-between">
            <button
              className="theme-btn success-btn text-uppercase fontBold"
              onClick={() => {
                verifyTrackingCode();
              }}
            >
              {translation("verify_my_code_is_installed")}
            </button>
            <button
              className="theme-btn white-btn text-uppercase fontBold"
              onClick={() => {
                dispatch({ type: ActionType.LOGOUT, payload: "" });
                disconnectSocket();
                emptyLocalStorageOnLogout();
              }}
            >
              {translation("log_off_complete_later")}
            </button>
          </div>
          <div className="installing-text">
            <p>
              {translation("for_help_with_installing_your_code")}{" "}
              <Link to={UseFulLinks.installingSkydive} target="_blank">
                <strong>{translation("installing_skydive")}</strong>
              </Link>{" "}
              {translation("or")} &nbsp;
              <Link to={UseFulLinks.contactUs} target="_blank">
                <strong>{translation("contact_us")}</strong>
              </Link>
            </p>

            <p>
              {translation("made_a_mistake_with_signing_up_with_this_account")}{" "}
              <Link onClick={() => setDeleteAcountModel(true)} to={""}>
                <strong>{translation("delete_your_account")}</strong>
              </Link>
            </p>
          </div>
        </div>
      </div>
      {deleteAcountModel && (
        <CommonModel
          title={"Delete Your Account"}
          onClose={() => {
            setDeleteAcountModel(false);
          }}
          name="DeleteAccount"
        />
      )}
    </>
  );
};

export default ConversionSetup;
