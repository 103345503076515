import saveAs from "file-saver";
import { IAdmins } from "../Interfaces/AdminInterface";
import config from "../config/config";
import { IUsers } from "../Interfaces/UserInterface";
import {
  adminExportCsvOption,
  userExportCsvOption,
} from "../constants/commonConstants";
import { toastMessageError } from "../components/utilities/commonToastMessage";
import storage from "./storage";

export const trakingCodeGenrator = (trackingId: string) => {
  return `<!-- Begin Skydive Tracking Code -->
<script src="https://cdnjs.cloudflare.com/ajax/libs/socket.io/2.0.3/socket.io.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.2/rollups/aes.js"></script>
<script src="${config.socketPath}env.js"></script>
<script>
    (function(i, s, o, g, r, a, m) {
      i['skydiveObject'] = {name: r, scriptUrl: g};
      a = s.createElement(o),
      m = s.getElementsByTagName(o)[0];
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
      a.onload = function() {
          skydive.init('${trackingId}');
      }
    })(window, document, 'script', '${config.socketPath}analytics.js', 'skydive');
</script>
<!-- End Skydive Tracking Code -->`;
};

export const averageSessionDurationConverter = (time: number) => {
  let seconds = time / 1000;
  // 2- Extract hours:
  const hours = parseInt(String(seconds / 3600)); // 3,600 seconds in 1 hour
  seconds = seconds % 3600; // seconds remaining after extracting hours
  // 3- Extract minutes:
  const minutes = parseInt(String(seconds / 60)); // 60 seconds in 1 minute
  // 4- Keep only seconds not extracted to minutes:
  seconds = seconds % 60;
  return `${hours < 10 ? `0${hours.toFixed()}` : hours.toFixed()}:${
    minutes < 10 ? `0${minutes.toFixed()}` : Math.trunc(minutes).toFixed()
  }:${seconds < 10 ? `0${seconds.toFixed()}` : Math.trunc(seconds).toFixed()}`;
};

export const exportDataToCSV = async (
  data: IAdmins[] | IUsers[],
  headers: string[],
  fileName: string
) => {
  const csvData: string | undefined = convertToCSV(data, headers, fileName);
  const blob = new Blob([csvData as string], {
    type: "text/csv;charset=utf-8",
  });
  saveAs(blob, fileName);
};

const convertToCSV = (
  data: IAdmins[] | IUsers[],
  headers: string[],
  fileName: string
) => {
  if (fileName === adminExportCsvOption.FILE_NAME) {
    const rows = (data as IAdmins[]).map((item: IAdmins) => {
      const permissions = item.permissions
        ? capitalizeFirstLetter(item.permissions)
        : "" || "";
      return [
        item.firstname ? capitalizeFirstLetter(item.firstname) : "" || "",
        item?.email || "",
        item.user_role ? capitalizeFirstLetter(item.user_role) : "" || "",
        item.status ? capitalizeFirstLetter(item.status) : "" || "",
        permissions || "",
      ];
    });

    const csvContent =
      headers.join(",") + "\n" + rows.map((row) => row.join(",")).join("\n");

    return csvContent;
  } else if (fileName === userExportCsvOption.FILE_NAME) {
    const rows = (data as IUsers[]).map((item: IUsers) => {
      const siteUrl = item?.sites ? item.sites[0]?.siteUrl : "-";
      const createdAt =
        item?.createdAt && new Date(item?.createdAt).toLocaleDateString();
     
      return [
        item?.firstname || "",
        item?.email || "",
        siteUrl || "",
        createdAt || "",
        "days(days)",
      ];
    });

    const csvContent =
      headers.join(",") + "\n" + rows?.map((row) => row.join(",")).join("\n");

    return csvContent;
  } else {
    toastMessageError("Something went wrong!");
  }
};

export const capitalizeFirstLetter = (input: string | string[]) => {
  if (Array.isArray(input)) {
    return input
      .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
      .join(" & ");
  } else if (typeof input === "string") {
    return input.charAt(0).toUpperCase() + input.slice(1);
  } else {
    return input;
  }
};

export const shortenString = (s:any) => {
  if (s.length > 1) {
      return s.slice(0, 1) + "...";
  } else {
      return s;
  }
};


export const emptyLocalStorageOnLogout = ()=>{
  storage.set("socketId","");
  storage.set("mainViewSlots","");
  storage.set("pages","");
  storage.set("siteId","");
  storage.set("totalSession","");
};

export const wrapPath =(originalPathName)=> {
  let isWrap = false;

  if (originalPathName.length > 29) {
    originalPathName = originalPathName.substr(0, 28);
    originalPathName = originalPathName + "...";
    isWrap = true;
  }

  return {
    pathName:originalPathName,
    isWrap
  };
};
