import React, { useRef, useEffect, ReactNode } from "react";

interface OutsideAlerterProps {
  children: ReactNode;
  onOutsideClick: () => void;
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref: React.RefObject<HTMLDivElement>, onOutsideClick: () => void): void {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        // alert("You clicked outside of me!");
        onOutsideClick();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onOutsideClick]);
}

/**
 * Component that alerts if you click outside of it
 */
function OutsideAlerter({ children, onOutsideClick }: OutsideAlerterProps): JSX.Element {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, onOutsideClick);

  return <div ref={wrapperRef}>{children}</div>;

}
// 

export default OutsideAlerter;
