import { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import { adminLogin } from "../../../services/auth";
import { currentAdmin } from "../../../services/admin";

import { ILogin } from "../../../Interfaces/AuthInterface";

import { loginValidationSchema } from "../../utilities/validationSchema";
import { toastMessageError } from "../../utilities/commonToastMessage";
import CommonButton from "../../utilities/formElements/commonButton/CommonButton";
import InputWrapper from "../../utilities/formElements/commonInput/InputWrapper";

import storage from "../../../util/storage";
import ROUTES from "../../../constants/routes";
import { typePassword, typeText } from "../../../constants/commonConstants";
import ActionType from "../../../resources/enums";

import Logo from "../../../assets/images/Skydive_logo_beta.svg";
import EyeIcon from "../../../assets/images/eye-outline.svg";
import EyeCloseIcon from "../../../assets/images/hidden-password.svg";

import ForgetPasswordModel from "./ForgetPasswordModel";

import "./AdminAuth.scss";

const AdminLogin = () => {
  const { t: translation } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [forgetPasswordModel, setForgetPasswordModel] = useState(false);
  const [passwordAndRememberMe, setPasswordAndRememberMe] = useState({
    passwordVisible: false,
    rememberMe: false,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginValidationSchema(translation)),
  });

  /**
   *
   * login functionality for admin
   */
  const onLogin = async (data: ILogin) => {
    setLoading(true);
    if (passwordAndRememberMe.rememberMe) {
      storage.set("loginAdminData", JSON.stringify(data));
    } else {
      storage.set("loginAdminData", "");
    }
    const result = await adminLogin(data);
    if (result && result.data && result.data.success) {
      const currentAdminData = await currentAdmin();

      dispatch({
        type: ActionType.ADMINLOGIN,
        payload: currentAdminData.data,
      });

      navigate(ROUTES.USERS);
    } else {
      if (result && result.error && result.error.message === "Unauthorized") {
        toastMessageError(translation("incorrect_password"), "unauthorized");
      } else {
        toastMessageError(translation("email_not_registered"), "emailNotFound");
      }
    }
    setLoading(false);
  };

  const closeForgetPasswordModel = () => {
    setForgetPasswordModel(false);
  };

  return (
    <div className="admin-userauth-main">
      <div className="container-fluid">
        <img src={Logo} alt="logo" className="logo" />

        <div className="userauth-form">
          <div className="userauth-form-head">
            <h2>{translation("welcome_back")}</h2>
            <p>{translation("admin_login")}</p>
          </div>
          <form onSubmit={handleSubmit((data) => onLogin(data as ILogin))}>
            <div className="form-group">
              <InputWrapper
                control={control}
                name="email"
                className="form-control"
                placeholder={translation("email_placeholder")}
                error={errors?.email}
                type="email"
              />
            </div>
            <div className="form-group">
              <div className="input-icon">
                <InputWrapper
                  control={control}
                  name="password"
                  className="form-control"
                  placeholder={translation("password_ladel")}
                  error={errors?.password}
                  type={
                    passwordAndRememberMe.passwordVisible
                      ? typeText
                      : typePassword
                  }
                />

                <CommonButton
                  type="button"
                  onClick={() =>
                    setPasswordAndRememberMe((prevState) => ({
                      ...prevState,
                      passwordVisible: !passwordAndRememberMe.passwordVisible,
                    }))
                  }
                  iconSrc={
                    passwordAndRememberMe.passwordVisible
                      ? EyeIcon
                      : EyeCloseIcon
                  }
                />
              </div>
            </div>
            <div className="forgot-check">
              <span onClick={() => setForgetPasswordModel(true)}>
                {translation("forget_password")}
              </span>
            </div>
            <div className="action-btn mb-0 text-center">
              <CommonButton
                className="btnC theme-btn success-btn text-uppercase mt-35"
                label={translation("admin_sign_in")}
                loading={loading}
                disabled={loading}
              />
            </div>
          </form>

          {forgetPasswordModel && (
            <ForgetPasswordModel closeModel={closeForgetPasswordModel} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
